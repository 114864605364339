<template>
  <div class="shops">
    <page-header>
      Мои склады
      <template #rightArea>
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="mini"
          @click="createShopHandler"
        >
          Создать склад
        </el-button>
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="mini"
          style="margin-left: 5px"
          @click="createButtonClickHandler"
        >
          Добавить товар
        </el-button>
      </template>
    </page-header>

    <el-skeleton v-if="shopsLoading">
      <template #template>
        <el-skeleton-item
          :variant="'rect'"
          style="height: 60px"
        />
      </template>
    </el-skeleton>
    <el-empty
      v-if="!shops.length && !shopsLoading"
      description="Нет складов"
    />
    <template v-if="shops.length && !shopsLoading">
      <div class="shops__list">
        <div
          v-for="shop in shops"
          :key="shop.id"
          class="shop"
        >
          <router-link
            class="shop__name"
            :to="'/shops/' + shop.id"
          >
            {{ shop.name }}
          </router-link>
          <div class="shop__actions">
            <el-button
              icon="el-icon-edit"
              size="small"
              type="text"
              @click="editShopHandler(shop)"
            />
            <el-button
              icon="el-icon-delete"
              size="small"
              type="text"
              @click="deleteShopHandler(shop)"
            />
          </div>
        </div>
      </div>
    </template>

    <div style="display: flex;justify-content: space-between; margin: 30px 0 10px;gap:20px">
      <h2
        style="cursor:pointer;margin: 0;white-space: nowrap"
        @click="toggleShopProducts"
      >
        Справочник «Товары»
        <i
          v-if="productsCollapsed"
          class="el-icon el-icon-caret-bottom"
        />
      </h2>
      <el-input
        v-model="searchQuery"
        placeholder="Поиск по товарам"
        prefix-icon="el-icon-search"
        style="max-width: 600px"
        @input="getProducts"
      />
    </div>
    <el-empty
      v-if="!allProducts.length && !allProductsLoading && !productsCollapsed"
      description="Нет товаров"
    />
    <n-table
      v-if="!productsCollapsed && (allProductsLoading || allProducts.length)"
      :loading="allProductsLoading"
      :data="allProducts"
      :default-sort="sort"
      :columns="[
        { name: '', prop: 'edit', width: '50px', align: 'right' },
        { name: 'Бренд', prop: 'brand', width: '120px' },
        { name: 'Код 1С', prop: 'externalId', width: '120px' },
        { name: 'Название', prop: 'name', width: 'minmax(200px, 1fr)', sortable: true },
        { name: '', prop: 'action', width: '50px'},
      ]"
      @scroll-end="loadMore"
    >
      <template #edit="{ row }">
        <el-tooltip
          effect="dark"
          content="Изменить"
        >
          <el-button
            size="mini"
            icon="el-icon-edit"
            @click.prevent.stop="editProductHandler(row)"
          />
        </el-tooltip>
      </template>
      <template #brand="{ row }">
        {{ row.brand }}
      </template>
      <template #externalId="{ row }">
        {{ row.externalId }}
      </template>
      <template #name="{ row }">
        {{ row.name }}
      </template>
      <template #quantity="{ row }">
        <template v-if="row.quantity">
          {{ row.quantity }}
        </template>
      </template>
      <template #action="{ row }">
        <el-tooltip
          effect="dark"
          content="Добавить на склад"
        >
          <el-button
            size="mini"
            icon="el-icon-plus"
            @click.prevent.stop="shopFillClickHandler(row)"
          />
        </el-tooltip>
      </template>
    </n-table>
    <el-dialog
      modal-append-to-body
      :visible.sync="isProductCreationModalVisible"
      title="Товар"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        status-icon
        @submit.native.prevent="createProductHandler"
      >
        <el-form-item
          label="Название"
          prop="name"
        >
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item
          label="OEM"
          prop="OEM"
        >
          <el-input v-model="form.OEM" />
        </el-form-item>
        <el-form-item
          label="Код 1С"
          prop="externalId"
        >
          <el-input v-model="form.externalId" />
        </el-form-item>
        <el-form-item
          label="Артикул"
          prop="article"
        >
          <el-input v-model="form.article" />
        </el-form-item>
        <el-form-item
          label="Кратность"
          :rules="[
            { type: 'number', message: 'Только числа'}
          ]"
          prop="minShippingRate"
        >
          <el-input v-model.number="form.minShippingRate" />
        </el-form-item>
        <el-form-item
          label="Бренд"
          prop="brand"
        >
          <el-input v-model="form.brand" />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button-group>
          <el-button @click="isProductCreationModalVisible = false">Отменить</el-button>
          <el-button
            type="primary"
            @click="createProductHandler"
          >Сохранить</el-button>
        </el-button-group>
      </span>
    </el-dialog>
    <el-dialog
      title="Выберите склад"
      :visible.sync="shopFillDialogOpened"
      append-to-body
      width="400px"
    >
      <el-select
        v-model="shopToFill"
        style="width: 100%"
        placeholder="Выбрать склад"
        default-first-option
      >
        <el-option
          v-for="shop in shops"
          :key="shop.id"
          :label="shop.name"
          :value="shop.id"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="shopFillDialogOpened = false"
        >Отменить</el-button>
        <el-button
          type="primary"
          :disabled="!shopToFill"
          :loading="shopFillLoading"
          @click="shopFillHandler"
        >Перенести</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { noop } from 'utils/functions';
import { mapActions, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';


/**
 * @returns {ShopProduct}
 */
const defaultFormValues = () => ({
  id: null,
  name: '',
  OEM: '',
  stockbalance: 0,
  externalId: '',
  article: '',
  minShippingRate: 1,
  brand: '',
});

export default {
  name: 'Shops',
  components: { PageHeader },
  metaInfo: {
    title: 'Мои склады'
  },
  data: () => ({
    /** @type {ShopProduct|null} */
    form: defaultFormValues(),
    rules: {
      name: [
        { required: true, message: 'Укажите наименование', trigger: 'blur' },
      ],
      OEM: [
        { required: true, message: 'Укажите OEM', trigger: 'blur' }
      ],
      externalId: [
        { required: true, message: 'Укажите код 1С', trigger: 'blur' }
      ],
      article: [
        { required: true, message: 'Укажите артикул', trigger: 'blur' }
      ],
      brand: [
        { required: true, message: 'Укажите бренд', trigger: 'blur' }
      ],
    },
    isProductCreationModalVisible: false,
    sort: { prop: 'name', order: 'ASC' },
    loadingMore: false,
    allLoaded: false,
    limit: 50,
    shopFillDialogOpened: false,
    shopToFill: null,
    productToFill: null,
    shopFillLoading: false,
    productsCollapsed: !!localStorage.getItem('shopProductsCollapse'),
    searchQuery: '',
  }),
  computed: {
    ...mapState('personalCabinet', ['shops', 'shopsLoading', 'allProducts', 'allProductsLoading']),
  },
  async mounted() {
    await this.getShops().catch((e) => {
      console.error(e);
      this.$message.error('Возникла ошибка при получении складов');
    });
    await this.getProducts();
  },
  methods: {
    ...mapActions('personalCabinet', [
      'getShops', 'createShop', 'editShop', 'deleteShop', 'createProduct', 'editProduct', 'getAllProducts',
      'setStockBalance',
    ]),
    async getProducts() {
      await this.getAllProducts(
        {
          sortField: this.sort.prop,
          sortOrder: this.sort.order,
          limit: this.limit,
          offset: 0,
          searchQuery: this.searchQuery,
        }
      ).then((products) => {
        if (products.length === 0) {
          this.allLoaded = true;
        }
      }).catch(() => {
        this.$message.error('Возникла ошибка при получении товаров склада');
      });
    },
    async createShopHandler() {
      return this.$prompt('Укажите название склада', 'Создание нового склада', {
        confirmButtonText: 'Создать',
        cancelButtonText: 'Отмена',
        inputPlaceholder: 'Название склада',
        inputValidator: value => value !== null && value.length >= 3 && value.length <= 100,
        inputErrorMessage: 'Название склада должно содержать от 3 до 100 символов',
      })
        .then(async ({ value }) => {
          await this.createShop(value)
            .then(() => {
              this.$message.success('Склад добавлен');
              this.getShops();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error('Возникла ошибка при создании склада');
            });
        }).catch(noop);
    },
    /**
     * @param {Shop} shop
     * @returns {Promise<MessageBoxData>}
     */
    editShopHandler(shop) {
      return this.$prompt('Укажите название склада', 'Изменение склада', {
        confirmButtonText: 'Изменить',
        cancelButtonText: 'Отмена',
        inputPlaceholder: 'Название склада',
        inputValidator: value => value !== null && value.length >= 3 && value.length <= 100,
        inputErrorMessage: 'Название склада должно содержать от 3 до 100 символов',
        inputValue: shop.name
      })
        .then(async ({ value }) => {
          await this.editShop({
            shopId: shop.id,
            name: value
          })
            .then(() => {
              this.$message.success('Склад изменён');
              this.getShops();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error('Возникла ошибка при изменении склада');
            });
        }).catch(noop);
    },
    /**
     * @param {Shop} shop
     * @returns {Promise<MessageBoxData>}
     */
    deleteShopHandler(shop) {
      return this.$confirm(`Вы действительно хотите удалить склад «${shop.name}»`, 'Удаление склада')
        .then(async () => {
          await this.deleteShop(shop.id)
            .then(() => {
              this.$message.success('Склад удалён');
              this.getShops();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error('Возникла ошибка при удалении склада');
            });
        });
    },
    createButtonClickHandler() {
      this.form = defaultFormValues();
      this.isProductCreationModalVisible = true;
    },
    async createProductHandler() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        if (this.form.id) {
          await this.editProduct({
            productId: this.form.id,
            name: this.form.name,
            OEM: this.form.OEM,
            externalId: this.form.externalId,
            article: this.form.article,
            minShippingRate: this.form.minShippingRate,
            brand: this.form.brand,
          }).then(() => {
            this.$message.success('Товар изменён');
            this.getProducts();
            this.form = defaultFormValues();
            this.isProductCreationModalVisible = false;
          })
            .catch((e) => {
              console.error(e);
              this.$message.error('Возникла ошибка при создании товара');
            });
        } else {
          await this.createProduct({
            name: this.form.name,
            OEM: this.form.OEM,
            externalId: this.form.externalId,
            article: this.form.article,
            minShippingRate: this.form.minShippingRate,
            brand: this.form.brand,
          }).then(() => {
            this.$message.success('Товар добавлен');
            this.form = defaultFormValues();
            this.isProductCreationModalVisible = false;
            this.getProducts();
          })
            .catch((e) => {
              this.$message.error(e || 'Возникла ошибка при создании товара');
            });
        }
        return true;
      });
    },
    /**
     * @param {ShopProduct} product
     */
    async editProductHandler(product) {
      this.form.id = product.id;
      this.form.name = product.name;
      this.form.OEM = product.OEM;
      this.form.externalId = product.externalId;
      this.form.article = product.article;
      this.form.brand = product.brand;
      this.isProductCreationModalVisible = true;
    },
    async loadMore() {
      // console.log('loadMore');
      // if (this.allProductsLoading || this.loadingMore || this.allLoaded || this.allProducts.length === 0) return;
      // this.loadingMore = true;
      // await this.getProducts();
      // this.loadingMore = false;
    },
    async shopFillClickHandler(row) {
      this.shopFillDialogOpened = true;
      this.productToFill = row;
      await this.getShops();
    },
    async shopFillHandler() {
      this.shopFillLoading = true;
      await this.setStockBalance(
        {
          productId: this.productToFill.id,
          stockBalance: 1,
          shopId: this.shopToFill,
        }
      ).then(() => {
        this.$message.success('Товар добавлен ');
        this.shopFillDialogOpened = false;
        this.selectedBasketItems = [];
        this.shopToFill = null;
      })
        .catch((e) => {
          console.error(e);
          this.$message.error('Возникла ошибка при добавлении товара');
        })
        .finally(() => {
          this.shopFillLoading = false;
        });
    },
    toggleShopProducts() {
      this.productsCollapsed = !this.productsCollapsed;
      if (this.productsCollapsed) {
        localStorage.setItem('shopProductsCollapse', 'true');
      } else {
        localStorage.removeItem('shopProductsCollapse');
      }
    }
  },
};
</script>

<style scoped lang="sass">
.shops
  max-width: 1200px
  margin: 0 auto
  padding: 20px
  &__list
    display: flex
    flex-direction: column
    gap: 10px
    margin-top: 30px
  .shop
    position: relative
    font-size: 16px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    display: flex
    align-items: center
    gap: 10px
    transition: box-shadow .3s
    &__name
      display: block
      padding: 20px
      text-decoration: none
      color: unset
      flex-grow: 1
    &__actions
      display: flex
      align-items: center
      gap: 10px
      opacity: 0
      visibility: hidden
      transition: all .3s
      margin-left: auto
      padding-right: 20px
    &:hover
      box-shadow: 0 20px 30px 0 rgba(0, 0, 50, .06)
      .shop__actions
        opacity: 1
        visibility: visible
</style>
