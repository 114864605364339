<template>
  <table
    ref="NTable"
    :class="b({ loading, size })"
    @scroll.passive="scroll"
  >
    <thead :class="b('head')">
      <tr :style="{ gridTemplateColumns }">
        <th
          v-for="({ name, prop, sortable, align }, colIndex) in columnsArray"
          :key="`colTitle---${colIndex}`"
          :class="b('col', { head: true, sortable, active: prop === defaultSort.prop && sortable, align, size })"
          @click="sort(prop, sortable)"
        >
          <slot :name="`${prop}Head`">
            <span>{{ name }}</span>
          </slot>
          <sort-arrows
            v-if="sortable"
            :class="b('arrows')"
            :active="prop === defaultSort.prop"
            :order="defaultSort.order"
          />
        </th>
      </tr>
    </thead>
    <tbody
      ref="body"
      :class="b('body')"
    >
      <template v-if="tableLoading">
        <tr
          v-for="i in expectedLength"
          :key="`fake-row---${i}`"
          :style="{ gridTemplateColumns }"
          :class="b('fake-row')"
        >
          <td
            v-for="col in columnsArray.length"
            :key="`fake-col---${col}`"
            :class="b('fake-col')"
          >
            <el-skeleton-item
              style="height: 19px"
              animated
            />
          </td>
        </tr>
      </template>
      <NTableRow
        v-for="(row, rowIndex) in data"
        :key="`row---${rowIndexKey ? row[rowIndexKey] : rowIndex}`"
        :classes="rowClass(row, rowIndex)"
        :columns="columnsArray"
        :template-columns="gridTemplateColumns"
        :propertis-array="propertisArray"
        :row-index="rowIndexKey ? row[rowIndexKey] : rowIndex"
        :row="row"
        :active="activeRowId === (rowIndexKey ? row[rowIndexKey] : rowIndex)"
        @click="clickOnRow(row, rowIndex)"
      >
        <template #default="scope">
          <slot
            :name="scope.prop"
            :row="scope.row"
            :index="rowIndex"
            v-text="scope.row[scope.prop]"
          />
        </template>
        <template #after="scope">
          <slot
            name="after"
            :row="scope.row"
            :columns="columnsArray"
            :grid-template-columns="gridTemplateColumns"
          />
        </template>
      </NTableRow>
      <div
        v-if="loadingMore"
        :class="b('more-loader')"
      >
        Загружаем больше товаров..
      </div>
      <div
        v-if="!data.length"
        :class="b('empty-text')"
      >
        <slot name="emptyText">
          {{ emptyText }}
        </slot>
      </div>
    </tbody>
    <div ref="scrollEnd" />
  </table>
</template>

<script>
import { size } from '../../props';
import NTableRow from './layouts/NTableRow';
import SortArrows from './layouts/SortArrows';
import {
  columns, defaultSort, emptyText, loading, rowClass
} from './props';

const genColumns = column => ({
  name: column.name,
  prop: column.prop,
  width: column.width ?? 'minmax(1px, 1fr)',
  align: column.align ?? 'left',
  sortable: column.sortable ?? false,
  show: column.show ?? true,
  translucent: column.translucent ?? false,
  hideTitleOnMobile: column.hideTitleOnMobile ?? false,
});

export default {
  name: 'NTable',
  block: 'NTable',
  components: { NTableRow, SortArrows },
  model: {
    prop: 'activeRowId',
    event: 'change-active-row'
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    columns,
    defaultSort,
    emptyText,
    tableLoading: loading,
    expectedLength: {
      type: Number,
      default: 10
    },
    loading,
    loadingMore: {
      type: Boolean,
      default: false
    },
    rowClass,
    mainColumns: {
      type: Array,
      default: () => []
    },
    rowIndexKey: {
      type: String,
      default: ''
    },
    activeRowId: {
      type: [Number, String],
      default: null,
    },
    size
  },
  data: () => ({}),
  computed: {
    columnsArray() {
      return this.columns
        .map(genColumns)
        .filter(column => column.show)
        .filter(column => !this.mainColumns.length || !!this.mainColumns.includes(column.prop));
    },
    propertisArray() {
      if (!this.mainColumns.length) return [];
      return this.columns
        .map(genColumns)
        .filter(column => column.show)
        .filter(column => !this.mainColumns.includes(column.prop));
    },
    gridTemplateColumns() {
      return this.columnsArray.reduce((a, item) => `${a}${item.width} `, '').trim();
    }
  },
  methods: {
    sort(prop, sortable) {
      if (!sortable) return;
      if (prop === this.defaultSort.prop) {
        this.$emit('sort-change', { prop, order: this.defaultSort.order === 'ASC' ? 'DESC' : 'ASC' });
      } else {
        this.$emit('sort-change', { prop, order: 'ASC' });
      }
    },
    scroll() {
      if (this.$refs.NTable.getBoundingClientRect().bottom >= this.$refs.scrollEnd.getBoundingClientRect().bottom - 400) {
        this.scrollEnd();
      }
    },
    clickOnRow(row, rowIndex) {
      this.$emit('click-on-row', row);
      this.changeActiveRow(this.rowIndexKey ? row[this.rowIndexKey] : rowIndex);
    },
    scrollEnd() {
      this.$emit('scroll-end');
    },
    changeActiveRow(id) {
      this.$emit('change-active-row', id === this.activeRowId ? null : id);
    }
  },
};
</script>

<style scoped lang="sass" src="./table.sass"></style>
