<template>
  <span class="sort-arrows">
    <span
      class="sort-arrows__ascending"
      :class="{ 'sort-arrows__ascending_active': order === 'ASC' && active }"
    />
    <span
      class="sort-arrows__descending"
      :class="{ 'sort-arrows__descending_active': order === 'DESC' && active }"
    />
  </span>
</template>

<script>
export default {
  name: 'SortArrows',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    order: {
      type: String,
      required: true
    },
  },
  block: 'SortArrows',
};
</script>

<style scoped lang="sass">
.sort-arrows
  display: inline-flex
  flex-flow: column
  align-items: center
  justify-content: center
  &__ascending
    border: 5px solid transparent
    border-bottom: 5px solid #bcbcbc
    margin-bottom: 2px
    &_active
      border-bottom: 5px solid #1f75a8
  &__descending
    border: 5px solid transparent
    border-top: 5px solid #bcbcbc
    &_active
      border-top: 5px solid #1f75a8
</style>
