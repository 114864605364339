<template>
  <div
    class="order-block"
  >
    <div
      class="order-block__header"
      @click="openInfo"
    >
      <div class="title-wrap">
        <order-return-status
          v-if="status"
          :status="status"
        /><h2>Возврат №{{ returnOrder.id }}</h2>
      </div>
      <div class="order-block__data order-block__date">
        <span>Дата оформления</span>
        {{ formatDate(returnOrder.createdAt) }}
      </div>
      <div
        v-if="returnOrder.comment && !visibleInfo"
        class="order-block__data order-block__comment"
      >
        <span>Комментарий</span>
        <div>{{ returnOrder.comment }}</div>
      </div>
      <price-output
        v-if="!visibleInfo"
        class="order-block__price"
        :price="normalizePrice(totalPrice)"
      />
      <button class="order-block__view-more-button">
        <i class="el-icon-caret-right" />
      </button>
    </div>
    <div
      v-if="visibleInfo"
      class="order-block__body"
    >
      <n-table
        :data="basketItems"
        :columns="[
          { name: 'Артикул', prop: 'article', width: '120px' },
          { name: 'Название', prop: 'name', width: 'minmax(200px, 1fr)' },
          { name: 'Причина возврата', prop: 'reason', width: 'minmax(100px, 1fr)' },
          { name: 'Цена', prop: 'price', width: '80px', align: 'right' },
          { name: 'Кол-во', prop: 'quantity', width: '80px', align: 'right' },
          { name: 'Сумма', prop: 'sum', width: '76px', align: 'right' },
        ]"
        @sort-change="$emit('sort', $event)"
      >
        <template #article="{ row }">
          <template v-if=" row.supplier">
            {{ JSON.parse(row.supplier).article }}
          </template>
          <template v-else>
            {{ row.Product.article }}
          </template>
        </template>
        <template #name="{ row }">
          <template v-if=" row.supplier">
            {{ JSON.parse(row.supplier).name }}
          </template>
          <template v-else>
            {{ row.Product.name }}
          </template>
        </template>
        <template #price="scope">
          <priceOutput :price="normalizePriceMarkup(getPrice(scope.row))" />
        </template>
        <template #quantity="scope">
          {{ scope.row.quantity }}
        </template>
        <template #sum="scope">
          <priceOutput :price="normalizePriceMarkup(getPrice(scope.row) * scope.row.quantity)" />
        </template>
      </n-table>
      <div class="order-block__properties">
        <h3 v-if="organisation">
          Юр. лицо:
        </h3>
        <p v-if="organisation">
          {{ organisation.name }}
        </p>
        <h3 v-if="returnOrder.comment">
          Комментарий:
        </h3>
        <p v-if="returnOrder.comment">
          {{ returnOrder.comment }}
        </p>
      </div>
      <div class="order-block__footer">
        <price-output
          class="order-block__price"
          :price="normalizePrice(totalPrice)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { getPrice, normalizePrice,normalizePriceMarkup } from 'utils/normalizePrice';
import { mapActions, mapState } from 'vuex';

import OrderReturnStatus from '@/views/history/components/orderReturnStatus';

export default {
  name: 'ReturnOrderBlock',
  components: { OrderReturnStatus },
  props: {
    /** @type {ReturnsHistoryItem|Object} */
    returnOrder: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      visibleInfo: false,
      basketItems: [],
    };
  },
  computed: {
    ...mapState('returns', ['statuses']),
    ...mapState('accounts', ['org']),
    status() {
      return this.statuses[this.returnOrder.status];
    },
    /**
     * @return {Organization|null}
     */
    organisation() {
      return this.org.find(orgData => orgData.id === this.returnOrder.orgId);
    },
    totalPrice() {
      return this.returnOrder.ReturnProductsItems.reduce((sum, current) => {
        return sum + (getPrice(current) * current.quantity)
      }, 0);
    },
  },
  methods: {
    ...mapActions('returns', ['GetReturnProductItems']),
    getPrice,
    normalizePriceMarkup,
    normalizePrice,
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    }),
    async openInfo() {
      if (!this.basketItems.length) {
        this.basketItems = await this.GetReturnProductItems(this.returnOrder.id);
      }
      this.visibleInfo = !this.visibleInfo;
    },
  }
};
</script>

<style scoped lang="sass">
  h3
    font-size: 12px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 12px
    color: #282828
    margin: 2px 0 15px 0
  .order-block
    position: relative
    padding: 10px 20px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    &__header
      display: flex
      flex-flow: nowrap
      align-items: center
      padding-right: 30px
      cursor: pointer
      > *
        position: relative
        z-index: 2
      &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: radial-gradient(circle at top right, #ecf5ff, white 30%)
        opacity: 0
        z-index: 1
        border-radius: 8px
        transition: .2s
      &:hover::before
        opacity: 1
      @media (max-width: 700px)
        flex-flow: column
        align-items: flex-start
      .title-wrap
        display: flex
        flex-flow: nowrap
        align-items: center
        margin-right: 30px
        min-width: 200px
        @media (max-width: 700px)
          margin-right: 0
          margin-bottom: 5px
      h2
        margin-left: 10px
        font-size: 14px
        color: #636363
    &__view-more-button
      position: absolute
      right: 0
      height: 30px
      width: 50px
      padding: 0
      border: none
      background: none
      cursor: pointer
      border-radius: 0 8px 8px 0
      overflow: hidden
      i
        position: relative
        transform: rotate(0)
        transition: .2s
        z-index: 2
      &_active
        i
          transform: rotate(-90deg)
    &__data
      display: flex
      flex-flow: column
      font-size: 12px
      color: #282828
      margin-right: 15px
      opacity: .8
      @media (max-width: 700px)
        margin-right: 0
        margin-bottom: 5px
      span
        font-size: 10px
        color: #636363
    &__name
      @media (min-width: 700px)
        width: 130px
        overflow: hidden
        text-overflow: ellipsis
    &__date
      min-width: 110px
    &__comment div
      max-width: 100px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    &__price
      font-size: 14px
      margin-left: auto
      color: #636363
      @media (max-width: 700px)
        margin-right: -30px
        &_footer-price
          margin-right: 0
    &__buttons
      display: flex
      flex-flow: nowrap
      gap: 10px
    &__body
      position: relative
      padding-top: 10px
      padding-bottom: 10px
      z-index: 2
    &__properties
      margin-top: 15px
    &__footer
      display: flex
      flex-flow: nowrap
      align-items: center
      @media (max-width: 700px)
        align-items: start
        flex-direction: column
    &__footer-actions
      display: grid
      grid-gap: 10px
      grid-template-columns: auto auto
      @media (max-width: 700px)
        margin-bottom: 15px
      @media (max-width: 320px)
        grid-template-columns: auto
</style>
