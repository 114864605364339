<template>
  <div
    class="xls-main"
    @drag.prevent=""
    @dragstart.prevent=""
    @dragend.prevent=""
    @dragover.prevent=""
    @dragenter.prevent="showLabel = true"
    @dragleave.prevent=""
    @drop.prevent=""
    @mouseleave="showLabel = false"
  >
    <div
      v-loading="$store.state.products.loading"
      class="uploader"
      :element-loading-text="`Загружаем ${fileName}`"
    >
      <div
        v-show="!$store.state.products.loading"
        class="uploader__text"
      >
        <span class="title">Загрузите Excel файл вашего заказа</span>
        <el-button @click="$refs.upload.click()">
          Выбрать файл
        </el-button>
        <span class="subtitle">или перетащите его в эту область</span>
        <a
          href="/preview.xlsx"
          class="after"
          download
        >скачать пример xls</a>
      </div>
      <label
        v-show="showLabel"
        class="uploader__label"
        for="uploadOrder"
        @drag.prevent=""
        @dragstart.prevent=""
        @dragend.prevent=""
        @dragover.prevent=""
        @dragenter.prevent=""
        @dragleave.prevent="showLabel = false"
        @drop.prevent="sendOrderInDrop($event)"
      >
        <svg
          class="uploader__label__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 489.4 489.4"
          fill="#1f75a8"
        >
          <path
            d="M382.4,422.75h-79.1H282h-4.6v-106.1h34.7c8.8,0,14-10,8.8-17.2l-67.5-93.4c-4.3-6-13.2-6-17.5,0l-67.5,93.4
          c-5.2,7.2-0.1,17.2,8.8,17.2h34.7v106.1h-4.6H186H94.3c-52.5-2.9-94.3-52-94.3-105.2c0-36.7,19.9-68.7,49.4-86
          c-2.7-7.3-4.1-15.1-4.1-23.3c0-37.5,30.3-67.8,67.8-67.8c8.1,0,15.9,1.4,23.2,4.1c21.7-46,68.5-77.9,122.9-77.9
          c70.4,0.1,128.4,54,135,122.7c54.1,9.3,95.2,59.4,95.2,116.1C489.4,366.05,442.2,418.55,382.4,422.75z"
          />
        </svg>
        Кидайте, мы поймаем
      </label>
      <input
        id="uploadOrder"
        ref="upload"
        type="file"
        hidden
        @change="sendOrderInInput"
      >
    </div>
    <div
      v-if="xlsErrors.length && !$store.state.products.loading"
      class="draft"
    >
      <div class="title">
        Продолжите работу
      </div>
      <el-badge
        :value="xlsErrors.length"
        :max="20"
        type="warning"
      >
        <el-button @click="$router.push('/upload-xls-errors')">
          Открыть черновик
        </el-button>
      </el-badge>
      <div class="subtitle">
        Открыть список товаров из excel,<br> загруженных ранее
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'XlsUploader',
  data: () => ({
    showLabel: false,
    fileName: '',
  }),
  computed: {
    ...mapState('products', ['xlsErrors'])
  },
  async mounted() {
    await this.getXlsErrors();
  },
  methods: {
    ...mapActions('products', ['GetXlsProducts', 'getXlsErrors']),
    sendOrderInInput() {
      this.sendOrder(this.$refs.upload.files[0]);
    },
    sendOrderInDrop(e) {
      this.sendOrder(e.dataTransfer.files[0]);
    },
    sendOrder(file) {
      this.fileName = file.name;

      const _fileNameSplitArr = file.name.split('.');
      const extension = _fileNameSplitArr[_fileNameSplitArr.length - 1];
      if (extension && (extension !== 'xls' && extension !== 'xlsx')) {
        this.$message.error('Загрузить можно только xls файлы');
        return;
      }

      this.showLabel = false;

      const formData = new FormData();
      formData.append('order', file);

      this.$refs.upload.value = '';

      this.GetXlsProducts(formData).catch(() => {
        this.$message.error('Загрузите подходящий xls');
      });
    },
  },
};
</script>

<style scoped lang="sass">
@keyframes border
  0%
    border-color: #ebeef5
  50%
    border-color: #1f75a8
  100%
    border-color: #ebeef5

.xls-main
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - 5px), 1fr))
  grid-gap: 10px
  width: 100%
  height: calc(100vh - 44px)
  position: relative
  @media (max-width: 800px)
    grid-template-columns: 1fr

  &::after
    content: ''
    display: block
    position: absolute
    top: 5px
    left: 5px
    right: 5px
    bottom: 5px
    border: 1px solid #ebeef5
    z-index: -1

.draft
  position: relative
  padding-left: 10px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  color: #303030
  font-size: 15px
  font-weight: bold
  @media (max-width: 800px)
    padding-left: 0
    padding-top: 10px
  &:before
    display: block
    content: ''
    border-left: 1px solid #dcdfe6
    height: 80%
    width: 1px
    position: absolute
    left: 0
    margin: auto 0
    @media (max-width: 800px)
      border-left: 0
      border-top: 1px solid #ebeef5
      width: 80%
      height: 1px
      top: 0
      left: auto
      right: auto
      margin: 0 auto
  .title
    margin-bottom: 30px
  .subtitle
    font-size: 10px
    color: #4e4e4e
    margin-top: 10px
    font-weight: normal

.uploader
  position: relative
  display: flex
  align-items: center
  justify-content: center
  color: #303030
  text-align: center
  font-size: 15px
  font-weight: bold
  &__text
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    .title
      margin-bottom: 30px
    .subtitle
      font-size: 10px
      color: #4e4e4e
      margin-top: 10px
      font-weight: normal
    .after
      font-size: 12px
      margin-top: 60px
      margin-bottom: -60px
      font-weight: normal
      color: #1f75a8
      text-decoration: underline
      text-align: center
  &__label
    position: fixed
    top: 5px
    left: 5px
    right: 5px
    bottom: 5px
    border: 3px solid #ebeef5
    animation: border 1.5s infinite
    background: white
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    z-index: 1000
    &__icon
      height: 50px
</style>
