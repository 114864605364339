<template>
  <div>
    <div class="m-md">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="$router.push('/tecdoc/brand')"
          >Общий каталог (TecDoc)</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="$router.push('/tecdoc/brand')"
          >Бренды</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="manufacturerId !== null ? $router.push(`/tecdoc/model/${manufacturerId}`) : $router.push('/tecdoc/brand')"
          >
            Модели
          </span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span class="breadcrumb-item">Модификации</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <TecDocOffline />

    <div class="m-md">
      <ModificationsComponent />
    </div>
  </div>
</template>

<script>
import TecDocOffline from '@/components/tecdoc/offline';

import ModificationsComponent from '../../components/abcp/Modifications';

export default {
  name: 'Modifications',
  filters: {
    date: (value) => {
      if (!value) return '';
      value = value.toString();

      return `${value.slice(4, 7)}/${value.slice(0, 4)}`;
    }
  },
  components: {
    TecDocOffline,
    ModificationsComponent
  },
  metaInfo: {
    title: 'Модификации TecDoc'
  },
  data() {
    return {
      // manufacturerId: this.$route.params.manufacturerId,
      // modelId: this.$route.params.modelId,
    };
  },
  computed: {
    manufacturerId() {
      return this.$route.params.manufacturerId;
    },
    modelId() {
      return this.$route.params.modelId;
    },
  },
  async mounted() {
    await this.$store.dispatch('tecdoc/GetModifications', {
      manufacturerId: this.manufacturerId,
      modelId: this.modelId
    });
  }
};
</script>

<style scoped>

</style>
