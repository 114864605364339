<template>
  <div class="search-block">
    <el-autocomplete
      v-model="input"
      placeholder="Введите наименование, артикул, бренд или VIN"
      class="search-block__input"
      size="small"
      :fetch-suggestions="querySearch"
      style="width: 100%"
      :minlength="1"

      @focus="goal"
      @input="change"
      @keydown.enter.native="search"
      @select="handleSelect"
    >
      <template
        v-if="searchQuery.length"
        #suffix
      >
        <div class="search-block__actions">
          <el-tooltip
            effect="dark"
            content="Очистить"
            placement="bottom"
          >
            <button
              class="search-block__clear"
              @click="clear"
            >
              <i class="el-icon-close" />
            </button>
          </el-tooltip>
        </div>
      </template>
      <template #default="{ item }">
        {{ item.item.name }}
      </template>
    </el-autocomplete>
    <button
      class="search-block__button"
      @click="search"
    >
      <i class="el-icon-search" />
    </button>
  </div>
</template>

<script>
import ymMixin from 'utils/ymMixin';
import { mapActions, mapMutations, mapState } from 'vuex';

const SECTION_CATALOG = 'catalog';
const SECTION_TECDOC = 'tecdoc';

export default {
  mixins: [ymMixin],
  data() {
    return {
      radio: '1',
      input: '',
      SECTION_CATALOG,
      SECTION_TECDOC,
      section: SECTION_CATALOG,
      sectionName: {
        [SECTION_CATALOG]: 'Товары Николь',
        [SECTION_TECDOC]: 'Сторонние каталоги',
      }
    };
  },
  computed: {
    ...mapState('ui', ['windowSize']),
    ...mapState('products', {
      searchQuery: 'search',
    }),
    currentCategory() {
      return this.$store.state.categories.currentCategory;
    },
    products() {
      return this.$store.state.products.products;
    },
  },
  watch: {
    $route(to) {
      if (to.path.indexOf('/tecdoc') >= 0 || to.path.indexOf('/oem')) {
        this.section = SECTION_TECDOC;
      }
      if (to.path.indexOf('/products') >= 0) {
        this.section = SECTION_CATALOG;
      }
    },
  },
  mounted() {
    const searchQuery = this.$route.query.query;
    if (searchQuery) {
      this.SET_SEARCH_TEXT(searchQuery);
      this.section = SECTION_TECDOC;
      this.tecDocSearch();
    }
  },
  methods: {
    ...mapActions('products', ['GetProductsList']),
    ...mapMutations('products', ['SET_CATEGORY_ID', 'SET_SEARCH_TEXT', 'SET_EMPTY_TEXT', 'SET_PRODUCT_TABLE_TYPE']),
    ...mapActions('order', ['GetOrderSearchSuggestions']),
    goal() {
      this.reachGoal('focusSearch');
    },
    change() {
      this.SET_SEARCH_TEXT(this.input);
    },
    async clear() {
      this.input = '';
      this.change();
      if (this.section === SECTION_TECDOC) {
        this.$store.commit('tecdoc/SET_HAS_SEARCH_REQUEST', false);
      }
      await this.search();
    },
    async search() {
      this.reachGoal('search');

      if (this.$store.state.basket.basketSize === 'big') {
        this.$store.commit('basket/CHANGE_BASKET_SIZE', 'default');
      }

      await this.catalogSearch();
      // if (this.searchQuery.match('[A-HJ-NPR-Z0-9]{17}')) {
      //   await this.tecDocSearch();
      // } else {
      //   await this.catalogSearch();
      // }
    },
    async catalogSearch() {
      if (this.$route.path !== '/products') await this.$router.push('/products');
      this.SET_EMPTY_TEXT('Загрузка');
      this.SET_PRODUCT_TABLE_TYPE('nikolProducts');
      this.SET_CATEGORY_ID(this.currentCategory === 'l-0' ? 0 : this.currentCategory);

      const { input } = this;
      const param = {
        search: input,
      };

      await this.GetProductsList(param)
        .then(async () => {
          // if (this.products.filter(product => product.stockbalance > 0).length === 0) {
          //   await this.tecDocSearch();
          // }
        });
    },
    async tecDocSearch() {
      if (this.$route.path !== '/find') await this.$router.push('/find');

      if (!this.searchQuery) {
        this.$store.commit('tecdoc/SET_HAS_SEARCH_REQUEST', false);
        return;
      }

      this.$store.commit('tecdoc/SET_TECDOC_LOADING_STATUS', true);
      try {
        await this.$store.dispatch('tecdoc/GetAutoByVin', {
          vin: this.searchQuery,
        });
        await this.$store.dispatch('tecdoc/GetBrandsByNumber', {
          number: this.searchQuery,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('tecdoc/SET_TECDOC_LOADING_STATUS', false);
        this.$store.commit('tecdoc/SET_HAS_SEARCH_REQUEST', true);
      }
    },
    /**
     * @callback suggestionsCallback
     * @param {[{value: String}]} suggestions
     */
    /**
     * @param {String} queryString
     * @param {suggestionsCallback} cb
     */
    querySearch(queryString, cb) {
      if (!queryString.length) {
        cb([]);
        return;
      }
      this.GetOrderSearchSuggestions(queryString)
        .then(results => cb(results))
        .catch((err) => {
          console.log(err);
          cb([]);
        });
    },
    handleSelect(result) {
      this.input = result.item.name;
      this.SET_SEARCH_TEXT(result.item.name);
      this.search();
    },
  }
};
</script>
<style lang="sass">
.search-block__input .el-input__inner
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  border: 0
  border-right: 1px solid #DCDFE6
</style>
<style lang="sass" scoped>
.search-block
  background: #fff
  border-radius: 4px
  display: flex
  justify-content: flex-start
  align-items: stretch
  margin-left: 20px
  margin-right: 20px
  flex-grow: 1
  @media (min-width: 1600px)
    margin-left: 140px
  @media (max-width: 600px)
    margin-left: 0
  &__actions
    display: inline-flex
    height: calc(100% - 2px)
    overflow: hidden
    position: relative
    top: 1px
  &__button
    cursor: pointer
    border: 0
    background-color: #3DB1FF
    color: #fff
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    height: 32px
    width: 45px
    flex-shrink: 0
  &__clear
    display: inline-block
    background: #fff
    border: 0
    color: #8D8D8D
    padding: 0
    font-size: 13px
    line-height: 124.5%
    margin: 0
    cursor: pointer
  &__selector
    flex-shrink: 0
    padding: 0 10px
    color: #8d8d8d !important
    font-size: 13px
    display: flex
    align-items: center
    cursor: pointer
  &__selector-text
    border-bottom: 1px dashed

@media (min-width: 1500px)
  .input-with-select
    width: 460px
</style>
