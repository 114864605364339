<template>
  <ElAlert
    v-if="offline"
    type="warning"
    show-icon
    title="Каталог временно недоступен из-за введенных ограничений от представителей TecDoc"
    class="offline"
    effect="dark"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'TecDocOffline',
  computed: {
    ...mapState('tecdoc', ['offline']),
  },
};
</script>

<style scoped lang="sass">
.offline
  width: calc(100% - 20px)
  margin: 0 10px
</style>
