<template>
  <div>
    <div class="m-md">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="$router.push('/tecdoc/brand')"
          >Общий каталог (TecDoc)</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span
            class="breadcrumb-item"
            @click="$router.push('/tecdoc/brand')"
          >Бренды</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <span class="breadcrumb-item">Модели</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <TecDocOffline />

    <div class="filters">
      <el-input
        v-model="search"
        style="width: 250px;"
        clearable
        size="small"
        placeholder="Поиск по названию"
      />
      <el-date-picker
        v-model="date"
        type="year"
        size="small"
        value-format="yyyy"
      />
    </div>

    <div style="display: flex;justify-content: center">
      <el-table
        :data="filteredAutos"
        :row-style="{ cursor: 'pointer' }"
        :height="height"
        stripe
        style="width: 100%;padding-left: 10px;padding-right: 10px"
        @row-click="getModifications"
      >
        <el-table-column
          prop="name"
          label="Модель"
        />
        <el-table-column
          prop="yearFrom"
          label="Начало производства"
        >
          <template #default="scope">
            {{ scope.row.yearFrom | date }}
          </template>
        </el-table-column>
        <el-table-column
          prop="yearTo"
          label="Окончание производства"
        >
          <template #default="scope">
            {{ scope.row.yearTo | date }}
          </template>
        </el-table-column>
        <el-table-column
          label=""
          width="10"
        >
          <div style="height: 21px;" />
        </el-table-column>
        <template #empty>
          <span
            v-if="!loading"
            class="el-table__empty-text"
          >Нет данных</span>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import TecDocOffline from '@/components/tecdoc/offline';

export default {
  name: 'Models',
  components: { TecDocOffline },
  filters: {
    date: (value) => {
      if (!value) return '';
      value = value.toString();

      return `${value.slice(4, 7)}/${value.slice(0, 4)}`;
    }
  },
  metaInfo: {
    title: 'Модели TecDoc'
  },
  data() {
    return {
      search: '',
      // manufacturerId: this.$route.params.manufacturerId,
      date: null
    };
  },
  computed: {
    height() {
      return 'calc(100vh - 125px)';
    },
    autos() {
      return this.$store.state.tecdoc.auto;
    },
    manufacturerId() {
      return this.$route.params.manufacturerId;
    },
    filteredAutos() {
      const autos = this.autos.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()));

      return autos.filter((data) => {
        if (!this.date) return true;

        if (!data.yearTo) {
          return data.yearFrom.slice(0, 4) < this.date;
        }

        return data.yearFrom.slice(0, 4) < this.date && data.yearTo.slice(0, 4) > this.date;
      });
    },
    loading() {
      return this.$store.state.tecdoc.loading;
    },
  },
  async mounted() {
    await this.$store.dispatch('tecdoc/GetAutoByBrand', { manufacturerId: this.manufacturerId });
  },
  methods: {
    async getModifications(model) {
      this.$router.push(`/tecdoc/modifications/${this.manufacturerId}/${model.id}`);
    },
  }
};
</script>

<style lang="sass" scoped>
.filters
  display: grid
  gap: 10px
  padding: 10px
  grid-template-columns: auto auto
  justify-content: start
  @media (max-width: 700px)
    grid-template-columns: auto
</style>
