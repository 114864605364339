<template>
  <div class="garage">
    <div class="wrapper">
      <page-header>
        Гараж
        <template #subtitle>
          Поставьте автомобиль в гараж, и вам достаточно будет нажать одну кнопку, чтобы начать подбирать запчасти.
        </template>
        <template #rightArea>
          <el-button
            icon="el-icon-plus"
            type="primary"
            size="mini"
            @click="isAddFormOpen = true"
          >
            Добавить авто в гараж
          </el-button>
        </template>
      </page-header>

      <div class="garage__filters">
        <el-select
          v-model="sortField"
          placeholder="Сортировка"
          @change="getCars"
        >
          <el-option
            value="createdAt"
            label="По дате добавления"
          />
          <el-option
            value="name"
            label="По имени"
          />
        </el-select>
        <el-input
          v-model="search"
          placeholder="Поиск"
          clearable
          class="garage__search"
          prefix-icon="el-icon-search"
          @input="getCars"
        />
      </div>
      <el-empty
        v-if="!isLoading && !cars.length"
        description="В вашем гараже нет автомобилей"
      />
      <div
        v-if="isLoading"
        v-loading="true"
        class="loader"
      />
      <div
        v-if="!isLoading && cars.length"
        class="cars"
      >
        <div
          v-for="car in cars"
          :key="car.id"
          class="car"
          @click="$router.push({ name: 'car', params: {car: car.id} })"
        >
          <div class="car__main">
            <div class="car__title">
              {{ car.name || 'Автомобиль без названия' }}
            </div>
            <div class="car__vin">
              {{ car.vin }}
            </div>
          </div>
          <div class="car__date">
            <div>Добавлен в гараж {{ formatDate(car.createdAt) }}</div>
          </div>
        </div>
      </div>
      <el-pagination
        small
        layout="prev, pager, next"
        :total="total"
        :page-size="50"
        hide-on-single-page
      />
    </div>
    <el-dialog
      title="Добавьте автомобиль в гараж"
      :visible.sync="isAddFormOpen"
      append-to-body
      width="400px"
    >
      <el-input
        v-model="vin"
        style="width: 100%"
        placeholder="Введите VIN или номер кузова"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="isAddFormOpen = false"
        >Отменить</el-button>
        <el-button
          type="primary"
          :disabled="!vin"
          :loading="isSending"
          @click="addHandler"
        >Добавить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formatDate from 'utils/formatDate';
import { mapActions, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';

export default {
  name: 'GaragePage',
  components: { PageHeader },
  data() {
    return {
      searchQuery: '',
      isAddFormOpen: false,
      vin: '',
      sortField: 'createdAt',
      sortOrder: 'asc',
      search: ''
    };
  },
  metaInfo: {
    title: 'Гараж'
  },
  computed: {
    ...mapState('garage', ['isSending']),
    ...mapState({
      cars: state => state.garage.cars,
      isLoading: state => state.garage.isLoading,
      total: state => state.garage.total,
    }),
  },
  async mounted() {
    await this.getCars()
  },
  methods: {
    formatDate,
    ...mapActions('garage', ['getItems', 'createItem']),
    ...mapActions('laximo', ['getVinOrFrameData']),
    async addHandler() {
      const model = await this.getVinOrFrameData(this.vin);
      const name = model ? model.brand + ' ' + model.name : 'Неизвестный автомобиль'
      await this.createItem({
        name: name,
        auto: this.vin,
        vin: this.vin,
        //userId: 0,
        phone: ""
      }).then(async () => {
        this.$message.success('Автомобиль добавлен в гараж');
        this.isAddFormOpen = false;
        await this.getCars();
      }).catch((error) => {
        this.$message.error(error || 'Произошла ошибка, попробуйте позже');
      })
    },
    async getCars() {
      return await this.getItems(
        {
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          q: this.search
        }
      ).catch((error) => {
        this.$message.error(error || 'Произошла ошибка, попробуйте позже');
      });
    },
  }
};
</script>
<style scoped lang="sass">

.garage
  padding: 20px
  margin: 0 auto
  overflow: auto
  &__filters
    display: flex
    justify-content: space-between
  &__search
    max-width: 300px
  .wrapper
    max-width: 800px
    margin: 0 auto
.loader
  height: 40px
.cars
  display: flex
  flex-direction: column
  gap: 10px
  margin-top: 20px
.car
  display: flex
  border-radius: 8px
  background-color: #fff
  cursor: pointer
  border: 1px solid #ebeef5
  box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
  padding: 10px 20px
  align-items: center
  &__main
    display: flex
    flex-direction: column
    gap: 4px
  &__title
    font-weight: bold
  &__vin
    color: rgba(0, 0, 0, 0.56)
    font-size: 13px
    line-height: 140%
  &__date
    margin-left: auto
    font-size: 12px
    color: #4e4e4e
</style>
