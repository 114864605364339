<template>
  <div :class="b({ type, small })">
    {{ status }}
  </div>
</template>

<script>
export default {
  name: 'OrderStatus',
  props: {
    status: {
      type: String,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    type() {
      const states = [
        ['Создан', 'orange'],
        ['В работе', 'blue'],
        ['Выполнен', 'green'],
        ['Принят', 'blue'],
        ['В резерве', 'yellow'],
        ['Отсутствует', 'gray'],
        ['Отправлен поставщику', 'purpur'],
        ['Добавлен', 'green'],
        ['Отгружен', 'green'],
        ['Аннулирован', 'red'],
        ['Отменен', 'red'],
        ['История', 'gray'],
      ];
      const a = states.find(item => this.status === item[0]);
      return a ? a[1] : 'gray';
    }
  }
};
</script>

<style scoped lang="sass">
.order-status
  flex-shrink: 0
  border-radius: 3px
  font-size: 11px
  text-align: center
  font-weight: bold
  width: 83px
  min-height: 23px
  display: inline-flex
  align-items: center
  justify-content: center
  text-transform: uppercase
  &_small
    min-height: 19px
    font-size: 10px
    width: 95px
  &_type
    &-green
      color: #3D8F5F
      border: 1px solid #3D8F5F
    &-gray
      color: #7F7F7F
      border: 1px solid #7F7F7F
    &-red
      color: #ff1c0a
      border: 1px solid #ff1c0a
    &-orange
      color: #FF9B0D
      border: 1px solid #FF9B0D
    &-blue
      color: #0074F7
      border: 1px solid #0074F7
    &-purpur
      color: #c000ff
      border: 1px solid #c000ff
    &-yellow
      color: #DC9157
      border: 1px solid #DC9157
</style>
