const columns = {
  type: Array,
  required: true,
  validator(val) {
    return !val.find((column) => {
      const {
        name, prop, width, align, sortable, show, translucent
      } = column;

      let err = false;
      if (typeof name !== 'string') {
        console.error('[NUI Validation:] name — это строка. name сейчас: ', name);
        err = true;
      }
      if (typeof prop !== 'string' || prop.length === 0) {
        console.error('[NUI Validation:] prop — это строка. prop сейчас: ', prop);
        err = true;
      }
      if (width !== undefined && (typeof width !== 'string' || ['px', '%', 'fr'].findIndex(ed => width.indexOf(ed) > -1) === -1)) {
        console.error('[NUI Validation:] width — это строка содержащая \'px\', \'%\' или \'fr\'. width сейчас: ', width);
        err = true;
      }
      if (align !== undefined && ['left', 'center', 'right'].findIndex(item => item === align) === -1) {
        console.error('[NUI Validation:] align — это [\'left\', \'center\', \'right\'] или undefined. align сейчас: ', align);
        err = true;
      }
      if (sortable !== undefined && typeof sortable !== 'boolean') {
        console.error('[NUI Validation:] sortable — это boolean или undefined. sortable сейчас: ', sortable);
        err = true;
      }
      if (show !== undefined && typeof show !== 'boolean') {
        console.error('[NUI Validation:] show — это boolean или undefined. show сейчас: ', show);
        err = true;
      }
      if (translucent !== undefined && typeof translucent !== 'boolean') {
        console.error('[NUI Validation:] translucent — это boolean или undefined. show сейчас: ', show);
        err = true;
      }
      return err;
    });
  }
};

export default columns;
