<template>
  <div
    v-if="activeCatalog"
    v-loading="loading"
    class="search-types"
  >
    <h2 v-if="!parts.length">
      {{ activeCatalog.name }}
    </h2>
    <h2 v-else-if="catalogInfo">
      {{ catalogInfo.brand }}
      {{ catalogInfo.name }}
    </h2>
    <div
      v-if="!parts.length"
      class="search-types__select-model"
    >
      <div
        v-for="(element, id) in activeCatalogWizards"
        :key="`wizard---${id}`"
        class="select-model__item"
      >
        <h2>{{ element.name }}</h2>
        <el-select
          v-if="!element.value"
          v-model="element.selected"
          :placeholder="element.name"
          @change="find(element.selected)"
        >
          <el-option
            v-for="item in element.values"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
        <div
          v-else
          class="el-input el-input--suffix select-model__item__del"
          @click="find(element.ssd)"
        >
          <input
            type="text"
            class="el-input__inner"
            :value="element.value"
            readonly
            disabled=""
            @click="find(element.ssd)"
          >
          <span class="el-input__suffix">
            <span class="el-input__suffix-inner">
              <i class="el-input__icon el-icon-close el-input__clear" />
            </span>
          </span>
        </div>
      </div>
    </div>

    <laximo-models-table
      v-if="activeCatalogAutos.columns && activeCatalogAutos.columns.length && !parts.length"
      :data="activeCatalogAutos.list"
      :columns="activeCatalogAutos.columns"
      @viewModel="viewModel"
    />
    <div
      v-if="parts.length"
      class="search-details"
    >
      <el-tree
        class="search-details__list"
        :data="parts"
        label="name"
        highlight-current
        :current-node-key="$route.query.quickgroupid"
        :default-expanded-keys="$route.query.quickgroupid ? [$route.query.quickgroupid] : []"
        node-key="quickgroupid"
        @node-click="$router.replace({query: { ...$route.query, quickgroupid: $event.quickgroupid }}).catch(noop)"
      />
      <div class="search-details__details">
        <div
          v-if="!details.length && !detailsLoading"
          class="search-details__empty-category"
        >
          Выберите раздел каталога
        </div>
        <div
          v-if="detailsLoading"
          v-loading="true"
          class="loader"
        />
        <div
          v-for="(detail, detailId) in details"
          :key="`detail---${detailId}`"
          class="search-details__detail"
        >
          <el-image
            :src="detail.attributes.imageurl.replace('%size%', '250')"
            :preview-src-list="[detail.attributes.imageurl.replace('%size%', 'source')]"
            fit="contain"
            lazy
          >
            <template #error>
              <span class="image-slot">нет изображения</span>
            </template>
          </el-image>
          <div>
            <span
              class="search-details__title"
              @click="toUnit(detail.attributes)"
            >{{ detail.attributes.name }}</span>
            <div class="search-details__complete">
              Включает:
              <ul>
                <li
                  v-for="(position, positionIndex) in detailInclude(detail.elements)"
                  :key="`position---${positionIndex}`"
                  @click="searchDetail(position.attributes.oem)"
                >
                  {{ position.attributes.name }} ({{ position.attributes.oem }})
                </li>
              </ul>
            </div>
            <el-button
              class="search-details__detail__button"
              size="small"
              type="primary"
              @click="toUnit(detail.attributes)"
            >
              Смотреть весь блок
              <i class="el-icon-arrow-right el-icon-right" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {noop} from "utils/functions";
import { mapActions, mapMutations, mapState } from 'vuex';

import LaximoModelsTable from '@/views/laximo/components/laximoModelsTable';
import nameConversion from '@/views/laximo/utils/nameConversion';

export default {
  name: 'LaximoModels',
  components: {
    LaximoModelsTable,
  },
  metaInfo: {
    title: 'По марке'
  },
  data: () => ({
    catalogInfo: null,
    wizard: null,

    ssd: '',
    autos: {},
    columns: [],

    parts: [],

    requestData: { ssd: undefined, catalog: undefined, vehicleid: undefined },
    details: {},

    loading: true,
    detailsLoading: false,
  }),
  computed: {
    ...mapState('laximo', ['catalogs', 'activeCatalog', 'activeCatalogId', 'activeCatalogWizards', 'activeCatalogAutos']),
  },
  watch: {
    async $route(val) {
      const { ssd, catalog, vehicleid, quickgroupid } = val.query;
      console.log(val.query)
      if (ssd && catalog && vehicleid && quickgroupid) {
        await this.getParts();
      } else {
        this.catalogInfo = null;
        this.requestData = { ssd: undefined, catalog: undefined, vehicleid: undefined };
        this.parts = [];
        await this.findActiveCatalog();
        await this.getListGroup({ssd, catalog, vehicleid});
        await this.CatalogInfo({ssd, catalog, vehicleid});
      }
    }
  },
  async mounted() {
    this.catalogInfo = null;
    this.requestData = { ssd: undefined, catalog: undefined, vehicleid: undefined };
    if (!this.catalogs[0] || !this.catalogs[0].children) await this.GetListCatalogs();
    const { ssd, catalog, vehicleid, quickgroupid } = this.$route.query;
    await Promise.all([
      this.findActiveCatalog(),
      this.getListGroup({ ssd, catalog, vehicleid }),
      this.CatalogInfo({ ssd, catalog, vehicleid }),
    ]).then(() => { this.loading = false; });
    if (quickgroupid) {
      await this.getParts()
    }
  },
  methods: {
    noop,
    ...mapMutations('laximo', ['SET_ACTIVE_CATALOG']),
    ...mapActions('laximo', [
      'GetListCatalogs',
      'GetVehicleInfo',
      'GetWizard2',
      'GetInfoByActiveCatalog',
      'GetAutos',
      'GetListQuickGroup',
      'GetListQuickDetail',
    ]),
    ...mapActions('products', ['GetSupplierProducts']),
    find(ssd) {
      this.ssd = ssd;
      this.GetInfoByActiveCatalog(ssd);
    },
    async viewModel({ ssd, catalog, vehicleid }) {
      await this.$router.push({
        path: '',
        query: {
          ssd,
          catalog,
          vehicleid,
        }
      });

      this.loading = true;
      await this.getListGroup({ ssd, catalog, vehicleid });
      await this.CatalogInfo({ ssd, catalog, vehicleid });
      this.loading = false;
    },

    async CatalogInfo({ ssd, catalog, vehicleid }) {
      if (!ssd || !catalog || !vehicleid) return;
      this.catalogInfo = await this.GetVehicleInfo({
        ssd,
        catalog,
        vehicleId: vehicleid,
      });
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async getParts() {
      const { ssd, catalog, vehicleid, quickgroupid } = this.$route.query;
      this.detailsLoading = true;
      this.details = [];
      await this.GetListQuickDetail({
        ssd,
        catalog,
        vehicleid,
        quickGroupId: quickgroupid,
      })
        .then((resp) => {
          this.details = resp.elements;
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
          this.detailsLoading = false;
        });
    },

    async getListGroup({ ssd, catalog, vehicleid }) {
      if (!ssd || !catalog || !vehicleid) return;
      this.requestData = { ssd, catalog, vehicleid };
      let id = 0;
      function r(items, pid) {
        if (!items) return [];
        return items.map((el) => {
          const parentId = pid;
          id++;
          return {
            id,
            ...el.attributes,
            label: el.attributes.name,
            children: r(el.elements, id),
            parentId,
          };
        });
      }

      this.parts = []

      const resp = await this.GetListQuickGroup({
        ssd,
        catalog,
        vehicleid,
      });
      this.parts = r(resp.elements);
    },

    async findActiveCatalog() {
      const catalog = this.catalogs[0].children.find(catalog => nameConversion(this.$route.params.name) === nameConversion(catalog.name));
      if (!catalog) {
        await this.$router.push('/oem');
        return;
      }
      this.SET_ACTIVE_CATALOG(catalog);
      await this.GetInfoByActiveCatalog();
    },

    toUnit({ ssd, unitid }) {
      this.$router.push({
        path: `/oem/${nameConversion(this.$route.params.name)}/${unitid}`,
        query: {
          ...this.requestData,
          ssd,
          catalog: this.activeCatalog.attributes.code
        }
      });
    },

    async searchDetail(article) {
      this.loading = true;
      await this.GetSupplierProducts({
        article,
        brand: this.activeCatalog.attributes.brand,
        tableType: 'part',
      })
        .then(() => {
          this.$router.push(`/part/${this.activeCatalog.attributes.brand}/${article}`);
        })
        .catch(() => {
          this.$alert('Подходящих предложений не найдено', 'Подбор', {
            confirmButtonText: 'OK',
          });
        });
      this.loading = false;
    },

    detailInclude(elements) {
      return [...new Set(elements.filter(item => item.attributes && item.attributes.oem && item.attributes.name))];
    }
  }
};
</script>

<style scoped lang="sass">
  .loader
    height: 40px
  h2
    margin-top: 20px
    margin-bottom: 10px
    font-size: 14px

  .text-overflow
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  .select-model
    &__item
      display: inline-flex
      flex-flow: column
      margin-right: 30px
      margin-bottom: 10px
      &__del
        width: 195px
        *
          cursor: pointer
      h2
        font-size: 11px
        margin-top: 0
        margin-bottom: 3px
    &__wrapper
      display: flex
      flex-flow: wrap
    &__list
      display: inline-flex
      border: 1px solid #1f75a8
      padding: 5px 10px
      border-radius: 5px
      font-size: 13px
      &:not(:last-child)
        margin-right: 10px

  .search-types
    height: 100%
    h2
      margin-top: 0
    &__footer
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 10px
    &__select-model
      grid-column: 1 / -1
      display: flex
      flex-flow: wrap
      align-items: flex-end
      .el-button
        margin-bottom: 10px
    &__type
      background: white
      padding: 15px
      box-shadow: 0 0 30px rgba(black, 0.05)

  .search-details
    display: grid
    grid-template-columns: 250px 1fr
    align-items: flex-start
    height: calc(100% - 50px)
    gap: 30px
    @media (max-width: 700px)
      display: flex
      flex-flow: column
      gap: 20px
    &__title
      display: block
      font-size: 14px
      margin-bottom: 10px
    &__complete
      font-size: 12px
      opacity: .7
      ul
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
        align-items: flex-start
        gap: 0 20px
        padding-left: 14px
        margin: 0
        @media (max-width: 700px)
          grid-template-columns: 1fr
      li
        font-size: 12px
        padding: 2px 0
        margin: 0
        cursor: pointer
        &:hover
          color: #1f75a8
    &__empty-category
      font-size: 12px
      margin-top: 10px
      opacity: .5
    &__list
      height: 100%
      overflow: auto
      width: 250px !important
      @media (max-width: 700px)
        height: auto !important
        max-height: 50vh !important
        flex-shrink: 0
        width: 100% !important
    &__details
      max-height: 100%
      overflow: auto
      @media (max-width: 700px)
        overflow: visible
    &__detail
      display: grid
      grid-template-columns: 250px 1fr
      gap: 10px
      background: #ffffff
      border: 1px solid #ebeef5
      padding: 10px
      transition: .2s
      @media (max-width: 700px)
        grid-template-columns: 1fr

      &:hover
        border-color: #1f75a8
      &:not(:last-child)
        margin-bottom: 15px
      img
        max-width: 250px
        max-height: 250px
        margin: 0 auto
        object-fit: contain
        border: 1px solid #ebeef5
        cursor: zoom-in
      &__button
        margin-top: 10px
        &:hover
          background-color: #5eade1 !important
          border-color: #5eade1 !important
      &__positions
        &__row
          display: grid
          grid-template-columns: 40px 1fr 80px
          gap: 0 10px
          align-items: flex-start
          span
            padding: 5px 0
            font-size: 11px
          &:not(:last-child)
            border-bottom: 1px solid #dcdada
          &_header
            margin-bottom: 5px

  .big-img
    display: flex
    align-items: center
    justify-content: center
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(black, .5)
    z-index: 100
    cursor: zoom-out
    img
      max-height: calc(100% - 30px)
      max-width: calc(100% - 30px)
</style>
