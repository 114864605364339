import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  categories: [
    {
      id: 0,
      children: [],
      name: 'Товары Николь',
      parent: null,
      collapsed: false,
    },
  ],
  currentCategory: 0,
});

const optimizeItem = item => Object.freeze(item);

const mutations = {
  SET_CATEGORIES(state, categories) {
    state.categories = [
      optimizeItem({
        id: 0,
        children: categories.map(optimizeItem),
        name: 'Товары Николь',
        parent: null,
        collapsed: false,
      }),
    ];
  },
  SET_CURRENT_CATEGORY(state, categoryId) {
    state.currentCategory = categoryId;
  },
};

const actions = {
  GetCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/categories',
      })
        .then((categories) => {
          commit('SET_CATEGORIES', categories);
          resolve();
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  SetCurrentCategoryData({ commit }, categoryId) {
    commit('SET_CURRENT_CATEGORY', categoryId);
  },
};


const getters = {
  categories: state => state.categories,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
