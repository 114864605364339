<template>
  <div class="return-order">
    <page-header
      back-button
      @back-button-click="$router.push({ name: 'view-products' })"
    >
      Оформление возврата
    </page-header>

    <template v-if="baskets.length">
      <return-order-create-basket-block
        v-for="basket in baskets"
        :key="basket.id"
        :selected.sync="selectedBaskets"
        :basket="basket"
      />

      <div class="summary">
        <div
          v-if="!selectedBaskets.length"
          class="no-selection-message"
        >
          У вас не выбрано ни одной корзины
        </div>
      </div>

      <div class="return-order__footer">
        <div class="return-order__footer-row">
          <div class="return-order__footer-row-title">
            <span class="return-order__footer-row-label">Комментарий к возврату:</span>
          </div>
          <div class="return-order__footer-row-content">
            <el-input
              v-model="orderComment"
              :rows="3"
              maxlength="255"
              show-word-limit
              type="textarea"
              placeholder="Укажите комментарий к возврату"
              size="mini"
              class="custom-select"
              resize="none"
            />
          </div>
        </div>
        <div class="return-order__footer-row">
          <div class="return-order__footer-row-title">
            <span class="return-order__footer-row-label">Выберите юр.лицо:</span>
          </div>
          <div class="return-order__footer-row-content">
            <el-select
              v-model="selectedOrgId"
              placeholder="Выберите юр.лицо"
              style="width: 100%"
              size="mini"
              class="custom-select"
            >
              <el-option
                v-for="option in orgOption"
                :key="option.value"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </div>
        </div>
        <div
          v-if="_role !== 'guest'"
          class="return-order__footer_row_three"
        >
          <el-button
            size="small"
            :type="selectedBaskets.length ? 'primary' : 'info'"
            :loading="isLoading"
            :disabled="!selectedBaskets.length"
            @click="doReturn"
          >
            Оформить возврат
          </el-button>
        </div>
        <div v-else>
          Вы не можете оформить заказ, пока находитесь в режиме гостя
        </div>
      </div>
    </template>
    <div
      v-else
      style="margin-top: 50px; font-size: 14px; color: #909399"
    >
      Для оформления заказа добавьте товары в корзину возврата
    </div>
  </div>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';
import ymMixin from 'utils/ymMixin';
import {
  mapActions, mapGetters, mapState
} from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import ReturnOrderCreateBasketBlock from '@/views/order/components/returnOrderCreateBasketBlock';

import orderTour from './orderTour';

export default {
  name: 'ReturnOrder',
  components: { ReturnOrderCreateBasketBlock, PageHeader },
  mixins: [ymMixin, orderTour],
  metaInfo: {
    title: 'Оформление возврата'
  },
  data() {
    return {
      selectedOrgId: '',
      isLoading: false,
      orderComment: '',
      selectedBaskets: []
    };
  },
  computed: {
    ...mapGetters('auth', ['_role']),
    ...mapState('accounts', {
      orgOption: 'org'
    }),
    ...mapState('returns', ['baskets', 'basketId']),
  },
  async mounted() {
    await this.GetReturnProductsBaskets();

    if (this.basketId) {
      this.selectedBaskets.push(this.basketId);
    }

    const organisations = await this.GetOrg();

    if (organisations.length) {
      if (organisations.length === 1) {
        this.selectedOrgId = organisations[0].id;
      } else {
        const lastSelectedOrgId = localStorage.getItem('selectedOrgId');
        this.selectedOrgId = organisations.find(item => +lastSelectedOrgId === item.id)
          ? +lastSelectedOrgId
          : '';
      }
    }
  },
  methods: {
    ...mapActions('accounts', ['GetOrg']),
    ...mapActions('returns', ['GetReturnProductsBaskets', 'PostReturnProducts']),
    normalizePrice,
    async doReturn() {
      this.isLoading = true;
      await this.PostReturnProducts({
        returnBasketIds: this.selectedBaskets,
        comment: this.orderComment,
        orgId: this.selectedOrgId
      }).then(async () => {
        this.$message.success('Возврат успешно создан!');
        await this.$router.push({ path: '/history' });
      })
        .catch((err) => { this.$alert(err.message || err); })
        .finally(() => {
          this.isLoading = false;
        });
    },
  }
};
</script>

<style lang="sass" scoped>
.return-order
  width: 100%
  background-color: #fff
  max-height: calc(100vh - 42px)
  overflow-y: auto
  padding: 20px 200px
  @media (max-width: 1300px)
    padding: 20px
  &__footer
    display: flex
    flex-direction: column
    margin-top: 15px
    width: max-content
    float: right
    color: #909399
    @media (max-width: 500px)
      width: 100%
    &_row_three
      display: flex
      justify-content: flex-end
    &-row
      display: flex
      align-items: flex-start
      margin-bottom: 15px
      @media (max-width: 700px)
        flex-flow: column
      @media (max-width: 500px)
        width: 100%
      &-title
        display: flex
        align-items: center
        flex-grow: 1
        padding-right: 15px
        font-size: 12px
        text-align: right
        min-height: 35px
        @media (max-width: 700px)
          min-height: auto
          margin-bottom: 2px
      &-label
        width: 100%
      &-content
        width: 350px
        flex-shrink: 0
        @media (max-width: 500px)
          width: 100%
.no-selection-message
  font-size: 12px
  color: #e6a23b
  @media (max-width: 700px)
    text-align: right
.summary
  display: grid
  align-items: center
  gap: 10px
  grid-template-areas: 'info prices'
  grid-template-columns: auto auto
  @media (max-width: 700px)
    grid-template-columns: auto
    grid-template-areas: 'info' 'prices'
  &__prices
    grid-area: prices
    font-size: 14px
    margin-right: 15px
    color: #909399
    text-align: right
</style>
