<template>
  <transition
    name="open"
    appear
  >
    <div class="OrderHistoryChangedModal">
      <el-button
        class="OrderHistoryChangedModal__close"
        icon="el-icon-close"
        size="mini"
        @click="closeModal"
      />
      <div class="OrderHistoryChangedModal__title">
        Изменения в ваших заказах
      </div>
      <div class="OrderHistoryChangedModal__subtitle font-md">
        У товаров в истории изменилась дата доставки
      </div>
      <div class="OrderHistoryChangedModal__content">
        <n-table
          :data="orderHistoryChanges"
          :loading="loading"
          :table-loading="loading && !orderHistoryChanges.length"
          :expected-length="3"
          :columns="[
            { name: 'Заказ', prop: 'orderId', width: '100px' },
            { name: 'Артикул', prop: 'article', width: '100px' },
            { name: 'Бренд', prop: 'brandName', width: 'minmax(100px, 1fr)' },
            { name: 'Товар', prop: 'productName', width: 'minmax(200px, 1fr)' },
            { name: 'Доставка', prop: 'deliveryDate', width: '100px', align: 'center' },
          ]"
          size="small"
        >
          <template #productName="{ row }">
            <div v-if="row.supplier === null">
              {{ row.productName }}
            </div>
            <div v-else>
              {{ JSON.parse(row.supplier).name }}
            </div>
          </template>
          <template #article="{ row }">
            <div v-if="row.supplier === null">
              {{ row.article }}
            </div>
            <div v-else>
              {{ JSON.parse(row.supplier).article }}
            </div>
          </template>

          <template #brandName="{ row }">
            <div v-if="row.supplier === null">
              {{ row.brand }}
            </div>
            <div v-else>
              {{ JSON.parse(row.supplier).brand }}
            </div>
          </template>
          <template #deliveryDate="{ row }">
            {{ formatDate(row.deliveryDate) }}
          </template>
        </n-table>
      </div>
      <div class="OrderHistoryChangedModal__footer">
        <span
          class="OrderHistoryChangedModal__link"
          @click="open"
        >
          Перейти к заказам
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'OrderHistoryChangesModal',
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState({
      orderHistoryChanges: state => state.order.orderHistoryChanges
    }),
  },
  methods: {
    ...mapActions('order', ['onDeliveryDatesChanges']),
    closeModal() {
      this.onDeliveryDatesChanges(null);
    },
    open() {
      this.$router.push('/history');
      this.closeModal();
    },
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
  }
};
</script>

<style lang="sass">
.OrderHistoryChangedModal .n-table__body
  max-height: 110px
</style>
<style scoped lang="sass">
  .open-enter,
  .open-leave-to
    opacity: 0
    transform: translateY(-5px)
  .open-leave,
  .open-enter-to
    opacity: 1
    transform: translateY(0)
  .open-enter-active,
  .open-leave-active
    transition: all .3s
  .n-table__body
    max-height: 20px
  .OrderHistoryChangedModal
    position: absolute
    top: 100%
    color: #000
    background: #fff
    border: 1px solid #ebeef5
    border-radius: 6px
    z-index: 10000
    padding-top: 10px
    box-shadow: 0 0 6px rgba(0, 0, 34, 0.15)
    transform: translateX(-150px)
    &:before
      content: ''
      display: block
      position: absolute
      top: -6px
      left: 181px
      width: 0
      height: 0
      border-left: 6px solid transparent
      border-right: 6px solid transparent
      border-bottom: 6px solid #fff
    &__title
      padding: 0 10px
      font-size: 12px
      font-weight: bold
    &__subtitle
      color: #909399
      padding: 0 10px
    &__close
      position: absolute
      right: 10px
      top: 10px
    &__content
      padding: 10px 10px 0
    &__footer
      display: flex
      justify-content: space-between
      align-items: center
      font-size: 12px
    &__unsubscribe-button
      margin-left: 10px
    &__link
      display: inline-block
      color: #1f75a8
      cursor: pointer
      padding: 10px
      text-decoration: none
      transition: 0.2s
      &:hover
        background: radial-gradient(circle at bottom right, #ecf5ff, white 50%)
    &__close-dropdown-item
      padding: 0 10px !important
</style>
