<template>
  <table
    ref="NTable"
    :class="b({loading})"
    @scroll.passive="scroll"
  >
    <thead :class="b('head')">
      <tr :style="{ gridTemplateColumns }">
        <th
          v-for="({ name, prop, sortable, align }, colIndex) in columnsArray"
          :key="`colTitle---${colIndex}`"
          :class="b('col', { head: true, sortable, active: prop === defaultSort.prop && sortable, align })"
          @click.prevent="sort(prop, sortable)"
        >
          <slot :name="`${prop}Head`">
            <span v-once>{{ name }}</span>
          </slot>
          <sort-arrows
            v-if="sortable"
            :class="b('arrows')"
            :active="prop === defaultSort.prop"
            :order="defaultSort.order"
          />
        </th>
      </tr>
    </thead>
    <tbody
      ref="body"
      :class="b('body')"
    >
      <div
        v-for="(section, sectionIndex) in Object.values(data)"
        :key="`section---${sectionIndex}`"
      >
        <div
          :key="`sectionHeader---${sectionIndex}`"
          :class="b('section-header')"
          :style="{ background: section.background, color: section.color }"
        >
          <slot
            name="sectionHeader"
            :section="section"
          >
            {{ section.name }}
          </slot>
        </div>
        <template v-for="(sectionRow, rowIndex) in section.data">
          <NTableRow
            :key="`row---${rowIndex}`"
            :classes="rowClass(sectionRow, rowIndex)"
            :columns="columnsArray"
            :template-columns="gridTemplateColumns"
            :row-index="rowIndex"
            :row="sectionRow"
            :propertis-array="propertisArray"
            :active="activeRowId === (rowIndexKey ? sectionRow[rowIndexKey] : rowIndex)"
            @click="clickOnRow(sectionRow, rowIndex)"
          >
            <template #default="{ row, prop }">
              <slot
                :name="prop"
                :row="row"
                v-text="row[prop]"
              />
            </template>
            <template #after="{ row }">
              <slot
                name="after"
                :row="row"
                :columns="columnsArray"
                :grid-template-columns="gridTemplateColumns"
              />
            </template>
          </NTableRow>
        </template>
        <div
          v-if="!section.data.length"
          :class="b('empty-text')"
        >
          <slot
            name="emptyText"
            :section="section"
          >
            {{ emptyText }}
          </slot>
        </div>
      </div>
    </tbody>
    <div ref="scrollEnd" />
  </table>
</template>

<script>
import NTable from './NTable';

export default {
  ...NTable,
  name: 'NBlockTable',
  block: 'NTable',
  props: {
    ...NTable.props,
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass" src="./table.sass"></style>
