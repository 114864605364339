<template>
  <div :class="b()">
    <page-header>
      Взаиморасчёты
    </page-header>

    <div
      v-loading="loading"
      :class="b('filters')"
    >
      <div :class="b('data')">
        <div
          class="font-md"
          :class="b('label')"
        >
          <strong>Дата документа</strong>
        </div>
        <div class="block">
          <el-date-picker
            v-model="date"
            class="date-picker-history"
            type="daterange"
            size="mini"
            align="right"
            style="width: 100%"
            unlink-panels
            range-separator="|"
            start-placeholder="С"
            end-placeholder="По"
            :picker-options="pickerOptions"
            value-format="yyyy.MM.dd"
            @change="getData"
          />
        </div>
      </div>
      <div :class="b('users')">
        <div
          class="font-md"
          :class="b('label')"
        >
          <strong>Выберите юр. лицо</strong>
        </div>
        <el-select
          v-model="selectedLegalEntity"
          value-key="id"
          class="user-input__select"
          size="small"
          style="width: 100%"
          placeholder="Выберите юр.лицо"
          :loading="organizationsLoading"
        >
          <el-option
            v-for="option in organizations"
            :key="option.id"
            :label="option.name"
            :value="option"
          />
        </el-select>
      </div>
      <div>
        <div
          class="font-md"
          :class="b('label')"
        >
          <strong>Общая сумма расчётов:</strong>
        </div>
        <div class="receivables__total">
          <price-output :price="normalizePrice(documentsSum)" />
        </div>
      </div>
      <div>
        <div
          class="font-md"
          :class="b('label')"
        >
          <strong>Только просроченные:</strong>
        </div>
        <el-checkbox
          :checked="filteredByExpired"
          @change="filteredByExpired = $event"
        />
      </div>
    </div>
    <el-empty
      v-if="!receivablesByDocuments"
      description="Нет данных"
    />
    <template v-else>
      <div :class="b('list')">
        <receivables-item
          v-for="accountId in Object.keys(receivablesByDocuments)"
          :key="accountId"
          :documents="filterByExpired(receivablesByDocuments[accountId])"
          :store="getOrganization(accountId)"
          :sort-order="sortOrder"
          :sort-field="sortField"
          @showOrder="displayOrderData($event)"
          @sort="setSort"
        />
      </div>
    </template>
    <el-dialog
      :visible="order !== null"
      title="Заказ"
      width="90%"
      @close="order = null"
    >
      <order-block
        v-if="order"
        :order="order"
        search-query=""
      />
    </el-dialog>
  </div>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';
import { mapActions, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import OrderBlock from '@/views/history/components/orderBlock';
import ReceivablesItem from '@/views/receivables/ReceivablesItem';

/**
 *
 * @type {DatePickerOptions}
 */
const pickerOptions = {
  shortcuts: [{
    text: 'Последняя неделя',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  }, {
    text: 'Последний месяц',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  }, {
    text: 'Последние 3 месяца',
    onClick(picker) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  }],
};

export default {
  name: 'ReceivablesPage',
  components: { OrderBlock, ReceivablesItem, PageHeader },
  metaInfo: {
    title: 'Взаиморасчёты'
  },
  data: () => ({
    pickerOptions,
    date: [],
    /** @type {Organization|null} */
    legalEntity: null,
    orderLoading: false,
    order: null,
    sortOrder: 'desc',
    sortField: 'dateShipping',
    filteredByExpired: false
  }),
  computed: {
    ...mapState('receivables', ['receivable', 'receivablesByDocuments', 'loading', 'organizations', 'organizationsLoading']),
    documentsSum() {
      /**
       * @return {number}
       * @param {number} sum
       * @param {ReceivableDocument} document
       */
      const documentsSum = (sum, document) => sum + document.amount;

      return this.receivablesByDocuments
        ? Object.values(this.receivablesByDocuments)
          .reduce((sum, organizationDocuments) => sum + organizationDocuments.reduce(documentsSum, 0), 0)
        : 0;
    },
    selectedLegalEntity: {
      async set(legalEntity) {
        this.legalEntity = legalEntity;
        await this.getData();
      },
      get() {
        return this.legalEntity;
      }
    },
  },
  async mounted() {
    await this.getReceivablesByDocumentsOrganizations();
    if (this.organizations.length) {
      this.legalEntity = this.organizations[0];
    }
    await this.getData();
  },
  methods: {
    ...mapActions('receivables', ['getReceivablesByDocuments', 'getReceivablesByDocumentsOrganizations']),
    ...mapActions('accounts', ['GetOrg']),
    ...mapActions('order', ['GetOrder']),
    normalizePrice,
    async getData() {
      const getDate = dateString => (dateString ? dateString.replaceAll('.', '-') : null);
      await this.getReceivablesByDocuments(
        {
          orgId: this.legalEntity?.id,
          from: getDate(this.date && this.date[0]),
          to: getDate(this.date && this.date[1]),
          sortOrder: this.sortOrder,
          sortField: this.sortField,
        }
      );
    },
    /**
     * @param {string|number} organizationId
     * @returns {Organization|null}
     */
    getOrganization(organizationId) {
      return this.organizations.find(organization => Number(organization.accountId) === Number(organizationId));
    },
    displayOrderData(orderId) {
      this.orderLoading = true;
      this.GetOrder(orderId).then((orderData) => {
        this.order = orderData;
      }).catch(() => {
        this.$message.error(`Заказ ${orderId} не найден`);
      }).finally(() => {
        this.orderLoading = false;
      });
    },
    setSort(e) {
      this.sortOrder = e.order.toLowerCase();
      this.sortField = e.prop;
      this.getData();
    },
    filterByExpired(documents) {
      if (this.filteredByExpired) {
        return documents.filter(document => new Date(document.scheduledPaymentDate) < new Date());
      }
      return documents;
    }
  }
};
</script>

<style scoped lang="sass">
.receivables-page
  max-width: 1200px
  margin: 0 auto
  padding: 20px
  &__label
    margin-bottom: 5px
  &__filters
    display: grid
    grid-template-columns: 300px 1fr 1fr 1fr
    gap: 10px 20px
    margin-bottom: 20px
    @media (max-width: 800px)
      grid-template-columns: 1fr
  &__total
    line-height: 32px
  &__list
    display: grid
    grid-gap: 10px
    margin-top: 30px
</style>
