import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  hasNotification: false,
  isModalOpen: false,
  productsInStock: []
});

const mutations = {
  SET_HAS_NOTIFICATION(state, hasNotification) {
    state.hasNotification = hasNotification;
  },
  SET_IS_MODAL_OPEN(state, isOpen) {
    state.isModalOpen = isOpen;
  },
  SET_PRODUCTS(state, items) {
    state.productsInStock = items;
  },
};
const actions = {
  /**
   * Add product to wish list
   * @param _
   * @param productId Product identifier
   * @param quantity Item quantity
   * @param basketName Basket name
   * @return {Promise<unknown>}
   */
  async addToWishList(_, { productId, quantity, basketName = null }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/wishlist',
        method: 'POST',
        data: {
          productId,
          quantity,
          basketName,
        }
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  async wishListUpdate({ commit, dispatch }) {
    const lastWishlistNotificationTimeString = localStorage.getItem('lastWishlistNotificationTime');
    localStorage.setItem('lastWishlistNotificationTime', new Date().toString());
    if (lastWishlistNotificationTimeString) {
      const lastWishlistNotificationTime = new Date(lastWishlistNotificationTimeString);
      const today = new Date();
      if (lastWishlistNotificationTime.getDate() === today.getDate()
        && lastWishlistNotificationTime.getMonth() === today.getMonth()
        && lastWishlistNotificationTime.getFullYear() === today.getFullYear()) {
        return;
      }
    }
    commit('SET_HAS_NOTIFICATION', true);
    if (!localStorage.getItem('doNotShowWishlistModal')) {
      dispatch('getProductsInStock');
    }
  },
  async getProductsInStock({ commit }) {
    await axiosInstance('/api/wishList/inStock')
      .then((resp) => {
        commit('SET_PRODUCTS', resp);

        if (!localStorage.getItem('doNotShowWishlistModal')) {
          commit('SET_IS_MODAL_OPEN', true);
        }
      });
  },
  async changeProductNotificationState(_, { productId, enabled }) {
    await axiosInstance.put(`/api/wishList/${productId}`, {
      notification: enabled
    });
  },
  async changeProductQuantity(_, { productId, quantity }) {
    await axiosInstance.put(`/api/wishList/${productId}`, {
      quantity
    });
  },
  closeModal({ commit }) {
    commit('SET_IS_MODAL_OPEN', false);
    commit('SET_HAS_NOTIFICATION', false);
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
