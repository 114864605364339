import calcStep from './calcQuantityStep';

export default function calcMax(row) {
  let count = 9999;
  if (row.supplier && typeof row.supplier === 'string') count = JSON.parse(row.supplier).stocks.count;
  if (row.supplier && typeof row.supplier === 'object') count = row.supplier.stocks.count;
  if (row.warehouseId === 1) count = row.stockBalance;
  if (row.warehouseId === 2) count = row.stockBalanceRegionalWarehouse;
  return count - (count % calcStep(row));
}
