<template>
  <div>
    <template v-if="hasSearchRequest">
      <section class="search-results">
        <h3 class="search-results__title">
          Результаты поиска по VIN:
        </h3>
        <ul
          v-if="autosByVIN && autosByVIN.length"
          class="search-results__list"
        >
          <li
            v-for="(auto, index) in autosByVIN"
            :key="`brandName---${index}`"
            class="search-results__item"
          >
            <a
              href="#"
              class="search-results__link"
              @click.prevent="getModificationByVIN(auto.modelId, auto.manufacturerId)"
            >
              {{ auto.name }}
            </a>
          </li>
        </ul>
        <p
          v-else
          class="search-results__empty"
        >
          Нет результатов
        </p>
      </section>

      <section class="search-results">
        <h3 class="search-results__title">
          Результаты поиска в каталоге TecDoc:
        </h3>
        <div
          v-if="brandsByNumber && Object.keys(brandsByNumber).length"
          class="search-results__tecdoc"
        >
          <n-table
            v-for="i in brandTablesCount(Object.keys(brandsByNumber).length)"
            :key="'table' + i"
            :data="Object.keys(brandsByNumber).slice((i - 1) * 7, 7 * i)"
            :expected-length="3"
            :columns="[
              { name: 'Бренд', prop: 'brand', width: '120px', translucent: true },
              { name: 'Наименование в каталоге', prop: 'name', translucent: true },
            ]"
            @click-on-row="row => searchDetail(brandsByNumber[row])"
          >
            <template #brand="{ row }">
              <div style="color: #000">
                {{ brandsByNumber[row].brand }}
              </div>
            </template>
            <template #name="{ row }">
              <div
                v-if="brandsByNumber[row].description"
                style="color: #000"
              >
                {{ brandsByNumber[row].description }}
              </div>
              <div
                v-else
                style="color: #D4D4D4"
              >
                Нет информации
              </div>
            </template>
          </n-table>
        </div>
        <p
          v-if="!brandsByNumber"
          class="search-results__empty"
        >
          Нет результатов
        </p>
      </section>
    </template>

    <div
      v-if="!hasSearchRequest"
      style="padding: 10px;"
    >
      <el-tabs
        v-model="editableTabsValue"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          label="Все"
          tabtype="all"
        />
        <el-tab-pane
          label="Легковые"
          tabtype="word"
          name="1"
        />
        <el-tab-pane
          label="Грузовые"
          tabtype="word"
          name="2"
        />
        <el-tab-pane
          label="Популярные"
          tabtype="favorites"
          name="3"
        />

        <el-tab-pane
          label="A"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="B"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="C"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="D"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="E"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="F"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="G"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="H"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="I"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="J"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="K"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="L"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="M"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="N"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="O"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="P"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="Q"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="R"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="S"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="T"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="U"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="V"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="W"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="X"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="Y"
          tabtype="alphabet"
        />
        <el-tab-pane
          label="Z"
          tabtype="alphabet"
        />
      </el-tabs>
    </div>

    <template v-if="!hasSearchRequest">
      <div
        v-if="tabTypeBrand === 'favorites'"
        class="brand-container"
      >
        <div
          v-for="(brands, index) in autoBrands"
          :key="`brand-container---${index}`"
          style="margin: 10px;border: 1px solid #f0f0f0;border-radius: 5px"
        >
          <div class="brand">
            <div class="letter">
              <b>{{ index }}</b>
            </div>
            <div class="brand-names-f">
              <div
                v-for="(brand, i) in brands"
                :key="i"
                style="width: 200px"
              >
                <div
                  class="brandName font-md"
                  @click="getAutos(brand.id)"
                >
                  {{ brand.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="brand-container"
      >
        <div
          v-for="(brands, i) in autoBrands"
          :key="`brand-container---${i}`"
          style="margin: 10px;border: 1px solid #f0f0f0;border-radius: 5px"
        >
          <div class="brand">
            <div class="letter">
              <b>{{ i }}</b>
            </div>
            <div class="brand-names">
              <div
                v-for="(brand, inx) in brands"
                :key="`brand-names---${inx}`"
                style="width: 200px"
              >
                <div
                  class="brandName font-md"
                  @click="getAutos(brand.id)"
                >
                  {{ brand.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Brands',
  data() {
    return {
      localManufacturerId: null,
      editableTabsValue: '3'
    };
  },
  computed: {
    ...mapState({
      autosByVIN: state => state.tecdoc.autosByVIN,
      tabTypeBrand: state => state.tecdoc.tabTypeBrand,
      brandsByNumber: state => state.tecdoc.brandsByNumber,
      hasSearchRequest: state => state.tecdoc.hasSearchRequest,
      searchQuery: state => state.products.search,
    }),
    ...mapGetters({
      autoBrands: 'tecdoc/autoBrands',
    }),
  },
  methods: {
    articleDescription(article) {
      const { brand = 'не указано', description = 'не указано' } = article;
      return article ? `${brand} | ${description}` : '';
    },
    setTabTypeBrand(type) {
      this.$store.commit('tecdoc/SET_TAB_TYPE_BRAND', type);
    },
    async getModificationByVIN(modelId, manufacturerId) {
      this.$emit('manufacturer', manufacturerId);
      this.localManufacturerId = manufacturerId;
      await this.getCategories(modelId);
    },
    async getCategories(modification) {
      await this.$router.push(`/tecdoc/catalog/${modification}`);
      this.$store.commit('tecdoc/SET_ARTICLES_SIMPLIFIED', []);
    },
    async searchDetail({ brand, number }) {
      await this.$store.dispatch('products/GetSupplierProducts', {
        article: number,
        brand,
        tableType: 'part',
      })
        .then(() => {
          this.$router.push(`/part/${brand}/${number}`);
        })
        .catch(() => this.$alert('Подходящих предложений не найдено', 'Подбор', {
          confirmButtonText: 'OK',
        }));
    },
    handleClick(tab) {
      const { tabtype } = tab.$attrs;

      if (tabtype === 'all') {
        if (this.tabTypeBrand === 'custom') {
          this.$store.dispatch('tecdoc/GetAutoBrand', {});
        }

        this.setTabTypeBrand(tabtype);
      }

      if (tabtype === 'favorites') {
        this.setTabTypeBrand('favorites');
      }

      if (tabtype === 'word') {
        this.$store.dispatch('tecdoc/GetAutoBrand', { carType: tab.name });

        this.setTabTypeBrand('custom');
      }

      if (tabtype === 'alphabet') {
        if (this.tabTypeBrand === 'custom') {
          this.$store.dispatch('tecdoc/GetAutoBrand', {});
        }
        this.setTabTypeBrand(tab.label);
      }
    },
    async getAutosByVIN() {
      this.$store.commit('tecdoc/SET_TECDOC_LOADING_STATUS', true);
      try {
        await this.$store.dispatch('tecdoc/GetAutoByVin', {
          vin: this.searchQuery,
        });
        await this.getBrandsByNumber(this.searchQuery);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('tecdoc/SET_TECDOC_LOADING_STATUS', false);
        this.hasSearchRequest = true;
      }
    },
    getBrandsByNumber(number) {
      return this.$store.dispatch('tecdoc/GetBrandsByNumber', {
        number,
      });
    },
    async getAutos(manufacturerId) {
      this.$router.push(`/tecdoc/model/${manufacturerId}`);
      // await this.$store.dispatch('tecdoc/GetAutoByBrand', { manufacturerId });
    },
    brandTablesCount(resultsCount) {
      const resultsPerTable = 7;
      return (resultsCount >= (resultsPerTable * 3) ? 3 : Math.ceil(resultsCount / resultsPerTable));
    },
  },
};
</script>

<style>
.search-results .n-table-row {
  cursor: pointer;
}
</style>
<style scoped>
  .brandName {
    width: max-content;
    padding: 5px 25px 5px 5px;
    cursor: pointer;
  }
  .brandName:hover {
    color: #1f75a8;
  }
  .brand-names {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    flex-wrap: wrap;
    flex-flow: wrap;
  }
  .brand-names-f {
    display: flex;
    flex-direction: column;
    height: 108px;
    flex-wrap: wrap;
  }
  .letter {
    background-color: #f0f0f0;
    padding: 10px;
    width: 36px;
    display: flex;
    justify-content: center;
  }
  .brand {
    display: flex;
  }
  .brand-container {
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    height: calc(100vh - 174px);
    overflow-y: scroll ;
  }

  .search-results {
    padding: 10px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  .search-results__title {
    margin: 0 0 6px 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 124.5%;
    color: #000;
  }

  .search-results__tecdoc {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px
  }

  .search-results__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
    list-style-type: none;
  }

  .search-results__item {
    max-width: 33.33%;
    padding: 5px 10px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .search-results__link {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease-in-out;
  }

  .search-results__link:hover,
  .search-results__link:focus {
    color: #1f75a8;
  }

  .search-results__empty {
    font-size: 13px;
    line-height: 124.5%;
    margin: 0 0 20px;
  }
</style>
