<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <div class="personal">
      <div class="personal__header">
        <div class="title">
          Новый релиз
        </div>
      </div>

      <div class="new-log-message">
        <el-input
          v-model="text"
          type="textarea"
          :autofocus="true"
          :rows="10"
        />
        <div
          class="new-log-message__html log-block"
          v-html="html"
        />
        <div class="new-log-message__footer">
          <el-button
            :loading="loading"
            class="new-log-message__button"
            @click="sendUpdate"
          >
            Добавить запись в чейнджлог
          </el-button>
        </div>
      </div>
      <div class="updates">
        <div class="personal__header">
          <div class="title">
            Лог изменений
          </div>
        </div>
        <div
          v-for="item in updates"
          :key="`update---${item.id}`"
          class="log-block"
        >
          <div v-html="item.text" />
          <span class="time">{{ new Date(item.createdAt).toLocaleString() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import marked from 'marked';
import { mapActions } from 'vuex';

export default {
  name: 'ViewAdminChangelog',
  metaInfo: {
    title: 'Лог изменений'
  },
  data() {
    return {
      text: '### Обновление от ',
      html: '<h3>Обновление от </h3>',
      updates: [],

      loading: false,
    };
  },
  watch: {
    text(val) {
      this.html = marked(val);
    }
  },
  mounted() {
    this.getAllUpdates();
  },
  methods: {
    ...mapActions('admin', ['GetUpdates', 'PushUpdate']),

    async getAllUpdates() {
      this.GetUpdates()
        .then((items) => {
          this.updates = items;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    async sendUpdate() {
      this.loading = true;
      await this.PushUpdate(this.html)
        .then(() => {
          this.$message.success('Ченджлог добавлен');
          this.text = '### Обновление от ';
          this.html = '<h3>Обновление от </h3>';
          this.loading = false;
        })
        .catch(({ message }) => {
          this.$message.error(message);
        });
      await this.GetUpdates()
        .then((items) => {
          this.updates = items;
        });
    },
  }
};
</script>

<style scoped lang="sass" src="../admin/access/admin.sass" />
