export default function pipeline(context, middleware, index) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = pipeline(
      context, middleware, index + 1,
    );

    nextMiddleware({
      ...context,
      nextMiddleware: nextPipeline,
    });
  };
}
// NOTE: рекурсивная функция, сама себя вызывает, чтоюы пройтись по массиву мидлваров.
// и проверить их метку мета.
