import axiosInstance from "api/axiosInstance";

export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    isSending: false,
    /** @type IGarageItem[] */
    cars: [],
    /** @type number */
    total: 0,
  }),
  mutations: {
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_IS_SENDING(state, isSending) {
      state.isSending = isSending;
    },
    SET_CARS(state, carsList) {
      state.cars = carsList;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
  },
  actions: {
    /**
     *
     * @param commit
     * @param {number} max
     * @param {number} offset
     * @param {string} sortField
     * @param {'ASC'|'DESC'} sortOrder
     * @param {number} userId
     * @returns {Promise<IGarageItem[]>}
     */
    async getItems({commit}, {max, offset, sortField, sortOrder, userId, q} = {}) {
      commit('SET_IS_LOADING', true);
      return await axiosInstance({
        url: '/api/garage',
        params: {
          max, offset, sortField, sortOrder, userId, q
        }
      })
        .then((response) => {
          commit('SET_CARS', response.rows);
          commit('SET_TOTAL', response.count);
          return response.rows
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    },
    async getItem(_, id) {
      return await axiosInstance({
        url: '/api/garage/' + id,
      });
    },
    async createItem({commit}, data) {
      commit('SET_IS_SENDING', true);
      return await axiosInstance({
        url: '/api/garage',
        method: 'post',
        data
      })
        .finally(() => {
          commit('SET_IS_SENDING', false);
        });
    },
    async updateItem(_, {id, data}) {
      await axiosInstance({
        url: '/api/garage/' + id,
        method: 'patch',
        data
      });
    },
    async deleteItem(_, id) {
      await axiosInstance({
        url: '/api/garage/' + id,
        method: 'delete'
      })
    },
  }
}
