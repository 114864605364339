<template>
  <div class="app-tree">
    <slot name="header" />
    <ul class="app-tree__list">
      <template v-for="node of nodes">
        <app-tree-node
          :key="node.id"
          :node="node"
          @click="nodeClickHandler"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import AppTreeNode from './app-tree-node.vue';

export default {
  name: 'AppTreeRoot',
  components: {
    AppTreeNode,
  },
  props: {
    nodes: {
      type: [Array, Object],
      default: () => [],
    },
  },
  methods: {
    nodeClickHandler(id) {
      this.$emit('selected', id);
    }
  },
};
</script>

<style scoped>
  .app-tree {
    padding-right: 5px;
    padding-left: 5px;
    width: 240px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .app-tree__list {
    min-width: 270px;
    padding: 0;
    margin: 0;
    list-style: none;
    color: black;
  }
</style>
