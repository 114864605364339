/**
 * createOrderCheckErrors проверяет, выбор корзин и заполнение селектов
 * @param ctx {this} - this
 * @return {string}
 */
export default function createOrderCheckErrors(ctx) {
  const {
    multipleSelection, selectedOrgId, selectedAddress, multiDataSum, minimumOrderSum, userCurrencySign
  } = ctx;
  if (!multipleSelection.length) { // Корзина
    return 'Необходимо выбрать корзину для заказа';
  }
  if (!selectedOrgId) { // Юр. лицо
    localStorage.removeItem('selectedAddress');
    return 'Юр.лицо не выбрано';
  }
  if (!selectedAddress) { // Адрес доставки
    return 'Адрес не выбран';
  }
  if (multiDataSum < minimumOrderSum) {
    return `Сумма заказа должна быть не менее ${minimumOrderSum} ${userCurrencySign}`;
  }
  return '';
}
