<template>
  <div
    v-loading="loading"
    :class="b()"
  >
    <div
      :class="b('header')"
      @click="openInfo"
    >
      <div class="title-wrap">
        <div style="display: flex;flex-direction: column;gap:5px">
          <order-status
            v-if="order.stateName"
            :status="order.stateName"
          />
          <order-status
            v-if="order.isOrderFrom1c"
            status="оффлайн"
          />
        </div>

        <h2>Заказ №{{ order.externalOrderId || order.id }}</h2>
      </div>
      <div :class="[b('data'), b('date')]">
        <span>Даты создания</span>
        {{ formatDate(order.createdAt) }}
      </div>
      <div
        v-if="_roleAdmin"
        :class="[b('data'), b('name')]"
      >
        <span>Имя</span>
        {{ order.userName }}
      </div>
      <div
        v-if="_roleAdmin"
        :class="b('data')"
      >
        <span>Контрагент</span>
        {{ order.accountName }}
      </div>
      <price-output
        v-if="!visibleInfo"
        :class="b('price')"
        hide-currency
        :price="normalizePrice(order.sumPrice)"
      />
      <button
        v-if="!full"
        :class="b('view-more-button', { active: visibleInfo })"
      >
        <i class="el-icon-caret-right" />
      </button>
    </div>
    <div
      v-if="visibleInfo"
      :class="b('body')"
    >
      <h3>Детали заказа:</h3>
      <template v-if="basketItems.length">
        <OrderHistoryTable
          :class="b('product-table')"
          :products="basketItems"
          :search-query="searchQuery"
          :selected.sync="selectedBasketItems"
          :selected-hint="displaySelectedHint"
        />
      </template>
      <h3 v-if="order.comment">
        Комментарий к заказу:
      </h3>
      <p v-if="order.comment">
        {{ order.comment }}
      </p>
      <h3 v-if="order.accountName">
        Юр. лицо:
      </h3>
      <p v-if="order.accountName">
        {{ organization ? organization.name : '' }}
      </p>
      <h3 v-if="order.address">
        Адрес доставки:
      </h3>
      <p v-if="order.address">
        {{ order.address }}
      </p>
      <h3 v-if="order.paymentMethod">
        Тип оплаты:
      </h3>
      <p v-if="order.paymentMethod">
        {{ order.paymentMethod }}
      </p>
      <div :class="b('footer')">
        <div :class="b('footer-actions')">
          <el-button
            size="small"
            @click="repeatOrder"
          >
            Повторить заказ
          </el-button>
          <el-button
            size="small"
            icon="el-icon-download"
            :loading="exportLoading"
            @click="exportOrder"
          >
            Скачать в Excel
          </el-button>
          <el-popover
            :value="displaySelectedHint"
            placement="right"
            width="95px"
            trigger="manual"
            content="Выберите товар для возврата"
          >
            <template #reference>
              <el-button
                size="small"
                :type="selectedBasketItems.length > 0 ? 'primary' : 'info'"
                @click="returnOrder"
              >
                Оформить возврат
              </el-button>
            </template>
          </el-popover>
          <!--          <el-button-->
          <!--            size="small"-->
          <!--            :disabled="!selectedBasketItems.length"-->
          <!--            @click="shopFillClickHandler"-->
          <!--          >-->
          <!--            Оприходовать-->
          <!--          </el-button>-->
        </div>
        <price-output
          v-if="visibleInfo"
          :class="b('price', { footerPrice: true })"
          hide-currency
          :price="normalizePrice(order.sumPrice)"
        />
      </div>
    </div>
    <el-dialog
      title="Выберите склад"
      :visible.sync="shopFillDialogOpened"
      append-to-body
      width="400px"
    >
      <el-select
        v-model="shopToFill"
        style="width: 100%"
        placeholder="Выбрать склад"
        default-first-option
      >
        <el-option
          v-for="shop in shops"
          :key="shop.id"
          :label="shop.name"
          :value="shop.id"
        />
      </el-select>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="shopFillDialogOpened = false"
        >Отменить</el-button>
        <el-button
          type="primary"
          :disabled="!shopToFill"
          :loading="shopFillLoading"
          @click="shopFillHandler"
        >Перенести</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from 'api/axiosInstance';
import {
mapActions, mapGetters, mapMutations,
  mapState} from 'vuex';

import { getPrice,normalizePrice } from '@/utils/normalizePrice';
import OrderHistoryTable from '@/views/history/components/orderHistoryTable';
import OrderStatus from '@/views/history/components/orderStatus';

export default {
  name: 'OrderBlock',
  components: { OrderStatus, OrderHistoryTable },
  props: {
    /** @type Order|Object */
    order: {
      type: Object,
      required: true
    },
    searchQuery: {
      type: String,
      required: false,
      default: null
    },
    full: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      visibleInfo: this.full,
      basketItems: [],
      /** @type  {[number|object]} */
      selectedBasketItems: [],
      displaySelectedHint: false,
      loading: false,
      exportLoading: false,
      shopFillDialogOpened: false,
      shopToFill: null,
      shopFillLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['_roleAdmin']),
    ...mapState('ui', ['windowSize']),
    ...mapState('accounts', ['org']),
    ...mapState('personalCabinet', ['shops']),
    organization() {
      return this.org.find(organization => organization.id === this.order.orgId);
    }
  },
  watch: {
    selectedBasketItems() {
      this.displaySelectedHint = false;
    }
  },
  methods: {
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),
    ...mapActions('order', ['GetOrderHistory', 'PostRepeatOrder']),
    ...mapActions('returns', ['AddReturnProductsBasket', 'AddItemToReturnProductsBasket']),
    ...mapActions('personalCabinet', ['setStockBalanceFromOrders', 'getShops']),
    async openInfo() {
      if (!this.basketItems.length) await this.getBasketItems();
      this.visibleInfo = !this.visibleInfo;
    },
    async getBasketItems() {
      this.loading = true;
      this.basketItems = (await this.GetOrderHistory({
        orderId: this.order.id,
        userId: this._roleAdmin ? this.order.userId : undefined
      }));
      this.loading = false;
    },
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    }),
    async repeatOrder() {
      const selectedIds = this.selectedBasketItems.map(item => item.id);
      const excludedIds = selectedIds.length ? this.basketItems.filter(item => !selectedIds.includes(item.id)).map(item => item.id) : [];
      await this.PostRepeatOrder({orderId: this.order.id, exclude: excludedIds})
        .then((basketId) => {
          this.SET_ACTIVE_BASKET(basketId);
          this.$router.push({ name: 'view-order' });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    async exportOrder() {
      this.exportLoading = true;

      await axiosInstance({
        url: `/api/orders/orderToExcel/${this.order.id}`
      })
        .then((data) => {
          setTimeout(() => {
            window.open(data);
          }, 100);
        })
        .catch(() => this.errors.push('Ошибка формирования документа'))
        .finally(() => {
          this.exportLoading = false;
        });
    },
    normalizePrice,
    getPrice,
    async returnOrder() {
      if (!this.selectedBasketItems.length) {
        this.displaySelectedHint = true;
        return;
      }
      this.$prompt('Укажите причину возврата товара', 'Возврат', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Отмена',
        inputPlaceholder: 'Причина возврата',
        inputValidator: value => value.length >= 3 && value.length <= 30,
        inputErrorMessage: 'Комментарий должен содержать от 3 до 20 символов',
      })
        .then(async ({ value }) => {
          const createdReturnBasket = await this.AddReturnProductsBasket(`Возврат по заказу №${this.order.id}`);

          // eslint-disable-next-line
          for (const selectedItem of this.selectedBasketItems) {
            // eslint-disable-next-line no-await-in-loop
            await this.AddItemToReturnProductsBasket({
              productId: selectedItem.productId,
              supplier: selectedItem.supplier,
              quantity: 1,
              returnProductsBasketId: createdReturnBasket.id,
              reason: value
            });
          }
          await this.$router.push({ name: 'return-order' });
        });
    },
    async shopFillClickHandler() {
      this.shopFillDialogOpened = true;
      await this.getShops();
    },
    async shopFillHandler() {
      this.shopFillLoading = true;
      const orderProductIds = this.selectedBasketItems.map(
        product => product.id
      );
      await this.setStockBalanceFromOrders(
        {
          orderProductIds,
          shopId: this.shopToFill,
        }
      ).then(() => {
        this.$message.success('Товары оприходованы');
        this.shopFillDialogOpened = false;
        this.selectedBasketItems = [];
        this.shopToFill = null;
      })
        .catch((e) => {
          console.error(e);
          this.$message.error('Возникла ошибка при оприходовании товара');
        })
        .finally(() => {
          this.shopFillLoading = false;
        });
    }
  },
};
</script>

<style scoped lang="sass">
  h3
    font-size: 12px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 12px
    color: #282828
    margin: 2px 0 15px 0
  .order-block
    position: relative
    padding: 10px 20px
    background: white
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    &__header
      display: flex
      flex-flow: nowrap
      align-items: center
      padding-right: 30px
      cursor: pointer
      > *
        position: relative
        z-index: 2
      &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: radial-gradient(circle at top right, #ecf5ff, white 30%)
        opacity: 0
        z-index: 1
        border-radius: 8px
        transition: .2s
      &:hover::before
        opacity: 1
      @media (max-width: 700px)
        flex-flow: column
        align-items: flex-start
      .title-wrap
        display: flex
        flex-flow: nowrap
        align-items: center
        margin-right: 30px
        min-width: 200px
        @media (max-width: 700px)
          margin-right: 0
          margin-bottom: 5px
      h2
        margin-left: 10px
        font-size: 14px
        color: #636363
    &__view-more-button
      position: absolute
      right: 0
      height: 30px
      width: 50px
      padding: 0
      border: none
      background: none
      cursor: pointer
      border-radius: 0 8px 8px 0
      overflow: hidden
      i
        position: relative
        transform: rotate(0)
        transition: .2s
        z-index: 2
      &_active
        i
          transform: rotate(-90deg)
    &__data
      display: flex
      flex-flow: column
      font-size: 12px
      color: #282828
      margin-right: 15px
      opacity: .8
      @media (max-width: 700px)
        margin-right: 0
        margin-bottom: 5px
      span
        font-size: 10px
        color: #636363
    &__name
      @media (min-width: 700px)
        width: 130px
        overflow: hidden
        text-overflow: ellipsis
    &__date
      min-width: 110px
    &__price
      font-size: 14px
      margin-left: auto
      color: #636363
      @media (max-width: 700px)
        margin-right: -30px
        &_footer-price
          margin-right: 0
    &__buttons
      display: flex
      flex-flow: nowrap
      gap: 10px
    &__body
      position: relative
      padding-top: 10px
      padding-bottom: 10px
      z-index: 2
    &__footer
      display: flex
      flex-flow: nowrap
      align-items: center
      @media (max-width: 700px)
        align-items: start
        flex-direction: column
    &__footer-actions
      display: flex
      gap: 10px
      flex-wrap: wrap
      @media (max-width: 700px)
        margin-bottom: 15px
</style>
