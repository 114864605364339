<template>
  <div :class="b()">
    <n-table
      v-if="windowSize.width > 900"
      :data="sortedProducts"
      :default-sort="sort"
      :row-class="rowClassName"
      :columns="[
        { name: '', prop: 'index', align: 'center', width: '30px' },
        { name: '', prop: 'checkbox', align: 'center', width: '30px' },
        { name: 'Артикул', prop: 'article', width: '160px', sortable: true },
        { name: 'Название', prop: 'productName', width: 'minmax(200px, 1fr)', sortable: true },
        { name: 'Доставка', prop: 'deliveryDate', width: '100px', sortable: true, align: 'center' },
        { name: 'Цена', prop: 'productPrice', width: '130px', sortable: true },
        { name: 'Кол-во', prop: 'quantity', width: '80px', align: 'center' },
        { name: 'Сумма', prop: 'sum', width: '80px', align: 'center' },
      ]"
      :class="b('table')"
      empty-text="Нет данных"
      @sort-change="sort = $event"
    >
      <template #checkboxHead>
        <el-checkbox
          :indeterminate="selected.length !== products.length && selected.length > 0"
          :value="selected.length === products.length"
          @change="bulkSelection"
        />
      </template>
      <template #checkbox="{ row }">
        <el-checkbox
          :value="selected.includes(row)"
          :class="{'highlighted-checkbox': selectedHint}"
          @change="selectionHandler($event, row)"
        />
      </template>
      <template #index="{ index }">
        {{ index + 1 }}
      </template>
      <template #article="{ row }">
        <div v-if="row.supplier === null">
          {{ row.article }}
        </div>
        <div v-else>
          {{ JSON.parse(row.supplier).article }}
        </div>
      </template>
      <template #productName="{ row }">
        <order-status
          v-if="row.name"
          :status="row.name"
          small
          style="margin-right: 5px"
        />
        <div v-if="row.supplier === null">
          {{ row.productName }}
        </div>
        <div v-else>
          {{ JSON.parse(row.supplier).name }}
        </div>
      </template>
      <template #productPrice="{ row }">
        <div style="display: flex; align-items: center">
          <priceOutput
            :price="normalizePrice(row.productPrice)"
            hide-currency
          />
          <el-tooltip
            v-if="
              userCurrency === 'RU' &&
                row.oldProductPrice > 0 &&
                normalizePrice(row.oldProductPrice) !== normalizePrice(row.productPrice)
            "
            effect="dark"
            content="цена на товар изменилась, обратитесь к менеджеру, если у Вас есть вопросы"
          >
            <div
              class="old-product-price"
            >
              <i class="el-icon-info" />
              <priceOutput
                :price="normalizePrice(row.oldProductPrice)"
                hide-currency
              />
            </div>
          </el-tooltip>
        </div>
      </template>
      <template #sum="{ row }">
        <priceOutput
          :price="normalizePrice(row.quantity * row.productPrice)"
          hide-currency
        />
      </template>
      <template #deliveryDate="{ row }">
        <div
          v-if="row.deliveryType === deliveryTypes.delivery"
          style="display: flex;flex-direction: column;gap:3px"
        >
          <template v-if="row.oldProductDeliveryDate && row.oldProductDeliveryDate !== row.deliveryDate">
            <div style="text-decoration: line-through;font-size: 10px;text-decoration-color: red">
              <el-tooltip
                effect="dark"
                content="Сроки доставки изменились"
              >
                <i
                  class="el-icon-info"
                  style="font-size: 10px;color: #e6a23b;margin-right: 3px"
                />
              </el-tooltip>
              {{ formatDate(row.oldProductDeliveryDate) }}
            </div>
          </template>
          <div v-if="city && city.cityId && row.deliveryDate">
            {{ formatDate(row.deliveryDate) }}
          </div>
          <el-tooltip
            v-if="city && city.cityId && !row.deliveryDate"
            effect="dark"
            content="Сроки доставки уточняйте у менеджера"
          >
            <i class="el-icon-info delivery-question-icon" />
          </el-tooltip>
          <el-tooltip
            v-if="!city || !city.cityId"
            effect="dark"
            content="не указан регион доставки, свяжитесь с менеджером"
          >
            <i class="el-icon-question delivery-question-icon" />
          </el-tooltip>
        </div>
        <div v-else>
          самовывоз
        </div>
      </template>
    </n-table>
    <div v-else>
      <div
        v-for="(item, i) in products"
        :key="`product---${i}`"
        class="small-product"
      >
        <div style="display: flex; gap: 10px; align-items: center">
          <el-checkbox
            :value="selected.includes(item)"
            :class="{'highlighted-checkbox': selectedHint}"
            @change="selectionHandler($event, item)"
          />
          <order-status
            :status="item.name"
            small
            style="margin-right: 5px"
          />
        </div>
        <h2>{{ item.productName }}</h2>
        <div class="small-product__info">
          <div>
            <h3>Артикул</h3>
            <p>{{ item.article }}</p>
          </div>
          <div>
            <h3>Цена</h3>
            <p>
              <priceOutput
                :price="normalizePrice(item.productPrice)"
                hide-currency
              />
            </p>
          </div>
          <div>
            <h3>Кол-во</h3>
            <p>{{ item.quantity }}</p>
          </div>
          <div>
            <h3>Сумма</h3>
            <p>
              <priceOutput
                :price="normalizePrice(item.quantity * item.productPrice)"
                hide-currency
              />
            </p>
          </div>
          <div v-if="item.deliveryDate">
            <h3>
              Доставка <el-tooltip
                v-if="shipmentDescription"
                effect="dark"
                :content="shipmentDescription"
              >
                <i class="el-icon el-icon-info" />
              </el-tooltip>
            </h3>
            <p v-if="city && city.cityId">
              {{ item.deliveryDate }}
            </p>
            <el-tooltip
              v-else
              effect="dark"
              content="не указан регион доставки, свяжитесь с менеджером"
            >
              <i class="el-icon-question delivery-question-icon" />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeliveryHint } from 'utils/deliveryDays';
import productsSort from 'utils/productsSort';
import { mapGetters, mapState } from 'vuex';

import deliveryTypes from '@/enums/deliveryTypes';
import { getPrice,normalizePrice } from '@/utils/normalizePrice';

import OrderStatus from './orderStatus';

const clearString = str => str.replace(/[^\\da-zA-Zа-яёА-ЯЁ0-9]/g, '').toLowerCase();

export default {
  name: 'OrderHistoryTable',
  components: {
    OrderStatus
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    searchQuery: {
      type: String,
      required: false,
      default: ''
    },
    /** @type {[number|object]|object} */
    selected: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedHint: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    sort: { prop: 'index', order: 'ASC' },
  }),
  computed: {
    deliveryTypes() {
      return deliveryTypes;
    },
    ...mapState('ui', ['userCurrency', 'windowSize']),
    ...mapState('accounts', ['city']),
    ...mapGetters('auth', ['_role']),
    sortedProducts() {
      return productsSort(this.products, this.sort, 'history');
    },
    shipmentDescription() {
      return getDeliveryHint();
    }
  },
  methods: {
    normalizePrice,
    getPrice,
    rowClassName(row) {
      return {
        searchResult:
          this.searchQuery.length
          && (
            clearString(row.productName || '').indexOf(clearString(this.searchQuery)) !== -1
            || clearString(row.article || '').indexOf(clearString(this.searchQuery)) !== -1
          ),
      };
    },
    formatDate: strDate => new Date(strDate).toLocaleString('ru', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
    bulkSelection() {
      this.$emit('update:selected', this.selected.length !== this.products.length
        ? this.products
        : []);
    },
    selectionHandler(isChecked, product) {
      if (isChecked) {
        this.$emit('update:selected', [...this.selected, product]);
      } else {
        this.$emit(
          'update:selected',
          [...this.selected].filter(item => item !== product).filter(item => JSON.stringify(item) !== JSON.stringify(product))
        );
      }
    }
  }
};
</script>

<style lang="sass">
.searchResult .n-table-row__col
  background-color: rgba(255, 253, 1, 0.16)
</style>
<style scoped lang="sass">
.old-product-price
  font-size: 10px
  color: #e6a23b
  display: flex
  align-items: center
  gap: 3px
  margin: -15px 5px 0
  span
    text-decoration: line-through
.order-history-table
  overflow: auto
  display: flex
  flex-flow: column
  justify-content: flex-end
  margin: 5px 0 10px 0

.small-product
  h2
    font-size: 12px
    margin: 5px 0
  h3
    font-size: 11px
    color: #636363
    font-weight: 400
    margin: 0
  p
    font-size: 11px
    color: #282828
    margin: 2px 0 0
  &:not(:last-child)
    margin-bottom: 10px
  &__info
    display: flex
    flex-flow: nowrap
    justify-content: space-between
.delivery-question-icon
  color: #1C6A9E
  font-size: 14px
.highlighted-checkbox
  background: #ff1c0a
  padding: 0 3px
  border-radius: 4px
</style>
