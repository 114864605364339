import { Message } from 'element-ui';
import declOfNum from 'utils/declOfNum';
import productsSort from 'utils/productsSort';
import Vue from 'vue';

import axiosInstance from '../../api/axiosInstance';

/**
 * @typedef {Object} Product
 * @property {integer} id
 * @property {null | object} isProductGroup
 * @property {string} OEM
 * @property {string} name
 * @property {integer} stockbalance
 * @property {integer} regionalstockbalance
 * @property {string} minShippingRate
 * @property {string} externalId
 * @property {string} article
 * @property {integer} categoryId
 * @property {string} brand
 * @property {string} price
 * @property {object | null} baskets
 * @property {object | null} productsinbaskets
 * @property {string} tengePrice
 */

/**
 * @typedef {Object} SupplierProductStock
 * @property {integer} id
 * @property {string} price
 * @property {integer} count
 * @property {integer} multiplicity
 * @property {integer} delivery
 * @property {number} type
 * @property {string} oldPrice
 * @property {number} tengePrice
 * @property {integer} discount
 * @property {integer} discountType
 */

/**
 * @typedef {Object} SupplierProduct
 * @property {string} guid
 * @property {string} brand
 * @property {string} article
 * @property {string} name
 * @property {string} supplier
 * @property {SupplierProductStock[]} stocks
 */

const state = () => ({
  /** @type {Product[]} */
  products: [],
  /** @type {number} */
  max: 50,
  /** @type {number} */
  offset: 0,
  categoryId: null,
  /** @type { 'nikolProducts' | 'tecdoc' | 'part' } */
  tableType: 'nikolProducts',
  /** @type {string} */
  sortField: localStorage.getItem('defaultSortName') || 'name',
  /** @type { 'ASC' | 'DESC' } */
  sortOrder: localStorage.getItem('defaultSortOrder') || 'ASC',
  displayTo: 0,
  productsCount: 0,
  search: '',
  columnSelector: false,
  emptyText: 'Загрузка',
  /** @type {{products: SupplierProduct[], analog: SupplierProduct[]}} */
  supplierProducts: {
    products: [],
    analog: []
  },
  supplierProductsLoading: false,
  /** @type {{brand: string, article: string}} */
  supplierProductsInfo: { brand: '', article: '' },
  /** @type {string[]} */
  suppliersNames: [],
  /** @type {string|boolean} */
  supplierLoading: false,
  /** @type {boolean} */
  suppliersLoading: false,
  tourStatus: false,
  /** @type {IExcelResultProduct[]} */
  xlsOrder: [],
  xlsOrderLength: 0,
  loading: false,
  /** @type {string} */
  percentMarkup: localStorage.getItem('percentMarkup') || '0',
  filters: [],
  changedFilters: [],
  filterChange: false,
  outOfStock: localStorage.getItem('outOfStock') === null ? true : !!localStorage.getItem('outOfStock'),
  noFilters: true,
  /** @type {number | null} */
  analogsFor: null,
  productInfoModalData: null,
  productInfoModalLoadingIndex: null,
  productCartLoadingIndex: null,
  deliveryDays: 0,
  /** @type {string | null} */
  regionalDeliveryDays: null,
  onlyPromoActions: false,
  onlyGroups: false,
  /** @type {IPromoAction[]} */
  promoActions: [],
  minimalOrderSum: 0,
  minimalOrderSumInTenge: 0,
  xlsErrors: [],
  xlsErrorsLoading: false,
  xlsSupplierModalProduct: null,
});

const mutations = {
  SET_TOUR_STATUS(state, tourStatus) {
    state.tourStatus = tourStatus;
  },
  SET_PRODUCT_TABLE_TYPE(state, tableType) {
    state.tableType = tableType;
  },
  SET_EMPTY_TEXT(state, text) {
    state.emptyText = text;
  },
  SET_SUPPLIER_PRODUCTS_LOADING(state, supplierProductsLoading) {
    state.supplierProductsLoading = supplierProductsLoading;
  },
  CLEAR_SUPPLIER_PRODUCTS(state) {
    state.supplierProducts = {
      products: [],
      analog: []
    };
  },
  ADD_SUPPLIER_PRODUCTS(state, supplierProducts) {
    state.supplierProducts.products.push(...supplierProducts.products);
    state.supplierProducts.analog.push(...supplierProducts.analog);
  },
  SET_SUPPLIER_PRODUCTS_INFO(state, /** { brand: String, article: String } */ payload) {
    state.supplierProductsInfo = payload;
  },
  RESET_PRODUCTS(state) {
    state.offset = 0;
    state.productsCount = 0;
    state.products = [];
  },
  DEFAULT_OFFSET(state) {
    state.offset = 0;
    state.productsCount = 0;
  },
  /**
   * @param state {state}
   * @param data
   * @param data.products {Product[]}
   * @param data.displayTo {number}
   * @param data.productsCount {string}
   */
  SET_PRODUCTS(state, {
    products,
    displayTo,
    productsCount,
    deliveryDays,
    regionalDeliveryDays,
  }) {
    products.forEach((item) => {
      item.quantity = +item.minShippingRate || 1;
    });
    if (!state.products.length) {
      state.products = products;
      state.offset = 50;
    } else {
      /* Следующий код убирает дублирование результатов при слишком быстром нажатии на кнопку поиска */
      if (state.products[0].id === products[0].id) {
        if (state.products.find(({ id }, i) => id !== products[i].id) === undefined && state.products.length === products.length) {
          state.products = products;
          return;
        }
      }
      state.products = state.products.concat(products);
      state.offset += 50;
    }

    state.displayTo = displayTo;
    state.productsCount = Number(productsCount);
    state.deliveryDays = deliveryDays;
    state.regionalDeliveryDays = regionalDeliveryDays;
  },
  SET_PRODUCTS_COUNT(state, count) {
    state.productsCount = count;
  },
  SET_PRODUCT_BASKET_QUANTITY(state, { id, basketId, quantity }) {
    const findedProductIndex = state.products.findIndex(item => item.id === id);
    if (findedProductIndex === -1) return;

    Vue.set(state.products[findedProductIndex], 'baskets', {
      ...state.products[findedProductIndex].baskets,
      [basketId]: quantity
    });
  },
  DELETE_BASKET(state, basketId) {
    state.products.forEach((item, index) => {
      if (!item.baskets || !item.baskets[basketId]) return;
      Vue.set(state.products[index], 'basket', {
        ...item.baskets,
        [basketId]: 0
      });
    });
  },
  SET_CATEGORY_ID(state, categoryId) {
    state.categoryId = categoryId;
    state.products = [];
    state.offset = 0;
  },
  SET_PRODUCT_SORT(state, { sortField, sortOrder }) {
    state.sortField = sortField;
    state.sortOrder = sortOrder;
    state.products = [];
    state.offset = 0;
    localStorage.setItem('defaultSortName', sortField);
    localStorage.setItem('defaultSortOrder', sortOrder);
  },
  SET_SEARCH_QUERY(state, { search }) {
    state.search = search;
    state.products = [];
  },
  SET_SEARCH(state, search) {
    state.search = search;
    state.categoryId = null;

    state.products = [];
    state.tableType = 'nikolProducts';
    state.supplierProducts = { products: [], analog: [] };
  },
  SET_SEARCH_TEXT(state, search) {
    state.search = search;
  },
  SET_COLUMN_SELECTOR(state, show) {
    state.columnSelector = show;
  },
  SET_XLS_ORDER(state, order) {
    state.xlsOrder = order.map(item => ({
      ...item,
      quantity: item.quantity || 1,
    }));
    state.xlsOrderLength = order.length;
  },
  REMOVE_ITEM_FROM_XLS_ORDER(state, id) {
    state.xlsOrder = state.xlsOrder.filter(item => item.code !== id);
  },
  REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID(state, id) {
    state.xlsOrder = state.xlsOrder.filter(item => item.productId !== id);
  },
  UPDATE_XLS_ITEM_BY_PRODUCT_ID(state, { productId, item }) {
    const index = state.xlsOrder.findIndex(item => item.productId === productId);
    Vue.set(state.xlsOrder, index, item);
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_PERCENT_MARKUP(state, percentMarkup) {
    localStorage.setItem('percentMarkup', percentMarkup);
    state.percentMarkup = percentMarkup;
  },
  REMOVE_ERROR_ITEM_FROM_XLS_ORDER(state) {
    state.xlsOrder = state.xlsOrder.filter(item => item.state === 'ready' || item.state === 'severalSuppliers');
  },
  SET_FILTERS(state, filters) {
    state.changedFilters = [];
    state.filters = filters;
  },
  SET_FILTER_CHANGE(state, payload) {
    state.filterChange = payload;
  },
  REMOVE_FILTERS(state) {
    state.changedFilters = [];
    state.filters = [];
  },
  SET_NO_FILTERS(state, payload) {
    state.noFilters = payload;
  },
  REMOVE_CHANGED_FILTERS(state) {
    state.changedFilters = [];
  },
  SET_CHANGED_FILTERS(state, {
    filter,
    remove = false,
  }) {
    if (remove) {
      state.changedFilters = state.changedFilters.filter(item => item.id !== filter.id);
      if (!state.changedFilters.length && state.noFilters) state.filterChange = false;
      return;
    }
    state.changedFilters = [
      ...state.changedFilters.filter(item => item.id !== filter.id),
      filter
    ];
  },
  SET_OUT_OF_STOCK(state, payload) {
    state.outOfStock = payload;
    localStorage.setItem('outOfStock', payload ? '1' : '');
  },
  SET_ANALOGS_FOR_ID(state, id) {
    state.analogsFor = id;
  },
  SET_PRODUCT_INFO_MODAL_DATA(state, productInfoModalData) {
    state.productInfoModalData = productInfoModalData;
  },
  SET_PRODUCT_INFO_MODAL_LOADING_INDEX(state, productInfoModalLoadingIndex) {
    state.productInfoModalLoadingIndex = productInfoModalLoadingIndex;
  },
  SET_PRODUCT_CART_LOADING_INDEX(state, productCartLoadingIndex) {
    state.productCartLoadingIndex = productCartLoadingIndex;
  },
  SET_SUPPLIERS_NAMES(state, suppliersNames) {
    state.suppliersNames = suppliersNames;
  },
  SET_SUPPLIER_LOADING(state, supplierLoading) {
    state.supplierLoading = supplierLoading;
  },
  SET_SUPPLIERS_LOADING(state, suppliersLoadingState) {
    state.suppliersLoading = suppliersLoadingState;
  },
  SET_ONLY_PROMO_ACTIONS(state, onlyPromoActions) {
    state.onlyPromoActions = onlyPromoActions;
  },
  SET_ONLY_GROUPS(state, onlyGroups) {
    state.onlyGroups = onlyGroups;
  },
  SET_PROMO_ACTIONS(state, promoActions) {
    state.promoActions = promoActions;
  },
  SET_MINIMAL_ORDER_SUM(state, minimalOrderSum) {
    state.minimalOrderSum = minimalOrderSum;
  },
  SET_MINIMAL_ORDER_SUM_IN_TENGE(state, minimalOrderSumInTenge) {
    state.minimalOrderSumInTenge = minimalOrderSumInTenge;
  },
  SET_XLS_ERRORS(state, xlsErrors) {
    state.xlsErrors = xlsErrors;
  },
  SET_XLS_ERRORS_LOADING(state, xlsErrorsLoading) {
    state.xlsErrorsLoading = xlsErrorsLoading;
  },
  REMOVE_ITEM_FROM_XLS_ERRORS(state, id) {
    state.xlsErrors = state.xlsErrors.filter(item => item.id !== id);
  },
  REMOVE_ALL_ITEMS_FROM_XLS_ERRORS(state) {
    state.xlsErrors = [];
  },
  SET_XLS_SUPPLIER_MODAL_PRODUCT(state, product) {
    state.xlsSupplierModalProduct = product;
  },
};

const actions = {
  /**
   * Получает список деталей от сторонних производителей
   *
   * @param _ {object}
   * @param brand {string}
   * @param name {string}
   * @param article {string}
   * @param tableType {'tecdoc' || 'part'}
   * @param supplierName {string}
   * @return {Promise<void>}
   */
  async GetSupplierProducts({ commit }, {
    brand, name, article, tableType = 'tecdoc',
    supplierName
  }) {
    commit('SET_SUPPLIER_PRODUCTS_LOADING', true);
    commit('SET_SUPPLIER_LOADING', supplierName);
    if (!supplierName) {
      commit('CLEAR_SUPPLIER_PRODUCTS');
    }

    await axiosInstance({
      url: '/api/suppliers',
      params: {
        brand,
        name,
        article,
        supplier: supplierName
      }
    })
      .then((/** { products: SupplierProduct[], analog: SupplierProduct[] } */ data) => {
        if (data.products.length === 0 && data.analog.length === 0) {
          throw new Error('Подходящих предложений не найдено');
        }

        const addQuantity = (item) => {
          item.stocks.forEach((i) => {
            if (Number(i.multiplicity) === 0) {
              i.multiplicity = 1;
            }
            i.quantity = Number(i.multiplicity);
          });
        };
        const filterStocksNotEnoughForMultiplicity = (item) => {
          item.stocks = item.stocks.filter(stock => stock.count >= stock.multiplicity);
        };
        const filterNoStocksAvailable = item => item.stocks.length;

        data.products.forEach(filterStocksNotEnoughForMultiplicity);
        data.products = data.products.filter(filterNoStocksAvailable);
        data.products.forEach(addQuantity);

        data.analog.forEach(filterStocksNotEnoughForMultiplicity);
        data.analog = data.analog.filter(filterNoStocksAvailable);
        data.analog.forEach(addQuantity);

        commit('SET_PRODUCT_TABLE_TYPE', tableType);
        commit('SET_SUPPLIER_PRODUCTS_INFO', { brand, article });
        commit('ADD_SUPPLIER_PRODUCTS', data);
      }).finally(() => {
        commit('SET_SUPPLIER_PRODUCTS_LOADING', false);
        commit('SET_SUPPLIER_LOADING', false);
      });
  },
  async GetSuppliersNames({ commit }) {
    await axiosInstance('/api/suppliers/names')
      .then((data) => {
        commit('SET_SUPPLIERS_NAMES', data);
      });
  },
  /**
   * Получает список товаров
   *
   * @param _ {Object<context>}
   * @param payload {{
   * categoryId: number | undefined,
   * sortField: string | null | undefined,
   * sortOrder: string | null | undefined,
   * search: string | undefined,
   * getFilters: boolean
   * }}
   * @return {Promise<{ products: Product[], productsCount: String, deliveryDays: Number, displayTo: Number } | undefined>}
   */
  async GetProductsList(
    { state, commit, dispatch },
    {
      categoryId = undefined,
      sortField = undefined,
      sortOrder = undefined,
      search = undefined,
      getFilters = false,
    }
  ) {
    if (categoryId !== undefined) commit('SET_CATEGORY_ID', categoryId);

    if (search || search === '') commit('SET_SEARCH_QUERY', { search });

    if (sortField && sortOrder) commit('SET_PRODUCT_SORT', { sortField, sortOrder });
    if (!['ASC', 'DESC'].includes(state.sortOrder)) commit('SET_PRODUCT_SORT', { sortField: 'name', sortOrder: 'ASC' });

    if (getFilters) dispatch('GetFilters');
    const filters = state.changedFilters.reduce((previousValue, item) => {
      if (item.value && !Array.isArray(item.value)) item.value = [item.value];
      return previousValue + JSON.stringify(item);
    }, '');
    if (filters) commit('SET_NO_FILTERS', false);

    return axiosInstance({
      url: '/api/productList',
      params: {
        max: state.max,
        categoryId: state.categoryId || undefined,
        offset: state.offset,
        sortField: state.sortField ?? undefined,
        sortOrder: state.sortOrder ?? undefined,
        q: state.search ? state.search : undefined,
        filters: filters || undefined,
        outOfStock: state.outOfStock ? true : undefined,
        onlyPromoactions: state.onlyPromoActions,
        onlyGroups: state.onlyGroups,
      }
    })
      .then((/** { products: Product[], productsCount: String, deliveryDays: Number, displayTo: Number } */ data) => {
        commit('SET_PRODUCTS', data);
        dispatch('getProductsListCount', {
          categoryId: state.categoryId || undefined,
          max: state.max,
          q: state.search ? state.search : undefined,
          filters: filters || undefined,
          outOfStock: state.outOfStock ? true : undefined,
          onlyPromoactions: state.onlyPromoActions,
          onlyGroups: state.onlyGroups,
        });
        if (!state.products.length) {
          commit('DEFAULT_OFFSET');
          commit('SET_EMPTY_TEXT', 'Товары не найдены, попробуйте изменить настройки фильтров или запрос');
        }
        dispatch('basket/renderBasketIndicators', null, { root: true });
        return Promise.resolve(data);
      })
      .catch(({ message }) => Promise.reject(message || 'Произошла неизвестная ошибка, пожалуйста, обновите страницу'));
  },
  getProductsListCount({ commit }, params) {
    return axiosInstance({
      url: '/api/productList/count',
      params
    })
      .then((data) => {
        commit('SET_PRODUCTS_COUNT', data.count);
        return Promise.resolve(data);
      })
      .catch(({ message }) => Promise.reject(message));
  },
  /**
   * Получает фильтры для текущей категории
   *
   * @returns {Promise<{
   * id: number | null,
   * name: string,
   * categoryId: number,
   * values: string[],
   * filterTypeId: number | null,
   * createdAt: number | null,
   * updatedAt: number | null,
   * type: string | null}[]>}
   */
  GetFilters({ commit, state }) {
    commit('REMOVE_FILTERS');
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: 'api/filters',
        params: { categoryId: state.categoryId }
      })
        .then((data) => {
          commit('SET_FILTERS', data);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * Получает информацию о конкретном товаре по Id
   *
   * @param _ {Object<context>}
   * @param productId
   * @return {Promise<{}>}
   */
  async GetProductInfo(_, productId) {
    return axiosInstance(`/api/productList/${productId}`);
  },

  /**
   * Отправляет xls файл на сервер и получает массив продуктов
   *
   * @param commit {function(mutation: string, payload: any)}
   * @param file {File}
   * @returns {Promise<any[] | Error>}
   * @constructor
   */
  GetXlsProducts({ commit }, file) {
    commit('SET_LOADING', true);
    return new Promise((resolve, reject) => {
      axiosInstance({
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: '/api/orders/upload',
        method: 'POST',
        data: file
      })
        .then((/** [] */ resp) => {
          let order = resp.map((item, index) => {
            // If multiple suppliers, then return first one with the lowest price
            if (Array.isArray(item)) {
              const changedItem = item[0];
              changedItem.state = 'severalSuppliers';
              changedItem.suppliers = item;
              item = changedItem;
            }

            const { minShippingRate, stockBalance, stockBalanceRegionalWarehouse } = item;

            let defPrice = 0;
            if (item.price || (item.stocks && item.stocks.length)) {
              if (item.price) defPrice = +item.price.price;
              if (item.stocks) defPrice = +item.stocks[0].price;
            }

            return {
              index,
              ...item,
              defPrice,
              minShippingRate: minShippingRate || (item.stocks && item.stocks[0].multiplicity) || 0,
              maxRate: stockBalance + stockBalanceRegionalWarehouse || (item.stocks && item.stocks[0].multiplicity) || 0,
            };
          });

          // Sort list by state
          order = productsSort(order, { prop: 'state', order: 'ASC' });

          commit('SET_XLS_ORDER', order);
          resolve(order);
        })
        .catch((err) => {
          reject(new Error(err.message || err));
          console.error(err);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  async getXlsErrors(
    { commit },
    {
      max = undefined, offset = undefined,
      from = undefined, to = undefined
    } = {}
  ) {
    commit('SET_XLS_ERRORS_LOADING', true);
    await axiosInstance('/api/orderUploadErrors', {
      params: {
        from,
        to,
        max,
        offset
      }
    })
      .then((resp) => {
        commit('SET_XLS_ERRORS', resp);
      }).finally(() => {
        commit('SET_XLS_ERRORS_LOADING', false);
      });
  },
  GetAnalogs({ commit }, id) {
    return axiosInstance(`/api/productList/${id}/analogs`)
      .then((data) => {
        commit('SET_ANALOGS_FOR_ID', id);
        return data;
      });
  },
  getPromoActions({ commit }) {
    const mockData = [
      {
        name: 'Акция 1',
        sum: 12000,
        productGroupId: 1,
        value: 3000
      },
      {
        name: 'Акция 2',
        sum: 3000,
        value: 3000,
        productGroupId: 3,
      }
    ];
    commit('SET_PROMO_ACTIONS', []);
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('SET_PROMO_ACTIONS', mockData);
        resolve(mockData);
      }, 1000);
    });
  },
  getMinimalOrderSum({ commit }, params) {
    return axiosInstance({
      url: '/api/minimalSum',
      params
    })
      .then((data) => {
        commit('SET_MINIMAL_ORDER_SUM', data.minimalSum);
        commit('SET_MINIMAL_ORDER_SUM_IN_TENGE', data.minimalSumInTenge);
        return Promise.resolve(data);
      })
      .catch(({ message }) => Promise.reject(message));
  },
  async removeProductFromXlsErrors({ commit }, orderUploadId) {
    await axiosInstance({
      url: `/api/orderUploadErrors/${orderUploadId}`,
      method: 'delete',
    })
      .then(() => {
        commit('REMOVE_ITEM_FROM_XLS_ERRORS', orderUploadId);
      })
      .catch(err => console.log(err));
  },
  async removeAllProductsFromXlsErrors({ commit }) {
    await axiosInstance({
      url: '/api/orderUploadErrors/deleteAll',
      method: 'post',
    })
      .then(() => {
        commit('REMOVE_ALL_ITEMS_FROM_XLS_ERRORS');
      })
      .catch(err => console.log(err));
  },
  async addProductToXlsErrors(_, productData) {
    await axiosInstance({
      url: '/api/orderUploadErrors',
      method: 'post',
      data: productData
    });
  },
  async addProductsArrayToXlsErrors(_, productsData) {
    await axiosInstance({
      url: '/api/orderUploadErrors/array',
      method: 'post',
      data: {
        orderUploadErrors: productsData
      }
    });
  },
  fixXlsError({ state, dispatch, commit }, item) {
    const productIndex = state.xlsOrder.findIndex(product => product.productId === item.productId);
    if (
      item.stockBalance < Number(item.minShippingRate)
    ) {
      dispatch('addProductToXlsErrors', item);
      commit('REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID', item.productId);
      Message.success('Товара недостаточно, он был добавлен в черновик');
      return;
    }
    // 1. нет кол-ва -> в лист ожидания (с оповещением)
    if (item.state === 'notEnoughQuantity' || item.status === 'notEnoughQuantity') {
      if (item.stockBalance > 0) {
        if (item.quantity > item.stockBalance) {
          dispatch('wishlist/addToWishList', {
            productId: item.productId,
            quantity: item.quantity - Number(item.stockBalance),
            basketName: this.activeBasketData?.name || '',
          }, {
            root: true
          });
        }

        item.quantity = Number(item.stockBalance);
        item.state = 'ready';
        item.status = 'ready';
        Vue.set(state.xlsOrder, productIndex, item);

        Message.success('У товара изменено количество');
      } else {
        dispatch('wishlist/addToWishList', {
          productId: item.productId,
          quantity: item.quantity,
          basketName: this.activeBasketData?.name || '',
        }, {
          root: true
        });
        commit('REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID', item.productId);
        Message.success('Товар добавлен в лист ожидания');
      }
    }
    // 2. ошибка кратности -> исправлена кратность в большую сторону (с оповещением)
    if (item.state === 'incorrectMultiplicity' || item.status === 'incorrectMultiplicity') {
      item.quantity = Number(item.minShippingRate);
      item.state = 'ready';
      item.status = 'ready';
      Vue.set(state.xlsOrder, productIndex, item);
      Message.success('У товара исправлена кратность');
    }
    // 3. несколько вариантов поставщиков -> выбран с меньшей ценой (с оповещением)
    if (item.state === 'severalSuppliers' || item.status === 'severalSuppliers') {
      const suppliers = item.suppliers;
      delete item.suppliers;
      suppliers.push(item);
      item = suppliers.reduce((prev, curr) => (prev.stocks[0].price < curr.stocks[0].price ? prev : curr));
      item.suppliers = suppliers.filter(supplier => supplier !== item);
      Vue.set(state.xlsOrder, productIndex, item);
      Message.success('Выбран поставщик с наименьшей ценой');
    }
    // 4. неверное наименование -> перенесено в Черновик (с оповещением)
    if (
      item.state !== 'ready'
      && item.state !== 'notEnoughQuantity'
      && item.state !== 'severalSuppliers'
    ) {
      dispatch('addProductToXlsErrors', item);
      commit('REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID', item.productId);
      Message.success('Товар добавлен в черновик');
    }
  },
  /**
   * Apply actions to all products with fixable errors in Excel result
   */
  fixXlsErrors({ state, dispatch, commit }) {
    let wishListed = 0;
    let multiplicityChanged = 0;
    const moveToDrafts = [];

    state.xlsOrder.forEach((item) => {
      if (!item.status) {
        item.status = item.state;
      }

      // 1. нет кол-ва -> в лист ожидания (с оповещением)
      if (
        item.status === 'notEnoughQuantity'
      ) {
        dispatch('wishlist/addToWishList', {
          productId: item.productId,
          quantity: item.quantity,
          basketName: this.activeBasketData?.name || '',
        }, {
          root: true
        });
        commit('REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID', item.productId);
        wishListed++;
      }
      // 2. ошибка кратности -> исправлена кратность в большую сторону (с оповещением)
      if (item.status === 'incorrectMultiplicity') {
        const productIndex = state.xlsOrder.findIndex(product => product.productId === item.productId);
        item.quantity = Number(item.minShippingRate);
        item.status = 'ready';
        Vue.set(state.xlsOrder, productIndex, item);
        multiplicityChanged++;
      }

      // 4. неверное наименование -> перенесено в Черновик (с оповещением)
      if (
        (
          (item.status || item.state) !== 'ready'
          && item.status !== 'notEnoughQuantity'
          && item.status !== 'severalSuppliers'
        )
        || (
          item.stockBalance < Number(item.minShippingRate)
        )
      ) {
        delete item.state;
        moveToDrafts.push(item);
      }
    });

    if (wishListed) {
      Message.success(
        `${wishListed} ${declOfNum(
          wishListed,
          ['товар добавлен', 'товара добавлено', 'товаров добавлено']
        )} в лист ожидания`
      );
    }
    if (multiplicityChanged) {
      Message.success(
        `У ${multiplicityChanged} ${declOfNum(
          wishListed,
          ['товара исправлена', 'товаров исправлена', 'товаров исправлена']
        )} кратность`
      );
    }
    if (moveToDrafts.length) {
      dispatch('addProductsArrayToXlsErrors', moveToDrafts).then(() => {
        moveToDrafts.forEach(itemToDelete => commit('REMOVE_ITEM_FROM_XLS_ORDER_BY_PRODUCT_ID', itemToDelete.productId));

        Message.success(
          `${moveToDrafts.length} ${declOfNum(
            moveToDrafts.length,
            ['товар отправлен', 'товара отправлено', 'товаров отправлено']
          )} в черновики`
        );
      }).catch(() => Message.error('Произошла ошибка при попытке добавить товары в черновик'));
    }
  }
};

const getters = {
  _percentMarkup: state => +state.percentMarkup || 0,
  minimumOrderSum(state, getters, rootState, rootGetters) {
    if (rootGetters['ui/_userCurrency'] === 'KZ') {
      return state.minimalOrderSumInTenge;
    }
    return state.minimalOrderSum;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
