import Shepherd from 'shepherd.js';
import store from 'store';

const tour = new Shepherd.Tour({
  defaultStepOptions: {
    classes: 'shadow-md bg-purple-dark',
    cancelIcon: {
      enabled: true,
    },
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
    }
  },
  useModalOverlay: true,
  confirmCancel: false
});

tour.addStep({
  attachTo: { element: '.app-sidebar', on: 'auto' },
  title: 'Категории',
  text: 'При открытии категорий, Вы сможете осуществлять поиск, ограничивая его выбранным разделом',

  buttons: [
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '#v-step-1', on: 'auto' },
  title: 'Категории',
  text: 'Чтобы скрыть/показать категории, нажмите на значок стрелочки',

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: () => {
        store.commit('ui/SET_COLLAPSE_MENU', true);
        tour.next();
      }
    },
  ]
});

tour.addStep({
  attachTo: { element: '.search-block', on: 'bottom' },
  title: 'Поиск',
  text: 'В строку поиска введите название товара, его код или артикул и нажмите кнопку "Найти"',

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '.v-step-3', on: 'auto-end' },
  title: 'Товары',
  text: `Чтобы сортировать данные по колонке, нажмите на заголовок:
              значки рядом с названием указывают на выбранную сортировку`,

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '.v-step-35', on: 'auto' },
  title: 'Товары',
  text: 'Количество товаров в корзине меняется кнопками +/- или ручным вводом',
  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '.v-step-4', on: 'auto' },
  title: 'Настройки',
  text: 'Настройки сайта расположены здесь',

  // modalOverlayOpeningPadding: 800,
  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '.v-step-45', on: 'auto' },
  title: 'Корзины',
  text: 'Для изменения размера области корзин нажмите на кнопки в правой части экрана',

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '#v-step-5', on: 'auto' },
  title: 'Корзины',
  text: 'Активные корзины и список товаров в них',

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '#v-step-6', on: 'auto' },
  title: 'Корзины',
  text: 'Для создания новой корзины нажмите на "+" и задайте имя новой корзины',
  classes: 'ml-10',
  scrollTo: true,

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});

tour.addStep({
  attachTo: { element: '#v-step-7', on: 'auto' },
  title: 'Корзины',
  text: 'Чтобы удалить, переименовать или очистить корзину, нажмите на соответствующие кнопки рядом с ее названием',
  scrollTo: true,

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});
tour.addStep({
  attachTo: { element: '.v-step-return', on: 'auto' },
  title: 'Оформление возврата',
  text: 'Для оформления возврата нажмите «Оформить возврат»',

  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});
tour.addStep({
  attachTo: { element: '.v-step-return-basket', on: 'auto' },
  title: 'Оформление возврата',
  text: 'Корзина для возврата расположена рядом с другими, для удобства она помечена красным цветом'
    + '<br>Добавьте товар в корзину возврата и нажмите «Перейти к возврату»',
  beforeShowPromise() {
    return new Promise((resolve) => {
      window.dispatchEvent(new CustomEvent('returns-tour'));
      resolve();
    });
  },
  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action: tour.next
    }
  ]
});
tour.addStep({
  attachTo: { element: '.v-step-return-button', on: 'auto' },
  title: 'Оформление возврата',
  text: 'Добавьте товары в корзину возврата и нажмите «Перейти к возврату»',
  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Далее',
      action() {
        tour.next();
      }
    }
  ]
});
tour.addStep({
  attachTo: { element: '.v-step-8', on: 'auto-start' },
  title: 'Оформление заказа',
  text: 'Для оформления заказа нажмите "Перейти к заказу"',
  beforeShowPromise() {
    return new Promise((resolve) => {
      window.dispatchEvent(new CustomEvent('returns-tour-end'));
      resolve();
    });
  },
  buttons: [
    {
      text: 'Назад',
      action: tour.back
    },
    {
      text: 'Завершить',
      action: tour.complete
    }
  ]
});

export default tour;
