import store from '../store';
import { pipe } from './functions';

const toLocaleString = price => price.toLocaleString('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
/**
 * Добавляет наценку
 * @param price {number}
 * @return {number}
 */
const priceMarkup = price => +price * (+store.getters['products/_percentMarkup'] / 100 + 1);

/**
 * Добавляет наценку и приводит к нормальному виду
 * @param price {number}
 * @return {string}
 */
const normalizePriceMarkup = price => pipe(priceMarkup, toLocaleString)(+price);

/**
 * Приводит прайс к нормальному виду
 * @param price
 * @return {string}
 */
const normalizePrice = price => pipe(toLocaleString)(+price);

const getPrice = (obj, rub = 'price', teng = 'tengePrice') => {
  if (!obj) console.error(obj, rub, teng);
  return +(store.getters['ui/_userCurrency'] !== 'RU' ? obj[teng] : obj[rub]);
};


export {
  getPrice,
  normalizePrice,
  normalizePriceMarkup,
  priceMarkup};
