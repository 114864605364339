<template>
  <div class="history">
    <order-history-selectors
      class="history__selectors"
      @find="find($event)"
    />
    <div
      v-if="loading || returnsLoading"
      v-loading="true"
      class="loader"
    />
    <div
      v-if="returns.length"
      class="history__returns"
    >
      <div class="returns__title">
        Возвраты
      </div>
      <return-order-block
        v-for="returnOrder in returns"
        :key="`return-order---${returnOrder.id}`"
        :return-order="returnOrder"
        :search-query="filterOptions.searchQuery"
      />
      <div v-if="returns.length < returnsCount">
        <span
          class="load-more-returns-link"
          @click="loadMoreReturns"
        >Показать ещё..</span>
      </div>
    </div>
    <div
      v-if="orders.length"
      class="history__orders"
    >
      <div class="orders__title">
        Заказы
      </div>
      <order-block
        v-for="order in orders"
        :key="`order---${order.id}`"
        :order="order"
        :search-query="filterOptions.searchQuery"
      />
      <div ref="endChecker" />
    </div>
    <el-empty
      v-if="!loading && !orders.length"
      :description="isFiltersApplied ? 'По заданным фильтрам заказы не найдены' : 'История заказов пуста'"
    />
  </div>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';
import Vue from "vue";
import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import OrderBlock from '@/views/history/components/orderBlock';
import OrderHistorySelectors from '@/views/history/components/orderHistorySelectors';
import ReturnOrderBlock from '@/views/history/components/returnOrderBlock';

import { orderStates } from './utils';

const defaultSelectedStatuses = orderStates.map(orderState => orderState.id);

const isArrayEquals = (a, b) => Array.isArray(a)
  && Array.isArray(b)
  && a.length === b.length
  && a.every((val, index) => val === b[index]);

export default Vue.extend({
  name: 'ViewOrderHistory',
  block: 'history',
  components: {
    ReturnOrderBlock,
    OrderBlock,
    OrderHistorySelectors,
  },
  metaInfo: {
    title: 'История заказов'
  },
  data() {
    return {
      filterOptions: {
        date: '',
        user: '',
        searchQuery: '',
      },
      statusFilterApplied: false,
      loadedAll: false,
      loading: true,
      returns: [],
      returnsCount: 0,
    };
  },
  computed: {
    ...mapState('ui', ['userCurrency']),
    ...mapState({
      orders: (state) => state.order.orders
    }),
    ...mapState('returns', ['returnsLoading']),
    ...mapGetters('auth', ['_role']),
    isFiltersApplied() {
      return (this.filterOptions.date && this.filterOptions.date.length)
        || this.filterOptions.user.length
        || this.filterOptions.searchQuery.length
        || !this.statusFilterApplied;
    },
    returnsCountToLoad() {
      return !this.returns.length ? 3 : 10;
    }
  },
  async mounted() {
    this.SET_ORDERS_EMPTY();
    await this.GetOrg();
    await this.getOrders({});

    await this.GetReturnProducts(
      {
        max: this.returnsCountToLoad
      }
    )
      .then((response) => {
        this.returns = response.rows;
        this.returnsCount = response.count;
      });
    await this.GetStatuses();

    if (!document.querySelector('.history')) return;
    document.querySelector('.history').addEventListener('scroll', this.infiniteHandler, { passive: true });
  },
  beforeDestroy() {
    if (!document.querySelector('.history')) return;
    document.querySelector('.history').removeEventListener('scroll', this.infiniteHandler);
  },
  methods: {
    ...mapActions('accounts', ['GetOrg']),
    ...mapActions('order', ['GetOrders']),
    ...mapMutations('order', ['SET_ORDERS_EMPTY']),
    ...mapActions('returns', ['GetReturnProducts', 'GetStatuses']),
    async infiniteHandler() {
      if (!this.$refs.endChecker || this.loadedAll || !this.orders.length || this.loading) return;
      if (window.innerHeight >= this.$refs.endChecker.getBoundingClientRect().bottom - 400) {
        await this.getOrders(this.filterOptions);
      }
    },
    async find({
      date, user, checkListData, searchQuery
    }) {
      this.filterOptions = {
        date, user, checkListData, searchQuery
      };
      this.statusFilterApplied = isArrayEquals(checkListData, defaultSelectedStatuses)
      this.SET_ORDERS_EMPTY();
      await this.getOrders(this.filterOptions);
    },
    async getOrders({
      date, user, offset, checkListData, searchQuery
    }) {
      this.loading = true;
      const getDate = dateString => (dateString ? dateString.replaceAll('.', '-') : '');
      return this.GetOrders({
        fromDate: getDate(date && date[0]),
        toDate: getDate(date && date[1]),
        offset,
        accountId: user,
        orderState: checkListData,
        query: searchQuery,
      })
        .then((data) => {
          this.loadedAll = data.length === 0;
          if (this.loadedAll) {
            document.removeEventListener('scroll', this.infiniteHandler);
          }
          return data;
        }).catch((err) => { this.$alert(err, 'Не удалось загрузить заказы'); })
        .finally(() => {
          this.loading = false;
        });
    },
    async loadMoreReturns() {
      await this.GetReturnProducts(
        {
          max: this.returnsCountToLoad,
          offset: this.returns.length
        }
      )
        .then((response) => {
          this.returns.push(...response.rows);
          this.returnsCount = response.count;
        });
    },
    normalizePrice,
  }
});
</script>

<style lang="sass" scoped>
.history
  height: calc(100vh - 42px)
  padding: 0 200px 20px
  overflow: auto
  @media (max-width: 1300px)
    padding: 0 20px
  &__selectors
    position: sticky
    top: 0
    background: white
    z-index: 20
    margin: -20px -20px 0
    padding: 20px
  &__orders
    display: flex
    flex-flow: column
    gap: 10px
    margin-top: 20px
  &__returns
    display: flex
    flex-flow: column
    gap: 10px
    margin-top: 20px
    margin-bottom: 35px
.loader
  height: 40px
.load-more-returns-link
  font-size: 12px
  margin: 10px 0
  color: #1C6A9E
  cursor: pointer
  border-bottom: 1px dashed rgba(28, 106, 158, 0.5)
</style>
