/**
 * Не даёт вызывать функцию слишком часто
 *
 * @param {function} func Запускаемая функция
 * @param {number} wait Отсрочка
 * @param {boolean} immediate Заставляет вызвать функцию незамедлительно
 * @returns {function(...[*]=): void}
 */
export default function debounce(func, wait = 500, immediate = false) {
  let timeout;
  return function deb(...args) {
    const context = this;
    function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
