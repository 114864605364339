export default function supplierCodeName(supplierName) {
  switch (supplierName) {
  case 'Megaros':
  case 'Мегарос':
    return 'MGR';
  case 'Rossko':
  case 'Росско':
    return 'RSK';
  case 'Berg':
    return 'BRG';
  case 'Partcom':
    return 'PRT';
  case 'ShateM':
  case 'Shate-M':
    return 'STM';
  case 'ForumAuto':
    return 'FRM';
  default:
    return '';
  }
}
