/**
 * Маска дающая ввести число от -100 до 1000, с двумя знаками после запятой
 * @param val {string}
 * @returns {string}
 */
export function currencyMask(val) {
  let a = val;
  // eslint-disable-next-line
  a = a.replace(',', '.'); // 0,5 => 0.5
  if (a.search(/\./) === 0) a = 0 + a; // .5 => 0.5
  // eslint-disable-next-line
  a = a.replaceAll(/[^(\d|\.|\-)]/g, ''); // s.sd5a => 0.5
  // eslint-disable-next-line
  if (a.split('.').length > 2) {
    a = `${a.split('.')[0]}.${a.split('.')[1]}`; // 0.5.5 => 0.5
  }
  if (val.length === 5 && a.search(/./) === -1) {
    a = a.substr(0, 4);
  }
  if (a.search(/\./g) > -1) {
    a = a.substr(0, a.search(/\./g) + 3); // 0.558 => 0.55
  }
  if (+a > 1000 || +a < -100) {
    if (+a < -100) a = '-100';
    if (+a > 1000) a = '1000';
  }
  return a;
}

export default { currencyMask };
