import Shepherd from 'shepherd.js';

export default {
  mounted() {
    if (!localStorage.getItem('tourOrder')) {
      this.$confirm('Хотите пройти обучение и ознакомиться с основными функциями сайта?', 'Обучение', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'info'
      })
        .then(() => {
          this.reachGoal('startTour');
          this.$nextTick(() => {
            const tour = new Shepherd.Tour({
              defaultStepOptions: {
                classes: 'shadow-md bg-purple-dark',
                scrollTo: true
              },
              useModalOverlay: true,
              confirmCancel: false
            });

            tour.on('complete', () => {
              this.$alert('Обучение успешно пройдено! Вы сможете запустить обучение в любое время из меню "Помощь"', 'Обучение', {
                confirmButtonText: 'OK'
              }).catch(() => {
                //
              });
              this.reachGoal('endTour');
              localStorage.setItem('tourOrder', 'true');
            });

            tour.on('cancel', () => {
              this.$alert('Вы сможете запустить обучение в любое время из меню "Помощь"', 'Обучение', {
                confirmButtonText: 'OK'
              }).catch(() => {
                //
              });
              localStorage.setItem('tourOrder', 'true');
            });

            tour.addStep({
              attachTo: { element: '.v-step-9', on: 'right' },
              text: 'Чтобы выбрать корзину, из которой будет оформлен заказ, поставьте на нее отметку',
              classes: 'example-step-extra-class',
              cancelIcon: {
                enabled: true
              },
              buttons: [
                {
                  text: 'Далее',
                  action: tour.next
                }
              ]
            });

            tour.addStep({
              attachTo: { element: '.v-step-11', on: 'top' },
              text: 'Чтобы раскрыть/скрыть список товаров в корзине, нажмите на стрелочку или на саму строку.',
              cancelIcon: {
                enabled: true
              },
              buttons: [
                {
                  text: 'Назад',
                  action: tour.back
                },
                {
                  text: 'Далее',
                  action: tour.next
                }
              ]
            });

            tour.addStep({
              attachTo: { element: '.v-step-10', on: 'right' },
              text: 'Общую сумму заказа можно посмотреть внизу, она складывается из сумм по каждой корзине',
              cancelIcon: {
                enabled: true
              },
              classes: 'example-step-extra-class',
              buttons: [
                {
                  text: 'Назад',
                  action: tour.back
                },
                {
                  text: 'Далее',
                  action: tour.next
                }
              ]
            });

            tour.addStep({
              attachTo: { element: '.v-step-12', on: 'left' },
              text: 'Выберите юридическое лицо, на которое будет оформлен заказ, регион и адрес доставки',
              cancelIcon: {
                enabled: true
              },
              buttons: [
                {
                  text: 'Назад',
                  action: tour.back
                },
                {
                  text: 'Далее',
                  action: tour.next
                }
              ]
            });

            tour.addStep({
              attachTo: { element: '.v-step-13', on: 'left' },
              text: 'Нажмите на кнопку "Оформить заказ". Статус заказа вы сможете увидеть в истории заказа.',
              cancelIcon: {
                enabled: true
              },
              buttons: [
                {
                  text: 'Назад',
                  action: tour.back
                },
                {
                  text: 'Завершить обучение',
                  action: tour.complete
                }
              ]
            });

            tour.start();
          });
        })
        .catch(() => {
          this.$alert('Вы сможете запустить обучение в любое время из меню "Помощь"', 'Обучение', {
            confirmButtonText: 'OK'
          }).catch(() => {
            //
          });
          localStorage.setItem('tour', 'true');
          localStorage.setItem('tourOrder', 'true');
        });
    }
  }
};
