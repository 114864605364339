/**
 * Функция склоняет существительное после числа
 * @param number {number} Число
 * @param words
 * @return {*}
 */
export default function declOfNum(number, [one, two, three]) {
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (number % 10 === 1 && number % 100 !== 11) {
    return one;
  }
  if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    return two;
  }
  return three;
}
