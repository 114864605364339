<template>
  <div class="catalog">
    <h2>Оригинальные каталоги</h2>
    <div class="brands">
      <div
        v-for="(block, catalogBlockId) in catalogs[0].children"
        :key="`catalogBlock---${catalogBlockId}`"
        class="brands__block"
        @click="setActiveCatalog(block)"
      >
        <img
          :src="genSrc(block.name)"
          alt=""
        >
        {{ block.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import nameConversion from '@/views/laximo/utils/nameConversion';

export default {
  name: 'LaximoMainPage',
  metaInfo: {
    title: 'Оригинальные каталоги'
  },
  computed: {
    ...mapState('laximo', ['catalogs'])
  },
  methods: {
    ...mapMutations('laximo', ['SET_ACTIVE_CATALOG']),
    ...mapActions('laximo', ['GetInfoByActiveCatalog']),
    setActiveCatalog(catalog) {
      this.$router.push(`/oem/${nameConversion(catalog.name)}`);
    },
    genSrc(name) {
      const wrap = img => `/laximo-logotypes/${img}.jpg`;
      if (name === 'BMW Motorrad') name = 'BMW';
      if (name === 'Fiat Professional') name = 'Fiat';
      return wrap(name.toLowerCase().replace(' ', '-'));
    }
  },
};
</script>

<style scoped lang="sass">
  .catalog
    h2
      margin-top: 0
      margin-bottom: 5px
      font-size: 14px
  .brands
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr))
    gap: 15px 15px
    overflow: auto
    &__block
      display: flex
      flex-flow: nowrap
      align-items: center
      cursor: pointer
      color: black
      border: 1px solid #ebeef5
      padding: 10px
      transition: .2s
      &:hover
        border-color: #1f75a8
      &:hover
        color: #1f75a8
      img
        height: 70px
        width: 70px
        object-fit: contain
        margin-right: 10px


  .search
    grid-column: 1 / -1
    margin-bottom: 20px

</style>
