import { getDeliveryDate } from 'utils/deliveryDays';

function genPrice(stocks) {
  return Math.min(...stocks.map(p => +p.price));
}
function genDelivery(stocks) {
  return Math.min(...stocks.map(p => +p.delivery || 0));
}

function genSum(row) {
  if (row.stocks) return row.stocks[0].price * row.quantity;
  return row.price * row.quantity;
}

function genSupplier(value) {
  if (value === null) return 'Николь';
  value = JSON.parse(value);

  return value.supplier;
}

function genDeliveryInBasket(value) {
  if (!value) return 2;
  value = JSON.parse(value);
  return +value.stocks.delivery;
}

export default function (products, event, table = 'xls') {
  const { order, prop } = event;

  return products.sort((a, b) => {
    if (order === null) {
      return a.index - b.index;
    }

    const TO_LARGE = order === 'ASC';

    if (prop === 'price' && (table === 'xls' || table === 'basket')) {
      return TO_LARGE ? a.price - b.price : b.price - a.price;
    }
    if (prop === 'productPrice' && table === 'history') {
      return TO_LARGE ? a.productPrice - b.productPrice : b.productPrice - a.productPrice;
    }
    if (prop === 'price' && table !== 'xls') {
      return TO_LARGE ? +genPrice(a.stocks) - +genPrice(b.stocks) : +genPrice(b.stocks) - +genPrice(a.stocks);
    }
    if (prop === 'delivery' && table === 'basket') {
      return TO_LARGE
        ? +genDeliveryInBasket(a.supplier) - +genDeliveryInBasket(b.supplier)
        : +genDeliveryInBasket(b.supplier) - +genDeliveryInBasket(a.supplier);
    }
    if (prop === 'delivery' && table !== 'xls') {
      return TO_LARGE ? +genDelivery(a.stocks) - +genDelivery(b.stocks) : +genDelivery(b.stocks) - +genDelivery(a.stocks);
    }

    if (prop === 'deliveryDate' && table === 'part') {
      const getStockDeliveryDays = (row) => {
        return row.stocks.reduce((acc, stock) => {
          if (stock?.deliveryDays === null) {
            return null;
          }
          return Math.min(acc, stock.deliveryDays);
        }, row.stocks[0]?.deliveryDays);
      };
      return TO_LARGE
        ? +getDeliveryDate({ deliveryDays: getStockDeliveryDays(a) }).deliveryDate - +getDeliveryDate({ deliveryDays: getStockDeliveryDays(b) }).deliveryDate
        : +getDeliveryDate({ deliveryDays: getStockDeliveryDays(b) }).deliveryDate - +getDeliveryDate({ deliveryDays: getStockDeliveryDays(a) }).deliveryDate;
    }

    if (prop === 'sum') {
      return TO_LARGE
        ? genSum(a) - genSum(b)
        : genSum(b) - genSum(a);
    }

    let A = a[prop];
    let B = b[prop];

    if (prop === 'supplier') {
      A = genSupplier(a[prop]);
      B = genSupplier(b[prop]);
    }

    if (prop === 'state') {
      const stateSort = [
        'ready',
        'severalSuppliers',
        'haveSeveralRows',
        'notEnoughQuantity',
        'incorrectMultiplicity',
        'notFound',
        'unknownError',
        'excelError',
      ];

      const aIndex = stateSort.findIndex(sortField => A === sortField);
      const bIndex = stateSort.findIndex(sortField => B === sortField);

      return TO_LARGE ? aIndex - bIndex : bIndex - aIndex;
    }

    if (typeof A === 'undefined' && typeof B !== 'undefined') return TO_LARGE ? -1 : 1;
    if (typeof A !== 'undefined' && typeof B === 'undefined') return TO_LARGE ? 1 : -1;
    if (typeof A === 'undefined' && typeof B === 'undefined') return 0;

    if (typeof A === 'string' || typeof B === 'string') {
      A = A.toLowerCase();
      B = B.toLowerCase();
      if (A < B) return TO_LARGE ? -1 : 1;
      if (A > B) return TO_LARGE ? 1 : -1;
      return 0;
    }

    return TO_LARGE ? A - B : B - A;
  });
}
