<template>
  <el-container class="tecdoc">
    <div v-loading.fullscreen.lock="loading" />
    <el-main class="main-padding">
      <div class="app-layout__wrapper">
        <el-container>
          <app-aside />

          <el-main class="abcp">
            <router-view />
          </el-main>
        </el-container>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import ymMixin from 'utils/ymMixin';
import { mapGetters } from 'vuex';

import AppAside from '@/components/app-tree/app-aside';

export default {
  name: 'SidebarLayout',
  components: {
    AppAside,
  },
  mixins: [ymMixin],
  metaInfo: {
    title: 'TecDoc'
  },
  data() {
    return {
      publicPath: import.meta.env.BASE_URL,
      customCatalog: null,
      catalogType: true,
      breadcrumb: [{
        name: 'Товары Николь',
        id: 0,
      }],
      curCat: [],
      productSize: localStorage.getItem('productSize') || 'default',
    };
  },
  computed: {
    ...mapGetters({
      role: 'auth/_role',
      menuWidth: 'ui/menuWidth',
      categories: 'categories/categories',
    }),
    loading() {
      return this.$store.state.tecdoc.loading;
    },
    name() {
      return this.$store.state.accounts.name;
    },
    email() {
      return this.$store.state.accounts.email;
    },

    warehouseId() {
      const { token } = this.$store.state.auth;

      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+')
        .replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(c => `%${(`00${c.charCodeAt(0)
          .toString(16)}`).slice(-2)}`)
        .join(''));

      return JSON.parse(jsonPayload).warehouseId;
    },
    products() {
      return this.$store.state.products.products;
    },
    productsCount() {
      return this.$store.state.products.productsCount;
    },
    basketStyle() {
      let size;

      if (this.productSize === 'default') {
        size = '252px';
      }

      if (this.productSize === 'small') {
        size = 'calc(100% - 43px)';
      }

      if (this.productSize === 'big') {
        size = '92px';
      }

      return {
        width: `calc(100% - ${this.menuButtonStyle.width})`,
        height: size,
      };
    },
  },
  created() {
    this.$store.dispatch('accounts/GetName');
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/AuthLogout');
      this.$router.push('/auth/login');
    },
    personal() {
      this.$router.push('/settings');
    },
    catSearch(categoryes, catId) {
      categoryes.forEach((item) => {
        if (+item.id === +catId) {
          this.breadcrumb.unshift(item);

          if (item.parentId !== undefined) {
            this.catSearch(this.categories, item.parentId);
          }

          return;
        }
        if (item.children !== undefined) {
          this.catSearch(item.children, catId);
        }
      });
    },
    async getCategoryID() {
      this.catalogType = true;
      this.breadcrumb = [];
    },
    basketSize(size) {
      if (size === 'default') {
        this.productSize = 'default';
      }

      if (size === 'big') {
        this.productSize = 'small';
      }

      if (size === 'small') {
        this.productSize = 'big';
      }

      localStorage.setItem('productSize', this.productSize);
    },
  },
};
</script>

<style lang="scss">
.tecdoc {
  @media (min-width: 1200px) {
    .el-col-mini {
      display: none;
    }
    .el-col-big {
      display: block;
    }
  }
  @media (max-width: 1200px) {
    .el-col-mini {
      display: block;
    }

    .el-col-big {
      display: none;
    }
  }

  .main-padding {
    padding: unset !important;
  }

  .menu-icon {
    cursor: pointer;
  }

  .menu-icon_position {
    padding: 10px;
  }

  .menu-icon_color {
    color: black;
  }

  .breadcrumb-container {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .button-container {
    margin-top: 20px;
  }

  .search {
    margin-left: 50px;
    width: 40%;
  }

  .found {
    margin: 0
  }

  .breadcrumb-item {
    cursor: pointer;
    font-size: 12px;
  }

  .breadcrumb-container {
    margin: 0;
    padding-bottom: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .basket-card__body {
    float: right;
    display: flex;
    flex-direction: column;
  }

  .table-card {
    background-color: #fff;
    /*-webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
    /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);*/
    padding: 10px;
  }

  .menu-icon-container {
    position: relative;
    height: 30px
  }

  .menu-icon-container__body {
    position: absolute;
    right: 0;
  }

  .tecdoc-button {
    font-size: 11px;
    color: black;
    padding-left: 34px;
    padding-bottom: 7px;
    cursor: pointer;
  }

  .fs {
    font-weight: 800;
  }

  .personal-account {

  }

  .naw-btn {
    height: 100%;
    font-size: 12px;
    text-decoration: none;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;
    width: max-content;
  }

  .naw-btn:hover {
    background: rgba(0, 0, 0, 0.15);
  }

  .naw-btn.active {
    background: rgba(0, 0, 0, 0.15);
  }

  .not-active-btn {
    cursor: not-allowed;
  }

  p.clip {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 115px;
    padding: 2px 0 0;
  }

  .menu-link {
    color: rgb(96, 98, 102);
    text-decoration: none;
    padding: 10px 50px 10px 20px;
    white-space: pre;
  }

  .menu-link.active {
    font-weight: bold;
  }
}
</style>
