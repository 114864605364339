<template>
  <div
    ref="items"
    class="order-items-wrapper"
  >
    <div class="order-items">
      <page-header>
        Отчёт по закупам
      </page-header>
      <div class="filters">
        <div class="filter">
          <strong class="filter__label font-md">Группировка</strong>
          <el-select
            v-model="groupBy"
            size="small"
            @change="applyFilters"
          >
            <el-option
              label="По месяцам"
              value="month"
            />
            <el-option
              label="По неделям"
              value="week"
            />
            <el-option
              label="По брендам"
              value="brand"
            />
          </el-select>
        </div>
        <div class="filter">
          <strong class="filter__label font-md">Даты</strong>
          <el-date-picker
            v-model="date"
            type="daterange"
            size="mini"
            align="right"
            style="width: 100%"
            unlink-panels
            range-separator="|"
            start-placeholder="С"
            end-placeholder="По"
            value-format="yyyy.MM.dd"
            @change="applyFilters"
          />
        </div>
      </div>
      <el-empty
        v-if="!orderItems.length && !loading"
        description="Нет данных"
      />
      <template v-else>
        <n-table
          v-loading="loading"
          :default-sort="{order: sortOrder, prop: sortField}"
          :data="groupedList"
          :columns="[
            {
              name: groupBy === 'brand' ? 'Бренд' : 'Период',
              prop: 'group',
              width: groupBy === 'week' ? '250px' : '150px',
            },
            {
              name: 'Наименование',
              prop: 'productName',
              width: 'minmax(110px, 2fr)',
              sortable: true
            },
            {
              name: 'Бренд',
              prop: 'Product.brand',
              width: '130px',
              align: 'right',
            },
            {
              name: 'Количество',
              prop: 'quantity',
              width: '80px',
              align: 'right',
            },
            {
              name: 'Сумма',
              prop: 'sum',
              width: '100px',
              align: 'right',
            },
            {
              name: 'Дата покупки',
              prop: 'createdAt',
              width: '150px',
              align: 'right',
              sortable: true
            },
          ]"
          @sort-change="sort($event)"
        >
          <template #group="{ row }">
            <div
              v-if="row.group"
              class="expand"
              @click="toggleRow(row)"
            >
              <i class="el-icon-arrow-down expand__icon" /> {{ row.group }}
            </div>
          </template>
          <template #createdAt="{ row }">
            <template v-if="!row.group">
              {{ formatDate(row.createdAt) }}
            </template>
          </template>
          <template #sum="{ row }">
            <template v-if="row.productPrice !== null">
              <priceOutput
                v-if="row.group"
                :price="normalizePriceMarkup(row.productPrice * row.quantity)"
              />
              <el-popover
                v-else
                trigger="hover"
                popper-class="custom-popover"
              >
                Цена за единицу: <priceOutput :price="normalizePriceMarkup(row.productPrice)" />
                <template #reference>
                  <priceOutput :price="normalizePriceMarkup(row.productPrice * row.quantity)" />
                </template>
              </el-popover>
            </template>
          </template>
        </n-table>
        <div ref="endChecker" />
      </template>
    </div>
  </div>
</template>

<script>
import { addDays } from 'utils/deliveryDays';
import { normalizePriceMarkup } from 'utils/normalizePrice';
import { mapActions, mapMutations, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import formatDate, { getWeekNumber } from '@/utils/formatDate';

export default {
  name: 'OrderItems',
  components: { PageHeader },
  metaInfo: {
    title: 'Отчёт по закупам'
  },
  data: () => ({
    loading: false,
    loadedAll: false,
    perPage: 50,
    page: 1,
    sortOrder: 'ASC',
    sortField: 'productName',
    groupBy: 'month',
    date: [],
    hiddenBrands: [],
    hiddenMonths: [],
    hiddenWeeks: [],
  }),
  computed: {
    ...mapState('orderItems', ['orderItems']),
    groupedList() {
      const list = [];
      this.orderItems.forEach((orderItem, index, items) => {
        const previousRow = items[index - 1];
        const sectionTitle = this.getSectionTitle(previousRow, orderItem);
        if (sectionTitle) {
          let quantity = null;
          let price = null;
          if (this.groupBy === 'month') {
            const itemsByMonth = this.getItemsByMonth(
              new Date(orderItem.createdAt).getMonth(),
              new Date(orderItem.createdAt).getFullYear()
            );
            quantity = itemsByMonth.length;
            price = itemsByMonth.reduce((sum, current) => sum + current.productPrice, 0);
          }
          if (this.groupBy === 'brand') {
            const itemsByBrand = this.getItemsByBrand(
              orderItem['Product.brand'],
            );
            quantity = itemsByBrand.length;
            price = itemsByBrand.reduce((sum, current) => sum + current.productPrice, 0);
          }
          if (this.groupBy === 'week') {
            const itemsByWeek = this.getItemsByWeek(
              getWeekNumber(orderItem.createdAt),
            );
            quantity = itemsByWeek.length;
            price = itemsByWeek.reduce((sum, current) => sum + current.productPrice, 0);
          }

          list.push({
            group: sectionTitle,
            'Product.brand': null,
            createdAt: null,
            productId: null,
            productName: null,
            productPrice: price,
            quantity,
            brand: orderItem['Product.brand'],
            month: `${new Date(orderItem.createdAt).getMonth()}.${new Date(orderItem.createdAt).getFullYear()}`,
            week: getWeekNumber(orderItem.createdAt)
          });
        }
        if (!this.isHiddenRow(orderItem)) {
          list.push(orderItem);
        }
      });
      return list;
    }
  },
  watch: {
    sort() {
      this.getItems();
    }
  },
  async mounted() {
    this.$refs.items.addEventListener('scroll', this.infiniteHandler, { passive: true });
    this.loading = true;
    await this.getItems();
    this.loading = false;
  },
  beforeDestroy() {
    this.$refs.items.removeEventListener('scroll', this.infiniteHandler);
  },
  methods: {
    ...mapActions('orderItems', ['getOrderItems']),
    ...mapMutations('orderItems', ['SET_ORDER_ITEMS']),
    formatDate,
    normalizePriceMarkup,
    async infiniteHandler() {
      if (this.loadedAll) this.$refs.items.removeEventListener('scroll', this.infiniteHandler);
      if (!this.$refs.endChecker || this.loadedAll || !this.orderItems.length || this.loading) return;
      if (window.innerHeight >= this.$refs.endChecker.getBoundingClientRect().bottom - 400) {
        await this.getItems();
      }
    },
    async getItems() {
      this.loading = true;
      const getDate = dateString => (dateString ? dateString.replaceAll('.', '-') : null);
      await this.getOrderItems(
        {
          max: this.perPage,
          offset: this.orderItems.length,
          sortField: this.groupBy !== 'brand' ? 'createdAt' : this.sortField,
          sortOrder: this.sortOrder,
          from: this.date && getDate(this.date[0]),
          to: this.date && getDate(this.date[1]),
          groupByDate: this.groupBy === 'week' || this.groupBy === 'month' ? this.groupBy : undefined,
        }
      ).then((data) => {
        this.loadedAll = data.length === 0;
        if (this.loadedAll) {
          document.removeEventListener('scroll', this.infiniteHandler);
        }
      });
      this.loading = false;
    },
    async applyFilters() {
      this.SET_ORDER_ITEMS([]);
      await this.getItems();
    },
    async sort({ order, prop }) {
      this.SET_ORDER_ITEMS([]);
      this.sortOrder = order;
      this.sortField = prop;
      await this.getItems();
    },
    getSectionTitle(previousRow, row) {
      if (this.groupBy === 'month') {
        if (
          !previousRow || (
            previousRow && new Date(row.createdAt).getMonth() !== new Date(previousRow.createdAt).getMonth()
          )
        ) {
          return new Date(row.createdAt).toLocaleString('ru', {
            month: 'long',
            year: 'numeric'
          });
        }
      }
      if (this.groupBy === 'week') {
        if (
          !previousRow || (
            previousRow && getWeekNumber(row.createdAt) !== getWeekNumber(previousRow.createdAt)
          )
        ) {
          const dayOfWeek = new Date(row.createdAt);
          const firstDay = addDays(dayOfWeek, -dayOfWeek.getDay() + 1);
          const lastDay = addDays(dayOfWeek, -dayOfWeek.getDay() + 7);

          return `${formatDate(firstDay)} – ${formatDate(lastDay)}`;
        }
      }
      if (this.groupBy === 'brand') {
        if (!previousRow || (previousRow && row['Product.brand'] !== previousRow['Product.brand'])) {
          return row['Product.brand'];
        }
      }

      return null;
    },
    isHiddenRow(row) {
      if (this.groupBy === 'brand') {
        return this.hiddenBrands.includes(row['Product.brand']);
      }
      if (this.groupBy === 'week') {
        return this.hiddenWeeks.includes(getWeekNumber(row.createdAt));
      }
      if (this.groupBy === 'month') {
        const monthKey = `${new Date(row.createdAt).getMonth()}.${new Date(row.createdAt).getFullYear()}`;
        return this.hiddenMonths.includes(monthKey);
      }
      return false;
    },
    getItemsByMonth(monthNumber, year) {
      return this.orderItems.filter(orderItem => new Date(orderItem.createdAt).getMonth() === monthNumber
        && new Date(orderItem.createdAt).getFullYear() === year);
    },
    getItemsByBrand(brandName) {
      return this.orderItems.filter(orderItem => orderItem['Product.brand'] === brandName);
    },
    getItemsByWeek(weekNumber) {
      return this.orderItems.filter(orderItem => getWeekNumber(orderItem.createdAt) === weekNumber);
    },
    toggleRow(groupRow) {
      if (this.groupBy === 'week') {
        const index = this.hiddenWeeks.indexOf(groupRow.week);
        if (index === -1) {
          this.hiddenWeeks.push(groupRow.week);
        } else {
          this.hiddenWeeks.splice(index, 1);
        }
      }
      if (this.groupBy === 'month') {
        const index = this.hiddenMonths.indexOf(groupRow.month);
        if (index === -1) {
          this.hiddenMonths.push(groupRow.month);
        } else {
          this.hiddenMonths.splice(index, 1);
        }
      }
      if (this.groupBy === 'brand') {
        const index = this.hiddenBrands.indexOf(groupRow.brand);
        if (index === -1) {
          this.hiddenBrands.push(groupRow.brand);
        } else {
          this.hiddenBrands.splice(index, 1);
        }
      }
    },
  },
};
</script>

<style lang="sass">
.order-items-wrapper .n-table-row__col
  padding: 10px
</style>
<style scoped lang="sass">
.order-items-wrapper
  overflow: auto
  max-height: calc(100vh - 42px)
.filters
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
  grid-gap: 15px
  margin-bottom: 30px
.filter
  display: flex
  flex-direction: column
  &__label
    margin-bottom: 5px
.order-items
  max-width: 1000px
  margin: 0 auto
  padding: 20px
.expand
  cursor: pointer
  &__icon
    margin-right: 5px
</style>
