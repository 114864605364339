import { mapMutations, mapState } from 'vuex';

export default {
  created() {
    if (!localStorage.getItem('showColumns')) {
      this.SET_SHOW_COLUMNS({
        hide: true,
        hide2: true,
        hide3: false,
        hide4: true,
        hide5: true,
      });
    }
  },
  computed: {
    ...mapState('ui', ['showColumns']),
  },
  methods: {
    ...mapMutations('ui', ['SET_SHOW_COLUMNS']),
  }
};
