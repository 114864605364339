<template>
  <span class="price-output">
    {{ price.split(',')[0] }}<span class="price-output__little">,{{ price.split(',')[1] }}</span>
    <span
      v-if="!hideCurrency"
      class="price-output__currency"
    >&nbsp;{{ currency || currencySign() }}</span>
  </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PriceOutput',
  props: {
    price: {
      type: String,
      required: true,
      validate: val => val.findIndex(',') > 0
    },
    showRub: {
      type: Boolean,
      required: false,
      default: false,
      validate: val => typeof val === 'boolean'
    },
    hideCurrency: {
      type: Boolean,
      required: false,
      default: false,
      validate: val => typeof val === 'boolean'
    },
    currency: {
      type: String,
      required: false,
      default: '',
      validate: val => typeof val === 'string'
    }
  },
  computed: {
    ...mapState('ui', ['userCurrency']),
  },
  methods: {
    currencySign() {
      const currencies = {
        RU: '₽',
        KZ: '₸'
      };
      if (this.showRub) {
        return currencies.RU;
      }
      return this.userCurrency !== 'RU' ? currencies[this.userCurrency] : '';
    },
  }
};
</script>

<style lang="sass">
.price-output
  display: inline-flex
  &__little
    opacity: .6
    font-size: .7em
    align-self: flex-start
    margin-top: 1px
  &__currency
    opacity: .7
</style>
