export default function _isEqualSuppliers(supplier, basketSupplier) {
  if (!supplier || !basketSupplier || supplier.stocks || basketSupplier.stocks) return false;

  return supplier.article === basketSupplier.article
    && supplier.brand === basketSupplier.brand
    && supplier.name === basketSupplier.name
    && supplier.guid === basketSupplier.guid
    && supplier.stocks.delivery === basketSupplier.stocks.delivery
    && supplier.stocks.price === basketSupplier.stocks.price;
}
