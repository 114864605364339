<template>
  <component
    :is="getComponentType"
    @before-enter="beforeEnterHandler"
    @before-leave="beforeLeaveHandler"
    @enter="enterHandler"
    @leave="leaveHandler"
    @after-enter="afterEnterHadler"
    @after-leave="afterLeaveHandler"
  >
    <slot />
  </component>
</template>

<script>
/* eslint-disable */
export default {
  name: 'app-tree-transition',
  props: {
    duration: {
      type: Number,
      default: 300,
    },
    group: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getComponentType() {
      return this.group ? 'transition-group' : 'transition';
    },
  },
  methods: {
    beforeEnterHandler(el) {
      let duration = this.duration;
      el.style.transition = `${duration}ms height ease-in`;

      if (!el.dataset) {
        el.dataset = {};
      }

      el.style.height = '0';
    },
    beforeLeaveHandler(el) {
      if (!el.dataset) {
        el.dataset = {};
      }

      el.dataset.overflow = el.style.overflow;

      el.style.height = `${el.scrollHeight}px`;
      el.style.overflow = 'hidden';
    },
    enterHandler(el) {
      el.dataset.overflow = el.style.overflow;

      if (el.scrollHeight !== 0) {
        el.style.height = `${el.scrollHeight}px`;
      } else {
        el.style.height = '';
      }

      el.style.overflow = 'hidden';
    },
    leaveHandler(el) {
      let duration = this.duration;

      if (el.scrollHeight !== 0) {
        el.style.transition = `${duration}ms height ease-in`;
        el.style.height = 0;
      }
    },
    afterEnterHadler(el) {
      el.style.transition = '';
      el.style.height = '';
      el.style.overflow = el.dataset.overflow;
    },
    afterLeaveHandler(el) {
      el.style.transition = '';
      el.style.height = '';
      el.style.overflow = el.dataset.overflow;
    }
  },
};
</script>
