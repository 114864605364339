<template>
  <div>
    <el-dialog
      title="Скачать прайс в формате Excel"
      :visible.sync="show"
      :width="windowSize.width > 830 ? '30%': 'auto'"
      :lock-scroll="false"
    >
      <div class="dialog-body">
        <div v-if="errors.length > 0">
          <el-alert
            v-for="error in errors"
            :key="error"
            :title="error"
            type="error"
            :closable="false"
          />
        </div>

        <el-row
          type="flex"
          class="row-bg"
          justify="center"
        >
          <el-radio
            v-model="radio"
            label="all"
          >
            По наличию
          </el-radio>
          <el-radio
            v-model="radio"
            label="new"
          >
            Новинки
          </el-radio>
        </el-row>

        <el-row
          type="flex"
          class="row-bg"
          justify="center"
        >
          <el-button
            type="primary"
            plain
            @click="formLink"
          >
            Сформировать
          </el-button>
        </el-row>

        <el-row
          v-show="showBtn"
          type="flex"
          justify="center"
        >
          <div>
            <el-link
              :href="link"
              target="_blank"
            >
              <el-button
                type="success"
                icon="el-icon-download"
                plain
                @click="downloadPrice"
              >
                Скачать
              </el-button>
            </el-link>
          </div>
        </el-row>
      </div>
    </el-dialog>
    <div
      v-if="showResult"
      class="excel-modal-result"
    >
      <el-tooltip
        class="item"
        content="Завершить загрузку прайса"
        placement="top"
      >
        <el-button
          class="excel-modal-result__close-button"
          icon="el-icon-close"
          size="mini"
          @click="cancel"
        />
      </el-tooltip>
      <div v-if="loading">
        Подождите, прайс формируется
      </div>
      <div v-else>
        Прайс сформирован
      </div>
      <el-button
        :loading="loading"
        type="success"
        icon="el-icon-download"
        class="excel-modal-result__button"
        plain
        @click="downloadPrice"
      >
        Скачать
      </el-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';

import axiosInstance from '../../api/axiosInstance';

export default {
  name: 'ExcelModal',
  data() {
    return {
      radio: 'new',
      showResult: false,
      loading: false,
      showBtn: false,
      errors: [],
      link: '',
      /** @type CancelTokenSource|null */
      requestCancelToken: null,
    };
  },
  computed: {
    ...mapState('excel', {
      open: 'showExelModal'
    }),
    ...mapState('ui', ['windowSize']),
    show: {
      set(val) {
        this.SET_SHOW_EXCEL_MODAL(val);
      },
      get() {
        return this.open;
      }
    },
  },
  watch: {
    radio() {
      this.showBtn = false;
    },
    $route(val) {
      if (val.query.excel && !this.open) this.openModal();
    },
    show(val) {
      if (!val && this.$route.query.excel) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            excel: undefined
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations('excel', ['SET_SHOW_EXCEL_MODAL']),
    async formLink() {
      if (this.validate()) {
        this.loading = true;
        this.showResult = true;
        this.SET_SHOW_EXCEL_MODAL(false);

        const cancelTokenSource = axios.CancelToken.source();
        this.requestCancelToken = cancelTokenSource;

        const params = {};

        if (this.radio === 'new') {
          params.newPositions = true;
        }

        await axiosInstance({
          url: '/api/prices/excel',
          params,
          cancelToken: cancelTokenSource.token
        })
          .then((data) => {
            this.showBtn = true;
            this.link = data;
            setTimeout(() => {
              window.open(this.link);
            }, 100);
          })
          .catch(() => this.errors.push('Ошибка формирования документа'))
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openModal() {
      this.errors = [];
      this.showBtn = false;
      this.SET_SHOW_EXCEL_MODAL(true);

      if (!this.$route.query?.excel) {
        this.$router.replace({
          query: { ...this.$route.query, excel: '1' }
        });
      }
    },
    validate() {
      this.errors = [];
      if (!this.radio) {
        this.errors.push('Выберите тип прайса');
      }

      return this.errors.length === 0;
    },
    async downloadPrice() {
      setTimeout(() => {
        window.open(this.link);
      }, 100);
    },
    cancel() {
      if (!this.loading) {
        this.showResult = false;
        return;
      }
      this.$confirm('Завершить формирование и загрузку прайса', 'Отмена', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        if (this.requestCancelToken) {
          this.requestCancelToken.cancel();
        }
        this.showResult = false;
      }).catch(() => {
        //
      });
    }
  },
};
</script>

<style scoped lang="sass">
  .exel-icon
    height: 40px
    display: flex
    align-items: center
    cursor: pointer
  .center
    text-align: center
  .row-bg
    padding: 15px
  .dialog-body
    margin-top: -10px
  .excel-modal-result
    background: #fff
    padding: 20px 60px 20px 30px
    position: fixed
    bottom: 20px
    right: 20px
    border: 1px solid #ebeef5
    box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
    border-radius: 8px
    border-top: 2px solid #3DB1FF
    z-index: 100
    color: #000
    font-size: 13px
    &__button
      margin-top: 10px
    &__close-button
      position: absolute
      top: 18px
      right: 10px
</style>
