<template>
  <el-form
    ref="regForm"
    hide-required-asterisk
    :model="model"
    status-icon
    :rules="rules"
    label-width="120px"
    label-position="top"
    class="demo-ruleForm"
  >
    <h2>Регистрация</h2>
    <el-form-item
      label="Email"
      prop="email"
    >
      <el-input
        v-model="model.email"
        :disabled="true"
      />
    </el-form-item>

    <el-form-item
      label="Код"
      prop="code"
    >
      <el-input
        v-model="model.code"
        :disabled="true"
        autocomplete="off"
      />
    </el-form-item>

    <el-form-item
      label="Имя"
      prop="name"
    >
      <el-input
        v-model="model.name"
        autocomplete="off"
        placeholder="Введите имя пользователя"
      />
    </el-form-item>

    <el-form-item
      label="Пароль"
      prop="pass"
    >
      <el-input
        v-model="model.pass"
        minlength="6"
        show-word-limit
        type="password"
        autocomplete="off"
        placeholder="Придумайте пароль для личного кабинета"
        show-password
      />
    </el-form-item>

    <el-alert
      v-if="error"
      :title="error"
      type="error"
      @close="error = ''"
    />

    <el-form-item>
      <el-button
        :loading="isLoading"
        type="primary"
        @click="submitForm"
      >
        Сохранить и войти
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AppRegister',
  metaInfo: {
    title: 'Регистрация'
  },
  data() {
    return {
      isLoading: false,
      error: '',
      model: {
        pass: '',
        email: '',
        name: '',
        code: '',
      },
      rules: {
        code: [
          {
            required: true, message: 'Пожалуйста введите Код', trigger: 'blur'
          }
        ],
        pass: [
          {
            required: true, message: 'Пожалуйста введите пароль', trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: 'Пожалуйста введите ваш email', trigger: 'blur' },
          { type: 'email', message: 'Проверьте корректность введенного email', trigger: 'blur' }
        ]
      }
    };
  },
  async created() {
    const { email, code } = this.$route.query;
    this.model.email = email || '';
    this.model.code = code || '';

    if (await this.HasUserPassword(this.model.email)) {
      await this.$router.push('/auth/login');
    }
  },
  methods: {
    ...mapActions('auth', ['RegRequest', 'HasUserPassword']),
    submitForm() {
      this.$refs.regForm.validate((valid) => {
        if (valid) {
          this.registerRequest();
          return true;
        }
        return false;
      });
    },
    async registerRequest() {
      this.isLoading = true;
      await this.RegRequest({
        email: this.model.email,
        code: this.model.code,
        password: this.model.pass,
        name: this.model.name,
      })
        .then(() => {
          this.error = '';
          this.$router.push('/products');
          this.$store.commit('accounts/SET_EMAIL', this.model.email);
        })
        .catch(({ message }) => {
          this.error = message || 'Непредвиденная ошибка. Попробуйте повторить действие позднее.';
        });
      this.isLoading = false;
    },
  }
};
</script>

<style src="./auth.sass" scoped lang="sass" />
