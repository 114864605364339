<template>
  <div class="promo-actions">
    <page-header>
      Все акции
    </page-header>
    <div
      v-if="loading"
      v-loading="true"
      class="loader"
    />
    <el-empty
      v-if="!loading && !promoActions.length"
      description="В данный момент нет активных акций"
    />
    <div class="promo-actions__list">
      <promo-action-item
        v-for="promoAction in promoActions"
        :key="promoAction.id"
        :name="promoAction.name"
        :sum="promoAction.sum"
        :value="promoAction.value"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import PromoActionItem from '@/views/products/components/PromoActionItem';

export default {
  name: 'PromoPage',
  components: { PromoActionItem, PageHeader },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState('products', ['promoActions'])
  },
  async mounted() {
    this.loading = true;
    await this.getPromoActions();
    this.loading = false;
  },
  methods: {
    ...mapActions('products', ['getPromoActions'])
  }
};
</script>

<style scoped lang="sass">
  .promo-actions
    max-width: 900px
    margin: 0 auto
    padding: 20px

    &__list
      margin: 20px 0
      display: grid
      grid-row-gap: 15px
</style>
