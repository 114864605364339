import axiosInstance from '../../api/axiosInstance';

/**
 * @typedef Organization
 * @property {boolean} active Active state
 * @property {number} id Identifier
 * @property {string} name Organization name
 */

/**
 * @typedef City
 * @property {number} cityId Identifier
 * @property {string} name City name
 */

/**
 * @typedef DeliveryCalendar
 * @property {number} id
 * @property {number} cityId
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {number} monday
 * @property {number} mondayTime
 * @property {number} tuesday
 * @property {number} tuesdayTime
 * @property {number} wednesday
 * @property {number} wednesdayTime
 * @property {number} thursday
 * @property {number} thursdayTime
 * @property {number} friday
 * @property {number} fridayTime
 * @property {number} saturday
 * @property {number} saturdayTime
 * @property {number} sunday
 * @property {number} sundayTime
 */

const state = () => ({
  /** @type {Organization[]} */
  org: [],
  addresses: [],
  selectedOrgId: null,
  selectedAddress: '',
  contact: [],
  accountId: null,
  name: '',
  email: localStorage.getItem('user-email') || '',
  myUsers: [],
  /** @type {City|null} */
  city: null,
  /** @type {City[]} */
  cities: [],
  /** @type {DeliveryCalendar|null} */
  deliveryDates: null,
});

const mutations = {
  SET_ORG(state, org) {
    state.org = org;
  },
  SET_USERS(state, users) {
    state.myUsers = users;
  },
  SET_EMAIL(state, email) {
    state.email = email;
    localStorage.setItem('user-email', email);
  },
  SET_ACCOUNT_ID(state, accountId) {
    state.accountId = accountId;
  },
  SET_NAME(state, name) {
    state.name = name.name;
  },
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses;
  },
  SET_CONTACT(state, contact) {
    state.contact = contact.contact;
  },
  SET_CITY(state, city) {
    state.city = city;
  },
  SET_CITIES(state, cities) {
    state.cities = cities;
  },
  SET_DELIVERY_DATES(state, deliveryDates) {
    state.deliveryDates = deliveryDates;
  },
};
const actions = {
  SetPostPass(context, pass) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/changePassword',
        method: 'put',
        data: {
          oldPassword: pass.old,
          password: pass.new,
        }
      })
        .then(() => {})
        .catch(err => reject(err));
    });
  },

  SetPostName({ commit }, newName) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/name',
        method: 'put',
        data: {
          name: newName,
        }
      })
        .then(() => {
          commit('SET_NAME', { name: newName });
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },

  GetName({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/name',
      })
        .then((name) => {
          commit('SET_NAME', name);
          commit('SET_ACCOUNT_ID', name.accountId);
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  async GetCities({ commit }, accountId = undefined) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/accounts/cities',
        params: {
          accountId,
        },
      })
        .then((cities) => {
          commit('SET_CITIES', cities);
          resolve(cities);
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  GetCity({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/currentCity',
      })
        .then((city) => {
          commit('SET_CITY', city);
          resolve(city);
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  SetCity(_, cityId) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/currentCity',
        method: 'PATCH',
        data: {
          cityId
        }
      })
        .then(data => resolve(data))
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  GetShipmentDescription({ commit, state }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: `/api/citiesShipmentDates/${state.city?.cityId}`,
      })
        .then((data) => {
          commit('SET_SHIPMENT_DESCRIPTION', data);
          resolve(data);
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
  GetOrg({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/accounts/org',
      })
        .then((org) => {
          commit('SET_ORG', org);
          resolve(org);
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },

  GetAddresses({ commit }, accountId) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/accounts/addresses',
        params: {
          accountId,
        },
      })
        .then((addresses) => {
          commit('SET_ADDRESSES', addresses);
          resolve();
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },

  GetContact({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/accounts/contacts',
      })
        .then((contact) => {
          commit('SET_CONTACT', { contact });
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },

  async GetMyUsers({ commit }) {
    await axiosInstance({
      url: '/api/users',
    })
      .then((users) => {
        commit('SET_USERS', users);
      })
      .catch(err => new Error(err));
  },

  async PostMyUsers({ dispatch }, { name, email }) {
    await axiosInstance({
      url: '/api/users',
      method: 'post',
      data: {
        name,
        email
      }
    })
      .then(() => {
        dispatch('GetMyUsers');
      })
      .catch(err => new Error(err));
  },

  async ActivateUser({ dispatch }, name) {
    await axiosInstance({
      url: '/api/users/active',
      method: 'put',
      data: {
        name,
      }
    })
      .then(() => {
        dispatch('GetMyUsers');
      })
      .catch(err => new Error(err));
  },

  async DisactivateUser({ dispatch }, name) {
    await axiosInstance({
      url: '/api/users',
      method: 'delete',
      data: {
        name,
      }
    })
      .then(() => {
        dispatch('GetMyUsers');
      })
      .catch(err => new Error(err));
  },

  /**
   * Получает все последние не прочитанные сообщения в чейнджлоге
   *
   * @returns {Promise<{
   * id: number,
   * text: string,
   * createdAt: string,
   * updatedAt: string,
   * }[] | Error>}
   * @constructor
   */
  GetLastUpdates() {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/updates',
        method: 'GET',
      })
        .then(data => resolve(data))
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   * Отправляет id последнего прочитанного пользователем сообщения в чейнджлоге
   *
   * @param {context} _
   * @param {number} id Id последнего сообщения
   * @returns {Promise<{}[] | Error>}
   * @constructor
   */
  SetLastUpdateId(_, id) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/users/updates',
        method: 'PATCH',
        data: { id }
      })
        .then(data => resolve(data))
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  /**
   *
   * @param commit
   * @return {Promise<DeliveryCalendar>}
   */
  getDeliveryDates({ commit }) {
    return new Promise((resolve, reject) => {
      axiosInstance('/api/accounts/deliveryDates')
        .then((deliveryDates) => {
          commit('SET_DELIVERY_DATES', deliveryDates);
          resolve(deliveryDates);
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
};

const getters = { };

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
