// eslint-disable-next-line import/prefer-default-export
export const orderStates = [
  {
    id: 1,
    value: 'Создан',
    label: 'Создан',
    color: 'orange',
  },
  {
    id: 2,
    value: 'В работе',
    label: 'В работе',
    color: 'blue',
  },
  {
    id: 3,
    value: 'Выполнен',
    label: 'Выполнен',
    color: 'green',
  },
  {
    id: 4,
    value: 'История',
    label: 'История',
    color: 'gray',
  }
];
