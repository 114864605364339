<template>
  <div class="ReconciliationReports">
    <div class="wrapper">
      <page-header>
        Акты сверки
        <template #subtitle>
          Вы можете запросить акт-сверки и посмотреть историю запросов
        </template>
      </page-header>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="selectedOrgId"
            placeholder="Выберите юр.лицо"
            style="width: 100%"
            size="small"
            class="custom-select"
            default-first-option
            :disabled="!organizationsLoaded"
          >
            <el-option
              v-for="organization in organizations"
              :key="organization.id"
              :label="organization.name"
              :value="organization.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="sending"
            type="primary"
            size="small"
            :disabled="!selectedOrgId"
            @click="makeReport"
          >
            Запросить
          </el-button>
        </el-form-item>
      </el-form>
      <div
        v-if="loading"
        v-loading="true"
        class="loader"
      />
      <div
        v-for="report in reports"
        :key="report.id"
        class="report"
      >
        <div class="report__data">
          <span>Дата запроса</span>
          {{ formatDate(report.createdAt) }}
        </div>
        <div class="report__data">
          <span>Юр. лицо</span>
          <div v-if="organizationsLoaded">
            {{ organizations.find(organization => organization.id === report.orgId).name }}
          </div>
        </div>
        <div class="report__data report__data_status">
          <div v-if="report.documentURL">
            <el-button @click="openLink(report.documentURL)">
              Скачать
            </el-button>
          </div>
          <div
            v-else
            class="report__status"
            :class="statusClasses[report.status]"
          >
            {{ report.statusName }}
          </div>
        </div>
      </div>
      <el-empty
        v-if="!loading && !reports.length"
        description="Вы ещё не запрашивали акты сверки"
      />
    </div>
  </div>
</template>

<script>
import formatDate from 'utils/formatDate';
import { mapActions, mapMutations, mapState } from 'vuex';

import PageHeader from '@/components/page-header/page-header';

export default {
  name: 'ReconciliationReports',
  components: { PageHeader },
  data() {
    return {
      selectedOrgId: null,
      organizationsLoaded: false,
      statusClasses: {
        1: 'report__status_blue',
        2: 'report__status_orange',
        3: 'report__status_green',
        4: 'report__status_red'
      }
    };
  },
  metaInfo: {
    title: 'Акты сверки'
  },
  computed: {
    ...mapState('reconciliationReports', ['reports', 'loading', 'sending']),
    ...mapState('accounts', {
      organizations: 'org'
    }),
  },
  async mounted() {
    await this.getReports();
    await this.GetOrg().then(() => {
      this.organizationsLoaded = true;
    });
  },
  methods: {
    formatDate,
    ...mapActions('reconciliationReports', ['getReports', 'postReports']),
    ...mapActions('accounts', ['GetOrg']),
    ...mapMutations('reconciliationReports', ['SET_SELECTED_ORG_ID']),
    async makeReport() {
      await this.postReports(this.selectedOrgId);
      await this.getReports();
      this.$message({
        message: 'Запрос на формирование акта сверки отправлен',
        type: 'success'
      });
    },
    openLink(target) {
      window.open(target, '_blank');
    }
  }
};
</script>
<style scoped lang="sass">

.ReconciliationReports
  padding: 20px
  margin: 0 auto
  overflow: auto
  .wrapper
    max-width: 800px
    margin: 0 auto
.loader
  height: 40px
.report
  position: relative
  padding: 10px 20px
  background: #fff
  border: 1px solid #ebeef5
  box-shadow: 0 9px 22px 0 rgb(0 0 50 / 5%)
  border-radius: 8px
  display: flex
  flex-flow: nowrap
  align-items: center
  margin-bottom: 15px
  &__data
    display: flex
    flex-flow: column
    margin-right: 15px
    opacity: .8
    font-size: 14px
    @media (max-width: 700px)
      margin-right: 0
      margin-bottom: 5px
    span
      font-size: 10px
      color: #636363
    &_status
      margin-left: auto
  &__status
    flex-shrink: 0
    border-radius: 3px
    font-size: 11px
    text-align: center
    font-weight: bold
    min-height: 23px
    padding: 0 10px
    display: inline-flex
    align-items: center
    justify-content: center
    text-transform: uppercase
    &_green
      color: #3D8F5F
      border: 1px solid #3D8F5F
    &_red
      color: #ff1c0a
      border: 1px solid #ff1c0a
    &_orange
      color: #FF9B0D
      border: 1px solid #FF9B0D
    &_blue
      color: #0074F7
      border: 1px solid #0074F7
    &_purpur
      color: #c000ff
      border: 1px solid #c000ff
</style>
