import axiosInstance from '../../api/axiosInstance';

/**
 * @typedef ReceivableDocument
 * @property {number} accountId
 * @property {number} amount
 * @property {string} createdAt
 * @property {string} dateShipping
 * @property {string} documentDate
 * @property {string} documentId
 * @property {string} documentType
 * @property {number} id
 * @property {number|null} orderId
 * @property {string} scheduledPaymentDate
 * @property {string} updatedAt
 */

const state = () => ({
  receivable: 0,
  /** @type {Object.<number, ReceivableDocument[]>} */
  receivablesByDocuments: [],
  loading: false,
  organizationsLoading: false,
  organizations: [],
});

const mutations = {
  SET_RECEIVABLE(state, receivable) {
    state.receivable = receivable;
  },
  SET_RECEIVABLES_BY_DOCUMENTS(state, receivablesByDocuments) {
    state.receivablesByDocuments = receivablesByDocuments;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ORGANIZATIONS_LOADING(state, loading) {
    state.organizationsLoading = loading;
  },
  SET_ORGANIZATIONS(state, organizations) {
    state.organizations = organizations;
  },
};
const actions = {
  /**
   * Получить число дебиторской задолженности
   * @param commit
   * @returns {Promise<void>}
   */
  async getReceivable({ commit }) {
    await axiosInstance('/api/receivable')
      .then((resp) => {
        commit('SET_RECEIVABLE', resp[0]?.receivable || 0);
      });
  },
  /**
   * Получить взаиморасчеты по документам
   */
  async getReceivablesByDocuments({ commit }, {
    orgId, from, to, sortOrder, sortField
  }) {
    commit('SET_LOADING', true);
    await axiosInstance({
      url: '/api/receivablesByDocuments',
      params: {
        orgId, from, to, sortOrder, sortField
      }
    })
      .then((resp) => {
        if (resp && Object.keys(resp).length > 0) {
          commit('SET_RECEIVABLES_BY_DOCUMENTS', resp);
        } else {
          commit('SET_RECEIVABLES_BY_DOCUMENTS', null);
        }
      });
    commit('SET_LOADING', false);
  },
  async getReceivablesByDocumentsOrganizations({ commit }) {
    commit('SET_ORGANIZATIONS_LOADING', true);
    await axiosInstance({
      url: '/api/receivablesByDocuments/organizations'
    })
      .then((resp) => {
        commit('SET_ORGANIZATIONS', resp);
      });
    commit('SET_ORGANIZATIONS_LOADING', false);
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
