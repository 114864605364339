<template>
  <div class="laximo-page">
    <el-container>
      <app-aside />
      <el-main class="laximo-page__container">
        <div
          v-if="catalogs[0]"
          class="laximo-page__wrapper"
          :class="{ 'laximo-page__wrapper_small': basketSize === 'big' }"
        >
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
        <basket-aside />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import AppAside from '@/components/app-tree/app-aside';
import BasketAside from '@/components/basket-aside/basketAside';

export default {
  name: 'LaximoCatalogs',
  components: {
    AppAside,
    BasketAside,
  },
  data: () => ({
    catalogInfo: null,
    wizard: null,

    ssd: '',
    autos: {},
    columns: [],
  }),
  computed: {
    ...mapState('laximo', ['catalogs', 'activeCatalog', 'activeCatalogId', 'activeCatalogWizards', 'activeCatalogAutos']),
    ...mapState('ui', ['basketSize']),
  },
  mounted() {
    this.GetListCatalogs();
  },
  methods: {
    ...mapMutations('laximo', ['SET_ACTIVE_CATALOG']),
    ...mapActions('laximo', ['GetListCatalogs', 'GetCatalogInfo', 'GetWizard2', 'GetInfoByActiveCatalog', 'GetAutos']),
    setActiveCatalog(catalog) {
      this.SET_ACTIVE_CATALOG(catalog);
      this.$store.commit('categories/SET_CURRENT_CATEGORY', catalog.id);
      this.GetInfoByActiveCatalog();
    },
    find(ssd) {
      this.ssd = ssd;
      this.GetInfoByActiveCatalog(ssd);
    },
  }
};
</script>

<style lang="sass">
.laximo-page
  .basket-card
    position: relative !important
    width: 100% !important
</style>

<style scoped lang="sass">
.laximo-page
  &__container
    display: flex
    flex-flow: column
    height: calc(100vh - 42px)
    max-height: calc(100vh - 42px)
  &__wrapper
    position: relative
    overflow: auto
    flex: 1
    z-index: 1
    padding: 10px
    transition: .2s
    &_small
      padding: 0 10px
      overflow: hidden

.select-model
  &__item
    display: inline-flex
    flex-flow: column
    margin-right: 30px
    margin-bottom: 10px
    &__del
      width: 195px
      *
        cursor: pointer
    h2
      font-size: 11px
      margin-top: 0
      margin-bottom: 3px
  &__wrapper
    display: flex
    flex-flow: wrap
  &__list
    display: inline-flex
    border: 1px solid #1f75a8
    padding: 5px 10px
    border-radius: 5px
    font-size: 13px
    &:not(:last-child)
      margin-right: 10px
.catalog
  overflow: auto
  &__block
    padding: 30px
    background: white
    box-shadow: 0 0 30px rgba(black, 0.05)

.search-types
  &__footer
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 10px
  &__select-model
    grid-column: 1 / -1
    display: flex
    flex-flow: wrap
    align-items: flex-end
    .el-button
      margin-bottom: 10px
  &__type
    background: white
    padding: 15px
    box-shadow: 0 0 30px rgba(black, 0.05)
</style>
