export default {
  methods: {
    /**
     * Оборачивает reachGoal в try catch
     * @param type { string }
     */
    reachGoal(type) {
      try {
        // eslint-disable-next-line no-undef
        ym(71757574, 'reachGoal', type);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
