import { io } from 'socket.io-client';

const socketIO = (id) => {
  const socket = io(`${import.meta.env.VUE_APP_BACKEND_SOCKET}?userId=${id}`, {
    transports: ['websocket'],
  });

  socket.on('connect', () => {
    console.log('Websocket подключён');
  });

  socket.io.on('reconnect_attempt', () => {
    console.log('Websocket: попытка переподключения');
  });

  return socket;
};

export default socketIO;
