<template>
  <div class="app-auth">
    <el-card class="app-auth__card">
      <router-view />
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'AppAuth',
};
</script>

<style src="../auth.sass" scoped lang="sass" />
