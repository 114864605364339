const defaultSort = {
  type: Object,
  required: false,
  default: () => ({ prop: 'name', order: 'ASC' }),
  validator(val) {
    let err = false;
    if (typeof val.prop !== 'string' || typeof val.order !== 'string') {
      console.error('[NUI Validation:] defaultSort — должен быть объектом { prop: String, order: String }. defaultSort сейчас: ', val);
      err = true;
    }
    if (!val.prop) {
      console.error('[NUI Validation:] defaultSort.prop — должен быть строкой. prop сейчас: ', val.prop);
      err = true;
    }
    if (['DESC', 'ASC'].findIndex(item => item === val.order) === -1) {
      console.error('[NUI Validation:] defaultSort.order — может быть \'DESC\' или \'ASC\'. order сейчас: ', val.order);
      err = true;
    }
    return !err;
  }
};

export default defaultSort;
