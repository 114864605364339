export default function ({ next, store, nextMiddleware }) {
  if (store.getters['auth/isToken']) {
    return next({
      path: '/products',
    });
  }
  return nextMiddleware();
}

// NOTE: если есть токен перекидывает на table
