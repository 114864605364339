<template>
  <div class="item">
    <div
      class="item__header"
      @click="openInfo"
    >
      <div class="item__title">
        {{ store?.name }}
      </div>
      <price-output
        v-if="!visibleInfo"
        :price="normalizePrice(getDocumentsSum(documents))"
      />
      <button :class="['item__view-more-button', { 'item__view-more-button_active': visibleInfo }]">
        <i class="el-icon-caret-right" />
      </button>
    </div>
    <div
      v-if="visibleInfo"
      class="item__body"
    >
      <template v-if="documents.length">
        <n-table
          :data="documents"
          :default-sort="{ prop: sortField, order: sortOrder.toUpperCase() }"
          :columns="[
            { name: 'Название', prop: 'documentType', width: 'minmax(200px, 1fr)' },
            { name: 'Номер документа', prop: 'documentId', width: 'minmax(100px, 1fr)' },
            { name: 'Дата', prop: 'documentDate', sortable: true, width: 'minmax(100px, 1fr)' },
            { name: 'Заказ', prop: 'orderId', width: 'minmax(100px, 1fr)' },
            { name: 'Дата отгрузки', prop: 'dateShipping', sortable: true, width: 'minmax(100px, 1fr)' },
            { name: 'Срок оплаты', prop: 'scheduledPaymentDate', sortable: true, width: 'minmax(100px, 1fr)' },
            { name: 'Сумма', prop: 'amount', width: '80px', align: 'right', sortable: true },
          ]"
          :row-class="rowClass"
          @sort-change="$emit('sort', $event)"
        >
          <template #documentType="{ row }">
            <div class="document-name">
              {{ row.documentType }}
            </div>
          </template>
          <template #documentDate="{ row }">
            {{ formatTime(row.documentDate) }}
          </template>
          <template #orderId="{ row }">
            <template v-if="row.orderId">
              <el-button
                size="mini"
                :loading="orderLoading"
                @click="$emit('showOrder', row.orderId)"
              >
                № {{ row.orderId }}
              </el-button>
            </template>
            <template v-else>
              Оффлайн
            </template>
          </template>
          <template #amount="{ row }">
            <priceOutput :price="normalizePrice(row.amount)" />
          </template>
          <template #dateShipping="{ row }">
            {{ formatTime(row.dateShipping) }}
          </template>
          <template #scheduledPaymentDate="{ row }">
            {{ formatTime(row.scheduledPaymentDate) }}
          </template>
        </n-table>
      </template>
    </div>
    <div class="item__footer">
      <price-output
        v-if="visibleInfo"
        :price="normalizePrice(getDocumentsSum(documents))"
      />
    </div>
  </div>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';

export default {
  name: 'ReceivablesItem',
  props: {
    /** @type {ReceivableDocument[] | Object} */
    documents: {
      type: Array,
      required: true,
    },
    /** @type {Organization | Object | null} */
    store: {
      type: Object,
      required: true,
    },
    sortField: {
      type: String,
      required: false,
      default: 'dateShipping',
    },
    sortOrder: {
      type: String,
      required: false,
      default: 'desc',
    },
    orderLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: [
    'showOrder',
    'sort',
  ],
  data: () => ({
    visibleInfo: true,
  }),
  methods: {
    normalizePrice,
    openInfo() {
      this.visibleInfo = !this.visibleInfo;
    },
    /**
     * @param {ReceivableDocument[]} documents
     * @return {number}
     */
    getDocumentsSum(documents) {
      return documents.reduce((sum, document) => sum + document.amount, 0);
    },
    formatTime(time) {
      if (!time) {
        return ''
      }
      const date = new Date(time);

      return new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }).format(date);
    },
    rowClass(row) {
      return {
        'expired-receivable-item':
          new Date(row.scheduledPaymentDate) < new Date()
          && row.amount > 0
      };
    }
  }
};
</script>

<style lang="sass">
.expired-receivable-item > .n-table-row__col
  color: #fb7e7e
</style>
<style scoped lang="sass">
.item
  position: relative
  padding: 10px 20px
  background: white
  border: 1px solid #ebeef5
  box-shadow: 0 9px 22px 0 rgba(0, 0, 50, .05)
  border-radius: 8px
  overflow: hidden
  &__header
    display: flex
    flex-flow: nowrap
    align-items: center
    padding-right: 30px
    cursor: pointer
    > *
      position: relative
      z-index: 2
    &::before
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background: radial-gradient(circle at top right, #ecf5ff, white 30%)
      opacity: 0
      z-index: 1
      border-radius: 8px
      transition: .2s
    &:hover::before
      opacity: 1
    @media (max-width: 700px)
      flex-flow: column
      align-items: flex-start
  &__title
    font-size: 14px
    color: #636363
    flex-grow: 1
    font-weight: bold
  &__view-more-button
    position: absolute
    right: 0
    height: 30px
    width: 50px
    padding: 0
    border: none
    background: none
    cursor: pointer
    border-radius: 0 8px 8px 0
    overflow: hidden
    i
      position: relative
      transform: rotate(0)
      transition: .2s
      z-index: 2
    &_active
      i
        transform: rotate(-90deg)
  &__body
    position: relative
    padding-top: 10px
    padding-bottom: 10px
    z-index: 2
    overflow-x: auto
  &__footer
    display: flex
    flex-flow: nowrap
    align-items: center
    justify-content: flex-end
    position: relative
    z-index: 2
</style>
