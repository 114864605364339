<template>
  <div class="app-wrapper">
    <div
      v-if="isAdmin"
      style="font-size: 10px;text-align: center"
    >
      Внимание, вы админ зашедший под чужим пользователем, не делайте ничего необдуманного
    </div>
    <app-header v-if="token" />
    <router-view />
    <analogs-modal />
    <info-product
      v-if="productInfoModalData"
      :info-product="productInfoModalData"
    />
    <app-update />
    <excel-modal />
    <suppliers-modal
      :product="xlsSupplierModalProduct"
      :open="xlsSupplierModalProduct !== null"
    />
  </div>
</template>

<script>
import 'api/axiosInstance';

import debounce from 'utils/debounce';
import {noop} from "utils/functions";
import { mapGetters, mapMutations, mapState } from 'vuex';

import AnalogsModal from '@/components/analogs-modal/AnalogsModal';
import AppHeader from '@/components/app-header/app-header';
import ExcelModal from '@/components/layout/ExcelModel';
import InfoProduct from '@/components/product-table/layout/infoProduct';
import SuppliersModal from '@/views/xls/SuppliersModal.vue';

import AppUpdate from './components/appUpdate';

export default {
  name: 'App',
  metaInfo: {
    // all titles will be injected into this template
    titleTemplate: '%s | Каталог Николь',
    title: 'Страница',
    meta: [
      { name: 'description', content: 'Онлайн каталог автозапчастей торговой фирмы «Николь» для оформления заказа' },
    ]
  },
  components: {
    SuppliersModal,
    ExcelModal,
    AppUpdate,
    InfoProduct,
    AnalogsModal,
    AppHeader
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('auth', ['token']),
    ...mapGetters('auth', ['isAdmin', 'getUserId']),
    ...mapState('products', ['productInfoModalData', 'xlsSupplierModalProduct']),
  },
  mounted() {
    this.SET_WINDOW_SIZE();
    window.addEventListener('resize', this.windowResize);

    const ym = (window['ym'] || noop)
    if (import.meta.env.PROD) {
      ym(71757574, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
        trackHash: true
      });
      ym(71757574, 'userParams', {
        UserID: this.getUserId
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    ...mapMutations('ui', ['SET_WINDOW_SIZE']),
    windowResize: debounce(function windowResize() { this.SET_WINDOW_SIZE(); }),
  },
};
</script>
