<template>
  <div
    v-if="car"
    class="car"
  >
    <div class="wrapper">
      <page-header
        back-button
        @back-button-click="$router.push({ name: 'garage' })"
      >
        Авто
        <template #subtitle>
          VIN/Frame: {{ car.vin }}
        </template>
        <template #rightArea>
          <el-button
            icon="el-icon-delete"
            size="mini"
            @click="deleteHandler"
          >
            Удалить
          </el-button>
        </template>
      </page-header>

      <h2 class="car__title">
        {{ car.name || 'Автомобиль без названия' }} <el-button
          icon="el-icon-edit"
          size="mini"
          @click="openRenameModal"
        />
      </h2>

      <template v-if="modelData">
        <el-descriptions
          class="margin-top"
          :title="modelData.brand + ' ' + modelData.name"
          :column="1"
          border
          size="mini"
        >
          <el-descriptions-item
            v-for="{key, title} in filteredModelProps"
            :key="key"
            :label="title"
          >
            {{ modelData[key] }}
          </el-descriptions-item>
        </el-descriptions>

        <el-button
          class="car__catalog"
          @click="openCatalog"
        >
          Перейти в каталог запчастей →
        </el-button>
      </template>
      <h2>Об авто и владельце</h2>
      <el-input
        v-model="phone"
        placeholder="Например, +7 999 999 99 99"
        @change="phoneChangeHandler"
      >
        <template #prepend>
          Телефон
        </template>
      </el-input>
    </div>
    <el-dialog
      title="Название автомобиля"
      :visible.sync="isRenameModalOpen"
      append-to-body
      width="400px"
    >
      <el-input
        v-model="name"
        style="width: 100%"
        placeholder="Введите VIN или номер кузова"
      />
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="isRenameModalOpen = false"
        >Отменить</el-button>
        <el-button
          type="primary"
          :disabled="!name"
          :loading="isSending"
          @click="editHandler"
        >Изменить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import formatDate from 'utils/formatDate';
import {noop} from "utils/functions";
import { mapActions } from 'vuex';

import PageHeader from '@/components/page-header/page-header';
import nameConversion from "@/views/laximo/utils/nameConversion";

export default {
  name: 'CarPage',
  components: { PageHeader },
  data() {
    return {
      /** @type IGarageItem */
      car: undefined,
      isRenameModalOpen: false,
      isSending: false,
      name: '',
      phone: '',
      modelData: null,
      modelProperties: {
        'bodyStyle' : 'Кузов',
        'engine' : 'Двигатель',
        'displacement' : 'Объем двигателя',
        'transmission' : 'КПП',
        'minorFeatures' : 'Функции',
        'region' : 'Регион',
        'manufactured' : 'Выпущено',
        'datefrom' : 'Выпуск с',
        'frames' : 'Кузов',
        'options' : 'Функции',
        'market' : 'Регион',
        'date' : 'Выпущено',
        'modelyearfrom' : 'Выпуск с',
      }
    };
  },
  metaInfo: {
    title: 'Гараж'
  },
  computed: {
    filteredModelProps() {
      return Object.keys(this.modelProperties)
        .filter(key => this.modelData[key] !== undefined)
        .map(key => {
          return {
            key: key,
            title: this.modelProperties[key]
          }
        })
    }
  },
  async mounted() {
    await this.getCarData()
    await this.getCatalog()
  },
  methods: {
    formatDate,
    ...mapActions('garage', ['getItem', 'updateItem', 'deleteItem']),
    ...mapActions('laximo', ['getVinOrFrameData']),
    openRenameModal() {
      this.name = this.car.name
      this.phone = this.car.phone
      this.isRenameModalOpen = true;
    },
    async editHandler() {
      this.isSending = true
      await this.updateItem({
        id: this.car.id,
        data: {
          name: this.name,
          auto: this.car.auto,
          vin: this.car.vin,
          phone: this.phone
        }
      }).then(async () => {
        await this.getCarData()
        this.$message.success('Название изменено');
        this.isRenameModalOpen = false;
      }).catch((error) => {
        this.$message.error(error || 'Произошла ошибка, попробуйте позже');
      }).finally(() => {
        this.isSending = false
      })
    },
    async phoneChangeHandler() {
      await this.updateItem({
        id: this.car.id,
        data: {
          ...this.car,
          phone: this.phone
        }
      }).then(async () => {
        await this.getCarData()
        this.$message.success('Контакт изменён');
      }).catch((error) => {
        this.$message.error(error || 'Произошла ошибка, попробуйте позже');
      })
    },
    async deleteHandler() {
      await this.$confirm('Вы уверены, что хотите удалить автомобиль?', 'Удаление', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        await this.deleteItem(this.car.id).then(async () => {
          this.$message.success('Автомобиль удалён');
          await this.$router.push({ name: 'garage' })
        }).catch((error) => {
          this.$message.error(error || 'Произошла ошибка, попробуйте позже');
        })
      }).catch(noop)
    },
    async getCarData() {
      await this.getItem(Number(this.$route.params.car)).then(response => {
        this.car = response;
        this.phone = response.phone;
      }).catch((error) => {
        this.$message.error(error || 'Произошла ошибка, попробуйте позже');
      });
    },
    async getCatalog() {
      await this.getVinOrFrameData(this.car.vin).then((data) => {
        this.modelData = data;
      })
    },
    async openCatalog() {
      return await this.$router.push({
        path: `/oem/${nameConversion(this.modelData.brand)}`,
        query: {
          ssd: this.modelData.ssd,
          catalog: this.modelData.catalog,
          vehicleid: this.modelData.vehicleid,
        }
      });
    }
  }
};
</script>
<style lang="sass">
.car .el-descriptions-item__label
  width: 200px
</style>
<style scoped lang="sass">
.car
  padding: 20px
  margin: 0 auto
  overflow: auto
  .wrapper
    max-width: 800px
    margin: 0 auto
  &__title
    font-weight: bold
  &__vin
    color: rgba(0, 0, 0, 0.56)
    font-size: 13px
    line-height: 140%
  &__catalog
    margin-top: 30px
.loader
  height: 40px
</style>
