<template>
  <div class="filters">
    <div class="filter">
      <span class="filter__title">Наличие</span>
      <el-checkbox v-model="outOfStockComp">
        Товары не в наличии
      </el-checkbox>
    </div>
    <div class="filter">
      <span class="filter__title">Скидки</span>
      <el-checkbox v-model="groupsOnly">
        <span class="promo">Выгодная цена</span>
      </el-checkbox>
    </div>
    <div
      v-for="(filter, i) in filters"
      :key="`filters---${i}`"
    >
      <component
        :is="components[filter.type]"
        :filter="filter"
      />
    </div>

    <div
      v-if="filters.length"
      class="filters__buttons"
    >
      <el-button
        type="primary"
        class="set-filters"
        @click="filterOut"
      >
        Применить {{ applyFiltersCount > 9 ? '(9+)' : applyFiltersCount !== 0 ? `(${applyFiltersCount})` : '' }}
      </el-button>
      <el-button
        class="set-filters"
        size="small"
        @click="resetFilters"
      >
        Сбросить
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import filterCheckbox from './filters/filterCheckbox.vue';
import filterRange from './filters/filterRange.vue';

const components = {
  checkbox: 'filterCheckbox',
  range: 'filterRange'
};

export default {
  name: 'ProductsFilters',
  components: { filterCheckbox, filterRange },
  data() {
    return {
      components,
      selectedFilters: [],
    };
  },
  computed: {
    ...mapState(
      'products',
      [
        'filters', 'changedFilters', 'filterChange', 'outOfStock',
        'noFilters', 'onlyPromoActions', 'onlyGroups'
      ]
    ),
    outOfStockComp: {
      set(val) {
        this.SET_OUT_OF_STOCK(val);
        this.filterOut();
      },
      get() {
        return this.outOfStock;
      }
    },
    groupsOnly: {
      set(val) {
        this.SET_ONLY_GROUPS(val);
        this.filterOut();
      },
      get() {
        return this.onlyGroups;
      }
    },
    queryToApply() {
      const params = this.changedFilters.reduce((preview, item) => ({
        ...preview,
        [item.name]: item.value ? item.value : `${item.gt}-${item.lt}`
      }), {});

      params.outOfStock = this.outOfStock ? 1 : undefined;
      params.onlyGroups = this.groupsOnly ? 1 : undefined;
      return params;
    },
    applyFiltersCount() {
      return this.changedFilters.length;
    }
  },
  methods: {
    ...mapMutations(
      'products',
      [
        'RESET_PRODUCTS', 'SET_FILTER_CHANGE', 'SET_OUT_OF_STOCK',
        'REMOVE_CHANGED_FILTERS', 'SET_ONLY_GROUPS'
      ]
    ),
    ...mapActions('products', ['GetProductsList']),
    filterOut() {
      this.RESET_PRODUCTS();
      this.$router.replace({
        path: '',
        query: this.queryToApply
      });
      this.SET_FILTER_CHANGE(false);
    },
    resetFilters() {
      this.$root.$emit('resetFilters');
      this.REMOVE_CHANGED_FILTERS();
      if (this.noFilters) {
        this.SET_FILTER_CHANGE(false);
      }
      if (JSON.stringify(this.$route.query) === JSON.stringify(this.queryToApply)) {
        return;
      }
      this.filterOut();
    }
  }
};
</script>

<style scoped lang="sass">
  .filters
    &__buttons
      width: 100%
      margin-top: 30px
      display: grid
      grid-gap: 10px
  .filter
    margin-top: 16px
    padding-top: 7px
    border-top: 1px solid #ebeef5
    &__title
      display: block
      font-size: 11px
      font-weight: bold
  .promo
    color: #3D8F5F
</style>
