export default function ({ next, store, nextMiddleware }) {
  if (store.getters['auth/_role'] === 'guest') {
    return next({
      path: '/products',
    });
  }
  return nextMiddleware();
}

// NOTE: если есть токен перекидывает на table
