<template>
  <aside class="app-sidebar">
    <div class="app-sidebar__wrapper">
      <div class="app-sidebar__content">
        <slot />
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'AppSidebar',
  data() {
    return {
      isCollapce: false
    };
  }
};
</script>
