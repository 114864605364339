/**
 * Check that file has extension
 * @param {String} filename Name of the file with extension
 * @param {String[]} extensions List of extensions to check
 * @returns {boolean}
 */
export default function validateFileExtension(filename, extensions = []) {
  const _fileNameSplitArr = filename.split('.');
  const extension = _fileNameSplitArr[_fileNameSplitArr.length - 1];
  return (extension && extensions.indexOf(extension) !== -1);
}
