export default function formatDate(strDate) {
  return new Date(strDate).toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}
export function formatDateNumeric(strDate) {
  return new Date(strDate).toLocaleString('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export function formatTime(strTime) {
  return new Date(strTime).toLocaleString('ru', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function getWeekNumber(strTime) {
  const dateFromString = new Date(strTime);

  const oneJan = new Date(dateFromString.getFullYear(), 0, 1);
  const today = new Date(dateFromString.getFullYear(), dateFromString.getMonth(), dateFromString.getDate());
  const dayOfYear = ((today - oneJan + 86400000) / 86400000);
  return Math.ceil(dayOfYear / 7);
}
