import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  /** @type IRecommendationItem[] */
  recommendations: [],
});

const mutations = {
  SET_RECOMMENDATIONS(state, recommendations) {
    state.recommendations = recommendations
  },
};

const actions = {
  getRecommendations({ commit }, basketIds) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        url: '/api/recommendations',
        params: {
          basketIds
        }
      })
        .then((recommendations) => {
          commit(
            'SET_RECOMMENDATIONS',
            recommendations.map(recommendation => {
              return {
                ...recommendation,
                quantity: recommendation.quantity || 1
              }
            })
          );
          resolve();
        })
        .catch(err => reject(new Error(err.message || err)));
    });
  },
};


const getters = {
  recommendations: state => state.recommendations,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
