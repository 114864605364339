<template>
  <div class="footer">
    <el-row>
      <el-button
        class="footer__button"
        @click="SET_XLS_ORDER([])"
      >
        Назад
      </el-button>

      <el-button
        v-show="showDelButton"
        class="footer__button"
        @click="addToDrafts()"
      >
        Сохранить в черновик
      </el-button>

      <el-button
        v-show="showDelButton"
        class="footer__button"
        type="primary"
        @click="fixXlsErrors()"
      >
        Исправить ошибки
      </el-button>
    </el-row>
    <el-select
      v-if="baskets"
      v-show="!showDelButton"
      v-model="selectedCheckout"
      clearable
      placeholder="Корзины"
      class="footer__button"
      @change="selectCheckout"
    >
      <el-option
        v-for="item in baskets"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
    <el-button
      v-show="!showDelButton"
      class="footer__button"
      type="primary"
      width="400px"
      :disabled="selectedCheckout === ''"
      @click="addToCard"
    >
      {{ selectedCheckout === 'new' ? 'Создать новую корзину' : 'Добавить в корзину' }}
    </el-button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'XlsOrderListFooter',
  props: {
    order: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selectedCheckout: ''
    };
  },
  computed: {
    ...mapState('basket', ['multiBasket']),
    ...mapState('products', ['xlsOrder']),
    showDelButton() {
      return this.order.findIndex(item => (item.status || item.state) !== 'ready' && item.status !== 'severalSuppliers') >= 0;
    },
    baskets() {
      return [
        {
          in: 0,
          name: 'Создать новую корзину'
        },
        ...this.multiBasket
      ];
    }
  },
  methods: {
    ...mapMutations('products', ['SET_XLS_ORDER']),
    ...mapActions('basket', ['XlsUploadBasket', 'GetProductsBasket']),
    ...mapActions('products', ['fixXlsErrors', 'addProductsArrayToXlsErrors', 'getXlsErrors']),
    ...mapMutations('basket', ['SET_ACTIVE_BASKET']),

    selectCheckout(el) {
      this.selectedCheckout = el;
      if (el) {
        this.SET_ACTIVE_BASKET(el);
        this.GetProductsBasket();
      }
    },
    addToCard() {
      if (this.order.findIndex(item => item.quantity === 0) > -1) {
        this.$message({
          type: 'error',
          message: 'Недопустимо количество товара равное нулю',
        });
        return;
      }

      const products = [];
      this.xlsOrder.forEach((item) => {
        if (item.state === 'severalSuppliers') {
          item.stocks.forEach((stock) => {
            if (!stock.quantity) return;
            products.push({
              supplier: {
                productId: item.productId,
                brand: item.brand,
                article: item.article,
                name: item.name,
                stocks: {
                  id: stock.id,
                  price: stock.price,
                  count: stock.count,
                  multiplicity: stock.multiplicity,
                  delivery: stock.delivery,
                  tengePrice: stock.tengePrice
                }
              },
              quantity: stock.quantity || 0,
            });
          });
        } else {
          products.push(item);
        }
      });


      this.XlsUploadBasket({
        products,
        basketId: this.selectedCheckout !== 'new' ? this.selectedCheckout : undefined
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Товары добавлены в корзину',
          });
          this.SET_XLS_ORDER([]);
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err,
          });
        });
    },
    addToDrafts() {
      let productsWithErrors = this.xlsOrder.filter(productToFilter => productToFilter.state !== 'ready');
      productsWithErrors = productsWithErrors.map((product) => {
        product.status = product.state;
        delete product.state;
        return product;
      });
      this.addProductsArrayToXlsErrors(productsWithErrors).then(() => {
        this.$message({
          type: 'success',
          message: 'Товары добавлены в черновик',
        });
        this.getXlsErrors();
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err,
        });
      });
    }
  },
};
</script>

<style scoped lang="sass">
.footer
  padding: 10px 0 0
  display: flex
  flex-flow: nowrap
  justify-content: flex-end
  margin-top: auto
  &__button
    margin-left: 15px !important
</style>
