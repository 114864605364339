export const CURRENCY_ROUBLE = 'RU';
export const CURRENCY_TENGE = 'KZ';

const state = () => ({
  loading: false,
  width: null,
  infinityScrollSettings: {
    distance: 800,
    busy: 0,
  },
  collapseMenu: JSON.parse(localStorage.getItem('collapseMenu')) || false,
  showColumns: JSON.parse(localStorage.getItem('showColumns')) || {
    hide: true,
    hide2: true,
    hide3: false,
    hide4: true,
    hide5: true,
  },
  showSettings: false,
  tableVisual: JSON.parse(localStorage.getItem('tableVisual')) || {
    striped: false,
    focus: false,
  },
  /** @type {'RU'|'KZ'} */
  userCurrency: localStorage.getItem('userCurrency') || 'RU',
  multipleCurrencies: localStorage.getItem('multipleCurrencies') !== null,
  /** @type {'default'|'big'|'small'} */
  basketSize: localStorage.getItem('basketSize') || 'default',
  windowSize: { height: Infinity, width: Infinity },
  displayCatalogImages: localStorage.getItem('displayCatalogImages') !== null,
});

const mutations = {
  LOADING(state, status) {
    state.loading = status;
  },
  SET_WIDTH(state, width) {
    state.width = width;
  },
  SET_INFINITY_SCROLL_BUSY(state, busy) {
    state.infinityScrollSettings.busy = busy;
  },
  SET_COLLAPSE_MENU(state, payload) {
    localStorage.setItem('collapseMenu', String(payload));
    state.collapseMenu = payload;
  },
  SET_SHOW_COLUMNS(state, payload) {
    state.showColumns = { ...state.showColumns, ...payload };
    localStorage.setItem('showColumns', JSON.stringify(state.showColumns));
  },
  SET_SHOW_SETTINGS(state, payload) {
    state.showSettings = payload;
  },
  SET_TABLE_VISUAL_SETTINGS(state, payload) {
    state.tableVisual = { ...state.tableVisual, ...payload };
    localStorage.setItem('tableVisual', JSON.stringify(state.tableVisual));
  },
  SET_USER_CURRENCY(state, payload) {
    state.userCurrency = payload;
    localStorage.setItem('userCurrency', payload);
  },
  SET_MULTIPLE_CURRENCIES(state, payload) {
    state.multipleCurrencies = payload;
    if (payload) {
      localStorage.setItem('multipleCurrencies', payload);
    } else {
      localStorage.removeItem('multipleCurrencies');
    }
  },
  CHANGE_BASKET_SIZE(state, payload) {
    state.basketSize = payload;
    localStorage.setItem('basketSize', payload);
  },
  SET_WINDOW_SIZE(state) {
    const { innerHeight, innerWidth } = window;
    state.windowSize = { height: innerHeight, width: innerWidth };
  },
  SET_DISPLAY_CATALOG_IMAGES(state, payload) {
    state.displayCatalogImages = payload;
    if (payload) {
      localStorage.setItem('displayCatalogImages', payload);
    } else {
      localStorage.removeItem('displayCatalogImages');
    }
  },
};

const getters = {
  isLoading: state => state.loading,
  width: state => state.width,
  menuWidth: state => (state.collapseMenu ? '35px' : '250px'),
  _userCurrency: state => state.userCurrency,
  userCurrencySign: (state) => {
    if (state.userCurrency === CURRENCY_ROUBLE) {
      return '₽';
    }
    return '₸';
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
