<template>
  <el-header>
    <div class="logo">
      <el-tooltip
        effect="dark"
        content="Перейти к сайту"
        placement="bottom"
      >
        <a
          class="logo__back"
          href="https://nikol.biz"
          target="_blank"
        >
          <i class="el-icon-s-home" />
        </a>
      </el-tooltip>
      <el-tooltip
        effect="dark"
        content="На главную каталога"
        placement="bottom"
      >
        <router-link
          class="logo__main"
          :to="productsLink"
        >
          <img
            src="@/assets/img/logo.png"
            width="90"
            alt="logo"
          >
        </router-link>
      </el-tooltip>
    </div>
    <div style="display: flex;align-items: center;width: 100%;justify-content: space-between">
      <AppSearch />

      <div class="personal-account">
        <el-row
          type="flex"
          align="center"
          justify="center"
        >
          <el-col class="is-justify-center is-align-center el-row--flex el-col-mini">
            <el-dropdown
              trigger="click"
              style="color:#ffffff;font-size: 12px;"
            >
              <span
                class="el-dropdown-link"
                style="display: flex;padding: 5px;cursor: pointer"
              >
                <div>
                  <div style="text-align: center;">
                    <i class="el-icon-menu" />
                  </div>
                  <div>
                    Меню
                  </div>
                </div>
              </span>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <router-link
                      :to="productsLink"
                      class="menu-link"
                      :active-class="'active'"
                    >
                      Товары
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span
                      class="menu-link"
                      @click="openExcelModal"
                    >
                      Скачать прайс
                    </span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <router-link
                      to="/wishlist"
                      class="menu-link"
                      :active-class="'active'"
                    >
                      Лист ожидания
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <router-link
                      to="/upload-xls"
                      class="menu-link"
                      :active-class="'active'"
                    >
                      Загрузка Excel
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <router-link
                      to="/history"
                      class="menu-link"
                      :active-class="'active'"
                    >
                      История
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <router-link
                      to="/contacts"
                      class="menu-link"
                      :active-class="'active'"
                    >
                      Контакты
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <a
                      href="https://nikol.biz"
                      class="menu-link"
                    >
                      Перейти на сайт
                    </a>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <router-link
                      to="/receivables"
                      class="menu-link receivables-menu-item"
                      :active-class="'active'"
                    >
                      <div class="receivables-menu-item__title">
                        Взаиморасчёты
                      </div>
                      <div class="receivables-menu-item__summ">
                        <price-output
                          :price="normalizePrice(receivable)"
                          show-rub
                        />
                      </div>
                    </router-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>

          <el-col class="is-justify-center is-align-center el-row--flex el-col-big">
            <router-link
              :to="productsLink"
              class="naw-btn"
              :active-class="'active'"
            >
              <div>
                <div style="text-align: center;">
                  <i class="el-icon-shopping-cart-full" />
                </div>
                <div>
                  Товары
                </div>
              </div>
            </router-link>
          </el-col>
          <el-col class="is-justify-center is-align-center el-row--flex el-col-big">
            <router-link
              to="/wishlist"
              class="naw-btn"
              :active-class="'active'"
            >
              <div>
                <div
                  v-if="hasNotification"
                  class="nav-btn-notification-sign"
                />
                <div style="text-align: center">
                  <i class="el-icon-alarm-clock" />
                </div>
                <div>
                  Лист ожидания
                </div>
              </div>
            </router-link>
            <wishlist-modal v-if="isModalOpen" />
          </el-col>
          <el-col class="is-justify-center is-align-center el-row--flex el-col-big">
            <router-link
              to="/upload-xls"
              class="naw-btn"
              :active-class="'active'"
            >
              <div>
                <div style="text-align: center;">
                  <i class="el-icon-upload2" />
                </div>
                <div>
                  Загрузка Excel
                </div>
              </div>
            </router-link>
          </el-col>
          <el-col class="is-justify-center is-align-center el-row--flex el-col-big">
            <router-link
              to="/history"
              class="naw-btn"
              :active-class="'active'"
            >
              <div>
                <div style="text-align: center;">
                  <i class="el-icon-tickets" />
                </div>
                <div>
                  История
                </div>
              </div>
            </router-link>
            <order-history-changes-modal v-if="orderHistoryChanges && orderHistoryChanges.length" />
          </el-col>
          <el-col class="is-justify-center is-align-center el-row--flex el-col-big">
            <router-link
              to="/contacts"
              class="naw-btn "
              :active-class="'active'"
            >
              <div>
                <div style="text-align: center;">
                  <i class="el-icon-phone-outline" />
                </div>
                <div>
                  Контакты
                </div>
              </div>
            </router-link>
          </el-col>
          <el-col class="is-justify-center is-align-center el-row--flex el-col-big excel">
            <div
              class="exel-icon naw-btn"
              style="height: 100%;"
              @click="openExcelModal"
            >
              <div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Скачать прайс в формате Excel"
                  placement="top"
                >
                  <div>
                    <div style="text-align: center;">
                      <i class="el-icon-download" />
                    </div>
                    <div>
                      Скачать прайс
                    </div>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </el-col>
          <el-col
            v-if="windowSize.width > 1200"
            class="is-justify-center is-align-center el-row--flex"
          >
            <router-link
              to="/receivables"
              class="naw-btn receivables"
              :active-class="'active'"
            >
              <div class="receivables__title">
                Взаиморасчёты
              </div>
              <div class="receivables__summ">
                <price-output
                  :price="normalizePrice(receivable)"
                  show-rub
                />
              </div>
            </router-link>
          </el-col>
          <app-header-user-dropdown />
        </el-row>
      </div>
    </div>
  </el-header>
</template>

<script>
import { normalizePrice } from 'utils/normalizePrice';
import ymMixin from 'utils/ymMixin';
import { mapActions, mapMutations, mapState } from 'vuex';

import AppHeaderUserDropdown from '@/components/app-header/layout/app-header-user-dropdown';
import AppSearch from '@/components/app-search';
import OrderHistoryChangesModal from '@/components/OrderHistoryChangedModal.vue';
import WishlistModal from '@/components/wishlist-modal/WishlistModal';

export default {
  name: 'AppHeader',
  components: {
    OrderHistoryChangesModal,
    WishlistModal,
    AppHeaderUserDropdown,
    AppSearch
  },
  mixins: [ymMixin],
  data() {
    return {};
  },
  computed: {
    ...mapState('products', ['tourStatus', 'supplierProductsInfo', 'tableType']),
    ...mapState('wishlist', ['hasNotification', 'isModalOpen']),
    ...mapState('receivables', ['receivable']),
    ...mapState('ui', ['windowSize']),
    ...mapState('accounts', ['city']),
    ...mapState('order', ['orderHistoryChanges']),
    productsLink() {
      if (this.tableType === 'nikolProducts') return '/products';
      if (this.tableType === 'part') {
        const { brand, article } = this.supplierProductsInfo;
        return brand && article ? `/part/${brand}/${article}` : '/products';
      }
      return '/products';
    }
  },
  created() {
    this.GetName();
  },
  async mounted() {
    await this.getReceivable();
    if (!this.city) {
      await this.GetCity();
    }
    if (this.city.cityId) {
      await this.getDeliveryDates();
    }
    // this.getMinimalOrderSum();
  },
  methods: {
    ...mapMutations('ui', ['SET_COLLAPSE_MENU', 'SET_SHOW_COLUMNS']),
    ...mapActions('receivables', ['getReceivable']),
    ...mapActions('accounts', ['GetCity', 'GetName', 'getDeliveryDates']),
    ...mapActions('products', ['getMinimalOrderSum']),
    ...mapActions('excel', {
      openExcelModal: 'open'
    }),
    normalizePrice,
    async startTutorial() {
      this.reachGoal('tutorial');
      if (this.$route.name === 'view-order') {
        localStorage.removeItem('tourOrder');
        window.location.pathname = '/products';
      } else {
        localStorage.removeItem('tour');
        this.SET_COLLAPSE_MENU(false);
        this.SET_SHOW_COLUMNS({
          hide: true,
          hide2: true,
          hide3: false,
          hide4: true,
          hide5: true,
        });

        window.location.pathname = '/products';
      }
    },
  }
};
</script>

<style scoped lang="sass">
.logo
  display: flex
  align-items: stretch
  height: 100%
  @media (max-width: 600px)
    display: none
  &__back
    width: 30px
    color: #fff
    display: flex
    align-items: center
    justify-content: center
    text-decoration: none
    &:hover
      background: rgba(0, 0, 0, 0.15)
    @media (max-width: 1200px)
      display: none
  &__main
    display: flex
    align-items: center
    padding: 0 10px
    &:hover
      background: rgba(0, 0, 0, 0.15)
.el-col-mini
  display: none
.el-col-big
  display: block
@media (max-width: 1200px)
  .el-col-mini
    display: block
  .el-col-big
    display: none
@media (max-width: 600px)
  .excel
    display: none
.main-padding
  padding: unset !important
.menu-icon
  cursor: pointer
.menu-icon_position
  padding: 10px
.menu-icon_color
  color: black
.el-header
  display: flex
  align-items: center
  background-color: #1f75a8
  color: #fff
  height: 42px !important
  position: relative
  flex-direction: row
.container
  max-width: 1200px
  margin: auto
.el-menu
  border-right: 0 !important
.button-container
  margin-top: 20px
.search
  margin-left: 50px
  width: 40%
.naw-btn
  height: 41px
  font-size: 12px
  text-decoration: none
  color: #ffffff !important
  display: flex
  align-items: center
  justify-content: center
  padding: 0 15px
  position: relative
  cursor: pointer
  width: max-content
  white-space: nowrap
  &:hover,
  &.active
    background: rgba(0,0,0,0.15)
.nav-btn-notification-sign
  position: absolute
  width: 6px
  height: 6px
  background: red
  border-radius: 50%
  right: 40px
  top: 5px
.not-active-btn
  cursor: not-allowed
p.clip
  margin: 0
  padding: 0
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  width: 115px
  padding-top: 2px
.menu-link
  color: rgb(96, 98, 102)
  text-decoration: none
  padding: 10px 50px 10px 20px
  white-space: pre
  &.active
    font-weight: bold
.receivables
  font-size: 12px
  height: 41px
  text-decoration: none
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  padding: 0 15px
  position: relative
  width: max-content
  white-space: nowrap
.receivables-menu-item
  display: flex
  flex-direction: column
  padding: 10px 50px 10px 20px
  line-height: 1.3
  white-space: pre
</style>
