<template>
  <div>
    <el-table
      :data="modifications"
      stripe
      :height="height"
      :row-style="{ cursor: 'pointer', position: 'relative', }"
      style="width: 100%;"
      @row-click="getCategories"
    >
      <el-table-column
        prop="name"
        label="Модификация"
        min-width="200"
      />
      <el-table-column
        prop="modelName"
        label="Модель"
        width="80"
      />
      <el-table-column
        prop="manufacturerName"
        label="Производитель"
        width="115"
      />
      <el-table-column
        prop="manufacturerName"
        label="Год выпуска"
        width="100"
      >
        <template #default="scope">
          {{ scope.row.yearFrom | date }} - {{ scope.row.yearTo | date }}
        </template>
      </el-table-column>
      <el-table-column
        prop="constructionType"
        label="Форма кузова"
        width="100"
      />

      <el-table-column
        prop="cylinder"
        label="Кол-во цилиндров"
        width="100"
      />
      <el-table-column
        label="Рабочий обьем"
        width="100"
      >
        <template #default="scope">
          <div>
            {{ scope.row.cylinderCapacityCcm }} куб/см
          </div>
          <div>
            {{ scope.row.cylinderCapacityLiter }} л
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="fuelType"
        label="Вид топлива"
        width="65"
      />
      <el-table-column
        prop="impulsionType"
        label="Тип привода"
        width="110"
      />
      <el-table-column
        prop="motorType"
        label="Вид двигателя"
        width="110"
      />
      <el-table-column
        label="Мощность"
        width="150"
      >
        <template #default="scope">
          мощность: {{ scope.row.powerHP }} л/c ({{ scope.row.powerKW }} кВт)
        </template>
      </el-table-column>
      <el-table-column
        width="1"
      >
        <div style="height: 22px;" />
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'Modifications',
  filters: {
    date: (value) => {
      if (!value) return '';
      value = value.toString();
      return `${value.slice(4, 7)}/${value.slice(0, 4)}`;
    }
  },
  computed: {
    height() {
      return 'calc(100vh - 100px)';
    },
    modifications() {
      return this.$store.state.tecdoc.modifications;
    },
  },
  methods: {
    async getCategories(modification) {
      await this.$router.push(`/tecdoc/catalog/${modification.id}`);

      this.$store.commit('tecdoc/SET_ARTICLES_SIMPLIFIED', []);
    },
  }
};
</script>

<style scoped>

</style>
