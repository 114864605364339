const state = () => ({
  showExelModal: false,
});

const mutations = {
  SET_SHOW_EXCEL_MODAL(state, showExelModal) {
    state.showExelModal = showExelModal;
  },
};
const actions = {
  async open({ commit }) {
    commit('SET_SHOW_EXCEL_MODAL', true);
  },
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
