import socketIO from 'api/SocketIOInstance';

const socketIOPlug = (() => (store) => {
  const socketIOInstance = socketIO(store.getters['auth/getUserId']);
  socketIOInstance.on('priceUpdated', () => {
    store.dispatch('basket/priceUpdate');
  });
  socketIOInstance.on('wishListUpdate', () => {
    console.log('on:wishListUpdate');
    store.dispatch('wishlist/wishListUpdate');
  });
  socketIOInstance.on('reconciliationReportsUpdate', () => {
    store.dispatch('reconciliationReports/setHashChanges');
  });
  socketIOInstance.on('priceInOrderUpdated', () => {
    store.dispatch('order/GetOrders');
  });

  socketIOInstance.on('ordersDeliveryDateUpdate', (e) => {
    store.dispatch('order/onDeliveryDatesChanges', e);
  });
  socketIOInstance.onAny((event) => {
    console.log(`got unknown socket event: ${event}`);
  });
})();

export default socketIOPlug;
