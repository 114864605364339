import axiosInstance from '../../api/axiosInstance';

const state = () => ({
  catalogs: [],
  activeCatalog: null,
  activeCatalogId: null,
  activeCatalogWizards: {},
  activeCatalogAutos: {
    list: [],
    columns: [],
  },
});

const mutations = {
  SET_CATALOGS(state, payload) {
    let id = 0;

    function structureChange(laximoCatalogs, parentId) {
      if (!laximoCatalogs) return undefined;
      return laximoCatalogs.map((item) => {
        id++;
        return {
          id: `l-${id}`,
          name: item.attributes.name,
          children: [],
          attributes: item.attributes,
          elements: item.elements[0].elements,
          parentId,
        };
      });
    }

    state.catalogs = [{
      id: 'l-0',
      name: 'Laximo',
      children: structureChange(payload.elements[0].elements[0].elements, 'l-0'),
      parentId: null,
    }];
  },
  SET_ACTIVE_CATALOG(state, payload) {
    state.activeCatalog = payload;
    state.activeCatalogId = payload.id;
    state.activeCatalogAutos = {
      list: [],
      columns: [],
    };
  },
  REMOVE_ACTIVE_CATALOG(state) {
    state.activeCatalog = null;
    state.activeCatalogId = null;
    state.activeCatalogAutos = {
      list: [],
      columns: [],
    };
  },
  SET_ACTIVE_CATALOG_WIZARDS(state, payload) {
    state.activeCatalogWizards = payload.elements[0].elements[0].elements.map(item => ({
      name: item.attributes.name,
      value: item.attributes.value || undefined,
      ssd: item.attributes.ssd || undefined,
      values: item.elements[0].elements ? item.elements[0].elements.map(el => ({
        name: el.attributes.value,
        value: el.attributes.key,
        select: '',
      })) : undefined,
    }));
  },
  SET_AUTOS(state, payload) {
    state.activeCatalogAutos.list = payload.elements[0].elements[0].elements.map(item => ({
      name: item.attributes.name,
      brand: item.attributes.brand,
      catalog: item.attributes.catalog,
      ssd: item.attributes.ssd,
      vehicleid: item.attributes.vehicleid,
      ...item.elements.reduce((previousValue, i) => ({
        ...previousValue,
        [i.attributes.key]: i.attributes.value,
      }), {}),
    }));
    state.activeCatalogAutos.columns = [
      {
        name: 'Название',
        prop: 'name',
        width: 'minmax(1px, 2fr)',
      },
      ...payload.elements[0].elements[0].elements[0].elements.map(item => ({
        name: item.attributes.name,
        prop: item.attributes.key,
      })),
    ];
  },
  REMOVE_AUTOS(state) {
    state.activeCatalogAutos = {
      list: [],
      columns: [],
    };
  },
};

const actions = {
  async GetListCatalogs({ commit }) {
    return axiosInstance('/api/laximo/listCatalogs')
      .then((resp) => {
        commit('SET_CATALOGS', resp);
      });
  },
  async GetVehicleInfo(_, { catalog, vehicleId, ssd }) {
    const resp = await axiosInstance('/api/laximo/GetVehicleInfo', {
      params: {
        catalog,
        vehicleId,
        ssd
      },
    });

    return resp.elements[0].elements[0].elements[0].attributes;
  },
  async GetWizard2({ getters }, {
    catalog = getters._catalogCode,
    ssd = undefined,
  }) {
    return axiosInstance('/api/laximo/GetWizard2', {
      params: {
        catalog,
        ssd,
      },
    });
  },
  async GetAutos({ getters }, {
    catalog = getters._catalogCode,
    ssd = undefined,
  }) {
    return axiosInstance('/api/laximo/FindVehicleByWizard2', {
      params: {
        catalog,
        ssd,
      },
    });
  },
  async GetInfoByActiveCatalog({
    dispatch,
    commit,
  }, ssd = undefined) {
    const wizard = await dispatch('GetWizard2', { ssd });
    commit('SET_ACTIVE_CATALOG_WIZARDS', wizard);
    if (!ssd) {
      commit('REMOVE_AUTOS');
      return;
    }
    const autos = await dispatch('GetAutos', { ssd });
    commit('SET_AUTOS', autos);
  },
  async GetListQuickGroup(_, {
    ssd,
    catalog,
    vehicleid
  }) {
    const resp = await axiosInstance('/api/laximo/ListQuickGroup', {
      params: {
        ssd,
        catalog,
        vehicleId: vehicleid,
      },
    });
    return resp.elements[0].elements[0].elements[0];
  },
  async GetListQuickDetail(_, {
    ssd,
    catalog,
    vehicleid,
    quickGroupId = undefined,
    all = 1
  }) {
    const resp = await axiosInstance('/api/laximo/ListQuickDetail', {
      params: {
        ssd,
        catalog,
        vehicleId: vehicleid,
        quickGroupId,
        all,
      },
    });
    return resp.elements[0].elements[0].elements[0];
  },
  async GetListDetailByUnit(_, {
    ssd,
    catalog,
    unitId
  }) {
    const resp = await axiosInstance('/api/laximo/ListDetailByUnit', {
      params: {
        ssd,
        catalog,
        unitId
      },
    });
    return resp.elements[0].elements[0].elements;
  },
  async GetUnitInfo(_, {
    ssd,
    catalog,
    unitId
  }) {
    const resp = await axiosInstance('/api/laximo/GetUnitInfo', {
      params: {
        ssd,
        catalog,
        unitId
      },
    });
    return resp.elements[0].elements[0].elements;
  },
  async GetListImageMapByUnit(_, {
    ssd,
    catalog,
    unitId
  }) {
    const resp = await axiosInstance('/api/laximo/ListImageMapByUnit', {
      params: {
        ssd,
        catalog,
        unitId
      },
    });
    return resp.elements[0].elements[0].elements;
  },
  async FindVehicle(_, query) {
    const resp = await axiosInstance('/api/laximo/FindVehicle', {
      params: {
        query: query.toLowerCase()
      },
    });
    return resp;
  },
  /**
   *
   * @param dispatch
   * @param number
   * @returns {Promise<ILaximoItem>}
   */
  async getVinOrFrameData({dispatch}, number) {
    return await dispatch('FindVehicle', number).then((resp) => {
      function parsData(type) {
        if (!resp[type].value) return [];
        return JSON.parse(resp[type].value)?.elements[0]?.elements[0]?.elements || [];
      }

      const val = [
        ...parsData(0),
        ...parsData(1)
      ];

      if (!val.length) return Promise.reject();

      const models = val.map(item => ({
        name: item.attributes.name,
        brand: item.attributes.brand,
        catalog: item.attributes.catalog,
        ssd: item.attributes.ssd,
        vehicleid: item.attributes.vehicleid,
        ...item.elements.reduce((previousValue, i) => ({
          ...previousValue,
          [i.attributes.key]: i.attributes.value
        }), {}),
      }));

      return Promise.resolve(models[0]);
    })
      .catch(() => {  });
  },
};

const getters = {
  _catalogCode: store => store.activeCatalog.attributes.code,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
