<template>
  <el-dialog
    title="Товар"
    :visible.sync="dialogVisible"
    width="30%"
    append-to-body
  >
    <div
      v-loading="dialogLoading"
      class="product-info"
    >
      <el-image
        v-if="dialogImages.length"
        class="dialog__img"
        :src="dialogImages[0]"
        fit="contain"
        :preview-src-list="dialogImages"
      />
      <div
        class="dialog__container"
      >
        {{ dialogDescription }}
        {{ dialogBrand }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProductInfoModal',
  props: {
    brand: {
      type: String,
      default: null
    },
    article: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      dialogLoading: false,
      dialogVisible: false,
      dialogImages: [],
      dialogBrand: null,
      dialogDescription: null,
    };
  },
  async mounted() {
    this.dialogLoading = true;
    this.dialogVisible = true;
    this.dialogImages = [];
    await this.GetArticleByBrandAndNumber({
      brand: this.brand,
      article: this.article
    })
      .then((response) => {
        this.dialogBrand = response.brandName;
        this.dialogDescription = response.description;
        response.images.forEach((image) => {
          this.dialogImages.push(`data:image/png;base64,${image.data}`);
        });
      }).catch(() => {
        this.dialogBrand = '';
        this.dialogDescription = 'Нет информации по товару';
      }).finally(() => {
        this.dialogLoading = false;
      });
  },
  methods: {
    ...mapActions('tecdoc', ['GetArticleByBrandAndNumber']),
  }
};
</script>

<style scoped>

</style>
