import axiosInstance from '../../api/axiosInstance';

/**
 * @typedef reconciliationReport
 * @property {number} id
 * @property {number} accountId
 * @property {string} createdAt
 * @property {string|null} documentURL
 * @property {number} orgId
 * @property {number} status
 * @property {string} statusName
 * @property {string} updatedAt
 */

const state = () => ({
  loading: false,
  sending: false,
  reports: [],
  selectedOrgId: [],
  hasChanges: false,
});

const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_SENDING(state, sending) {
    state.sending = sending;
  },
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  SET_SELECTED_ORG_ID(state, selectedOrgId) {
    state.selectedOrgId = selectedOrgId;
  },
  SET_HAS_CHANGES(state, hasChanges) {
    state.hasChanges = hasChanges;
  },
};
const actions = {
  async getReports(
    { commit },
    {
      max = undefined, offset = undefined,
      from = undefined, to = undefined
    } = {}
  ) {
    commit('SET_LOADING', true);
    await axiosInstance('/api/reconciliationReports', {
      params: {
        from,
        to,
        max,
        offset
      }
    })
      .then((resp) => {
        commit('SET_REPORTS', resp);
      }).finally(() => {
        commit('SET_LOADING', false);
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async postReports({ commit }, orgId) {
    return new Promise((resolve, reject) => {
      commit('SET_SENDING', true);
      axiosInstance({
        url: '/api/reconciliationReports',
        method: 'post',
        params: {
          orgId
        }
      }).then(() => {
        resolve();
      }).catch((err) => {
        reject(new Error(err.message || err));
      }).finally(() => commit('SET_SENDING', false));
    });
  },
  async setHashChanges({ commit, dispatch }) {
    dispatch('getReports');
    commit('SET_HAS_CHANGES', true);
  }
};

const getters = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
