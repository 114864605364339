const loading = {
  type: Boolean,
  required: false,
  default: false,
  validator(val) {
    return typeof val === 'boolean';
  }
};

export default loading;
