<template>
  <div :class="b({ size, border, focused, disabled })">
    <button
      :class="b('button', { size })"
      :disabled="value <= min || disabled"
      @click.prevent="minesStep"
    >
      <div class="mines" />
    </button>
    <input
      v-model.number="computedValue"
      :class="b('input', { size })"
      :disabled="disabled"
      @keydown="keydown"
      @blur="blur"
      @focus="focus"
    >
    <button
      :class="b('button', { size })"
      :disabled="value >= max || disabled"
      @click.prevent="plusStep"
    >
      <div class="plus" />
    </button>
  </div>
</template>

<script>
import { size } from '../../props';

export default {
  name: 'NInputNumber',
  block: 'NInputNumber',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    size,
    border: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: Infinity
    },
    min: {
      type: Number,
      default: -Infinity
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    focused: false
  }),
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) val = 0;
        this.$emit('change', val);
      }
    }
  },
  watch: {
    computedValue(val) {
      if (typeof val !== 'number') this.computedValue = this.min;
    }
  },
  methods: {
    plusStep() {
      let val = this.computedValue + this.step;
      val = val % this.step === 0 ? val : val - (val % this.step);
      val = val > this.max ? this.max : val;
      this.computedValue = val;
    },
    minesStep() {
      let val = this.computedValue - this.step;
      val = val % this.step === 0 ? val : val + (this.step - (val % this.step));
      val = val < this.min ? this.min : val;
      val = val > this.max ? this.max : val;
      this.computedValue = val < this.min ? this.min : val;
    },

    /**
     * checkKey Проверяет возможность изменить инпут (путём шага или ввода числа)
     * @param event
     * @return {boolean}
     */
    checkKey(event) {
      const { key } = event;
      if (key === 'Backspace' || key === 'Delete') return true;
      if (key === '+' || key === 'ArrowUp') {
        event.preventDefault();
        this.plusStep();
        return false;
      }
      if (key === '-' || key === 'ArrowDown') {
        event.preventDefault();
        this.minesStep();
        return false;
      }
      if (key === 'ArrowLeft' || key === 'ArrowRight') return true;
      if (Number(key) || key === '0') {
        /*
         * Проверка длинны введённой строки
         * Если указан max, то максимальная длинна строки равна длинне строки max,
         * в противном случае максимальная длинна в 4 символа
         */
        const length = event.target.value.length;
        const maxLength = this.max === Infinity ? 4 : String(this.max).length;
        if (length > maxLength - 1) {
          event.preventDefault();
        }
        return true;
      }
      event.preventDefault();
      return false;
    },
    checkValue() {
      if (this.computedValue < this.min) this.computedValue = this.min;
      if (this.computedValue % this.step !== 0) this.computedValue += (this.step - (this.computedValue % this.step));
      if (this.computedValue > this.max) this.computedValue = this.max;
    },

    // Events
    keydown(event) {
      this.checkKey(event);
    },
    blur(event) {
      this.focused = false;
      this.checkValue(event);
    },
    focus() {
      this.focused = true;
    }
  }
};
</script>

<style scoped lang="sass">
  .n-input-number
    position: relative
    display: inline-flex
    align-items: center
    justify-content: space-between
    border-radius: 3px
    background: white
    transition: .2s
    &_border
      border: 1px solid #DCDFE6
    &_focused.n-input-number_border
      border-color: #52A3D3
    &__input
      text-align: center
      border: none
      outline: none
      height: 19px
      min-width: 40px
      width: 40px
      font-size: 12px
      font-weight: normal
      color: #414141
      padding: 0
      margin: 0
      -moz-appearance: textfield
      &:disabled
        background: white
        opacity: .4
        cursor: default
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button
        -webkit-appearance: none
    &__button
      display: flex
      align-items: center
      justify-content: center
      border: none
      height: 19px
      width: 19px
      border-radius: 2px
      color: #a8a8a8
      background: #F5F7FA
      font-weight: lighter
      cursor: pointer
      transform: scale(1)
      transition: .2s
      &:disabled
        opacity: .4
        cursor: default
      &:not(:disabled):hover
        transform: scale(1.1)
        box-shadow: 0 0 3px rgba(#52A3D3, .5)
  .mines
    height: 1px
    width: 7px
    background: #a8a8a8
  .plus
    display: flex
    align-items: center
    justify-content: center
    position: relative
    height: 1px
    width: 7px
    background: #a8a8a8
    &::before
      display: block
      content: ''
      width: 1px
      height: 7px
      background: #a8a8a8
</style>
