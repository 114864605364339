<template>
  <el-container>
    <app-aside />
    <el-main>
      <div>
        <section class="search-results">
          <h3 class="search-results__title">
            Результаты поиска по VIN:
          </h3>
          <ul
            v-if="autosByVIN && autosByVIN.length"
            class="search-results__list"
          >
            <li
              v-for="(auto, index) in autosByVIN"
              :key="`brandName---${index}`"
              class="search-results__item"
            >
              <a
                href="#"
                class="search-results__link"
                @click.prevent="getModificationByVIN(auto.modelId, auto.manufacturerId)"
              >
                {{ auto.name }}
              </a>
            </li>
          </ul>
          <p
            v-else
            class="search-results__empty"
          >
            Нет результатов
          </p>
        </section>

        <section class="search-results">
          <h3 class="search-results__title">
            Результаты поиска в каталоге TecDoc:
          </h3>
          <div
            v-if="brandsByNumber && Object.keys(brandsByNumber).length"
            class="search-results__tecdoc"
          >
            <n-table
              v-for="i in brandTablesCount(Object.keys(brandsByNumber).length)"
              :key="'table' + i"
              :data="Object.keys(brandsByNumber).slice((i - 1) * 7, 7 * i)"
              :expected-length="3"
              :columns="[
                { name: 'Бренд', prop: 'brand', width: '120px', translucent: true },
                { name: 'Наименование в каталоге', prop: 'name', translucent: true },
              ]"
              @click-on-row="row => searchDetail(brandsByNumber[row])"
            >
              <template #brand="{ row }">
                <div style="color: #000">
                  {{ brandsByNumber[row].brand }}
                </div>
              </template>
              <template #name="{ row }">
                <div
                  v-if="brandsByNumber[row].description"
                  style="color: #000"
                >
                  {{ brandsByNumber[row].description }}
                </div>
                <div
                  v-else
                  style="color: #D4D4D4"
                >
                  Нет информации
                </div>
              </template>
            </n-table>
          </div>
          <p
            v-else
            class="search-results__empty"
          >
            Нет результатов
          </p>
        </section>

        <section class="search-results">
          <h2>Результаты поиска в оригинальных каталогах:</h2>
          <laximo-models-table
            v-if="columns && columns.length"
            v-loading="laximoLoading"
            :data="models"
            :columns="columns"
            @viewModel="viewModel"
          />
          <p
            v-else
            class="search-results__empty"
          >
            Нет результатов
          </p>
        </section>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import AppAside from '@/components/app-tree/app-aside';
import LaximoModelsTable from '@/views/laximo/components/laximoModelsTable';
import nameConversion from '@/views/laximo/utils/nameConversion';

const TYPE_BY_VIN = 0;
const TYPE_BY_FRAME = 1;

export default {
  name: 'SearchResults',
  components: { AppAside, LaximoModelsTable },
  metaInfo: {
    title: 'Результаты поиска'
  },
  data() {
    return {
      localManufacturerId: null,
      editableTabsValue: '3',
      //
      // laximo
      brand: {},
      models: [],
      columns: [],
      error: false,
      laximoLoading: false,
    };
  },
  computed: {
    ...mapState({
      autosByVIN: state => state.tecdoc.autosByVIN,
      tabTypeBrand: state => state.tecdoc.tabTypeBrand,
      brandsByNumber: state => state.tecdoc.brandsByNumber,
      hasSearchRequest: state => state.tecdoc.hasSearchRequest,
      searchQuery: state => state.products.search,
      supplierProductsLoading: state => state.products.supplierProductsLoading,
    }),
    ...mapGetters({
      autoBrands: 'tecdoc/autoBrands',
    }),
  },
  watch: {
    searchQuery() {
      this.findLaximo();
      this.getAutosByVIN();
    }
  },
  mounted() {
    if (this.$route.query.query?.length) {
      this.findLaximo();
      this.getAutosByVIN();
    }
  },
  methods: {
    articleDescription(article) {
      const { brand = 'не указано', description = 'не указано' } = article;
      return article ? `${brand} | ${description}` : '';
    },
    setTabTypeBrand(type) {
      this.$store.commit('tecdoc/SET_TAB_TYPE_BRAND', type);
    },
    async getModificationByVIN(modelId, manufacturerId) {
      this.$emit('manufacturer', manufacturerId);
      this.localManufacturerId = manufacturerId;
      await this.getCategories(modelId);
    },
    async getCategories(modification) {
      await this.$router.push(`/tecdoc/catalog/${modification}`);
      this.$store.commit('tecdoc/SET_ARTICLES_SIMPLIFIED', []);
    },
    async searchDetail({ brand, number }) {
      if (this.supplierProductsLoading) {
        return;
      }
      await this.$router.push(`/part/${brand}/${number}`);
    },
    handleClick(tab) {
      const { tabtype } = tab.$attrs;

      if (tabtype === 'all') {
        if (this.tabTypeBrand === 'custom') {
          this.$store.dispatch('tecdoc/GetAutoBrand', {});
        }

        this.setTabTypeBrand(tabtype);
      }

      if (tabtype === 'favorites') {
        this.setTabTypeBrand('favorites');
      }

      if (tabtype === 'word') {
        this.$store.dispatch('tecdoc/GetAutoBrand', { carType: tab.name });

        this.setTabTypeBrand('custom');
      }

      if (tabtype === 'alphabet') {
        if (this.tabTypeBrand === 'custom') {
          this.$store.dispatch('tecdoc/GetAutoBrand', {});
        }
        this.setTabTypeBrand(tab.label);
      }
    },
    async getAutosByVIN() {
      this.$store.commit('tecdoc/SET_TECDOC_LOADING_STATUS', true);
      try {
        await this.$store.dispatch('tecdoc/GetAutoByVin', {
          vin: this.$route.query.query,
        });
        await this.getBrandsByNumber(this.$route.query.query);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('tecdoc/SET_TECDOC_LOADING_STATUS', false);
        this.hasSearchRequest = true;
      }
    },
    getBrandsByNumber(number) {
      return this.$store.dispatch('tecdoc/GetBrandsByNumber', {
        number,
      });
    },
    async getAutos(manufacturerId) {
      await this.$router.push(`/tecdoc/model/${manufacturerId}`);
      await this.$store.dispatch('tecdoc/GetAutoByBrand', { manufacturerId });
    },
    brandTablesCount(resultsCount) {
      const resultsPerTable = 7;
      return (resultsCount >= (resultsPerTable * 3) ? 3 : Math.ceil(resultsCount / resultsPerTable));
    },
    ...mapActions('laximo', ['FindVehicle', 'GetListQuickGroup']),

    async viewModel({
      brand, ssd, catalog, vehicleid
    }) {
      await this.$router.push({
        path: `/oem/${nameConversion(brand)}`,
        query: {
          ssd,
          catalog,
          vehicleid,
        }
      });
    },

    async findLaximo() {
      this.models = [];
      this.columns = [];
      this.error = false;
      this.laximoLoading = true;

      await this.FindVehicle(this.$route.query.query)
        .then((resp) => {
          function parsData(type) {
            if (!resp[type].value) return [];
            return JSON.parse(resp[type].value)?.elements[0]?.elements[0]?.elements || [];
          }

          const val = [
            ...parsData(TYPE_BY_VIN),
            ...parsData(TYPE_BY_FRAME)
          ];

          if (!val.length) return Promise.reject();


          this.models = val.map(item => ({
            name: item.attributes.name,
            brand: item.attributes.brand,
            catalog: item.attributes.catalog,
            ssd: item.attributes.ssd,
            vehicleid: item.attributes.vehicleid,
            ...item.elements.reduce((previousValue, i) => ({
              ...previousValue,
              [i.attributes.key]: i.attributes.value
            }), {}),
          }));

          this.columns = val[0].elements.reduce((previousValue, item) => [
            ...previousValue,
            {
              name: item.attributes.name || '',
              prop: item.attributes.key || '',
            }
          ], [{
            name: 'Название',
            prop: 'name',
            width: '2fr',
          }]);

          return Promise.resolve();
        })
        .catch(() => { this.error = true; })
        .finally(() => {
          this.laximoLoading = false;
        });
    }
  },
};
</script>

<style>
/*noinspection CssUnusedSymbol*/
.search-results .n-table-row {
  cursor: pointer;
}
</style>
<style scoped>
.search-results {
  padding: 10px;
  margin-bottom: 5px;
  font-size: 12px;
}

.search-results__title {
  margin: 0 0 6px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 124.5%;
  color: #000;
}

.search-results__tecdoc {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px
}

.search-results__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding: 0;
  list-style-type: none;
}

.search-results__item {
  max-width: 33.33%;
  padding: 5px 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-results__link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;
}

.search-results__link:hover,
.search-results__link:focus {
  color: #1f75a8;
}

.search-results__empty {
  font-size: 13px;
  line-height: 124.5%;
  margin: 0 0 20px;
}
</style>
