<template>
  <div :class="b()">
    <admin-menu />
    <router-view style="width: 100%; height: calc(100vh - 42px); overflow: auto" />
  </div>
</template>

<script>
import AdminMenu from '@/views/admin/components/AdminMenu';

export default {
  name: 'AdminLayout',
  components: { AdminMenu },
  metaInfo: {
    titleTemplate: '%s | Админка',
    title: 'Страница'
  },
};
</script>

<style scoped lang="sass" src="../access/admin.sass" />
