<template>
  <div class="laximo-unit">
    <h2 v-if="activeCatalog && activeCatalog.attributes && info.attributes">
      {{ activeCatalog.attributes.name }} {{ info.attributes.name }}
    </h2>
    <div
      ref="imgWrap"
      class="laximo-unit__img__wrapper"
      :class="{ 'laximo-unit__img__wrapper_zoom': imageZoom.zoomed }"
      :style="imageDefStyle"
      @click="zoom"
      @mousemove="imgMouseMove"
    >
      <img
        v-if="info && info.attributes && info.attributes.imageurl"
        ref="img"
        class="laximo-unit__img"
        :src="info.attributes.imageurl.replace('%size%', 'source')"
        :style="imageZoomStyle"
        alt=""
        @load="imgLoad"
      >
      <div
        v-else
        class="laximo-unit__img laximo-unit__img_error-img"
      />
      <div
        class="laximo-unit__map"
        :style="imageZoomStyle"
      >
        <div
          v-for="({ code, x1, x2, y1, y2 }, index) in imageMap"
          :key="`userMap---${index}`"
          class="laximo-unit__map__area"
          :class="{ 'laximo-unit__map__area_active': selectedArea === code }"
          :style="{
            top: y1 / naturalSize.height * 100 + '%',
            left: x1 / naturalSize.width * 100 + '%',
            height: (y2 - y1) / naturalSize.height * 100 + '%',
            width: (x2 - x1) / naturalSize.width * 100 + '%',
          }"
          @click.prevent.stop="selectedArea = code"
        />
      </div>
    </div>
    <n-table
      v-model="selectedDetail"
      :data="details"
      :columns="[
        { name: '№', prop: 'codeonimage', width: `${codeonimageMaxWidth + 14}px` },
        { name: 'Название', prop: 'name', width: 'minmax(100px, 1fr)' },
        { name: 'OEM', prop: 'oem', width: `${OEMMaxWidth + 14}px` },
        { name: 'Подобрать', prop: 'view', width: '60px', align: 'center' },
      ]"
    >
      <template #codeonimage="{ row }">
        <span ref="codeonimage">{{ row.codeonimage }} {{ checkMaxWidth($refs.codeonimage, 'codeonimageMaxWidth') }}</span>
      </template>
      <template #oem="{ row }">
        <el-tooltip
          v-if="windowSize.width < 600"
          effect="dark"
          :content="row.oem"
          placement="top"
        >
          <div class="oem">
            {{ row.oem }}
          </div>
        </el-tooltip>
        <template v-else>
          {{ row.oem }}
        </template>
      </template>
      <template #view="{ row }">
        <el-button
          icon="el-icon-truck"
          plain
          size="mini"
          type="primary"
          :loading="row.loading"
          :disabled="supplierProductsLoading"
          @click="searchDetail(row.oem);"
        />
      </template>
    </n-table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import nameConversion from '@/views/laximo/utils/nameConversion';

export default {
  name: 'LaximoUnit',
  metaInfo: {
    title: 'Деталь'
  },
  data: () => ({
    requestData: { ssd: undefined, catalog: undefined, vehicleid: undefined },

    selectedDetail: null,

    details: [],
    info: {},
    imageMap: {},

    naturalSize: { height: 0, width: 0 },

    imageZoom: {
      zoomed: false,
      pageCord: [0, 0],
      size: [0, 0],
      mousePosition: [0, 0]
    },

    codeonimageMaxWidth: 26,
  }),
  computed: {
    ...mapState('laximo', ['catalogs', 'activeCatalog', 'activeCatalogId', 'activeCatalogWizards', 'activeCatalogAutos']),
    ...mapState('ui', ['windowSize']),
    ...mapState('products', ['supplierProductsLoading']),

    selectedArea: {
      set(val) {
        this.selectedDetail = this.details.findIndex(item => item.codeonimage === val);
      },
      get() {
        return this.details[this.selectedDetail] ? this.details[this.selectedDetail].codeonimage : null;
      }
    },

    imageDefStyle() {
      const { imageZoom } = this;
      if (!imageZoom.zoomed) return {};
      return { width: imageZoom.size[0] ? `${imageZoom.size[0]}px` : '', height: imageZoom.size[1] ? `${imageZoom.size[1]}px` : '' };
    },

    imageZoomStyle() {
      const { imageZoom } = this;
      function cordMath(cord) {
        return `-${2 * (imageZoom.mousePosition[cord] - imageZoom.pageCord[cord])}px`;
      }

      if (!imageZoom.zoomed) return {};
      return { transform: `translate(${cordMath(0)}, ${cordMath(1)}` };
    },
    OEMMaxWidth() {
      if (this.windowSize.width < 600) {
        return 40;
      }
      return 71;
    }
  },
  mounted() {
    const { name, unit } = this.$route.params;
    if (!name) this.$router.push('/oem');

    const { ssd, catalog } = this.$route.query;
    if (!unit || !ssd || !catalog) this.$router.push(`/oem/${nameConversion(name)}`);
    this.requestData = { ssd, catalog };

    this.findDetails();
  },
  methods: {
    ...mapMutations('laximo', ['SET_ACTIVE_CATALOG']),
    ...mapActions('laximo', ['GetListDetailByUnit', 'GetInfoByActiveCatalog', 'GetUnitInfo', 'GetListImageMapByUnit']),
    async findDetails() {
      const catalog = this.catalogs[0].children.find(catalog => nameConversion(this.$route.params.name) === nameConversion(catalog.name));
      if (!catalog) {
        await this.$router.push('/oem');
        return;
      }
      this.SET_ACTIVE_CATALOG(catalog);
      this.GetInfoByActiveCatalog();

      const GetListDetailByUnit = this.GetListDetailByUnit({
        ...this.requestData,
        catalog: this.activeCatalog.attributes.code,
        unitId: this.$route.params.unit
      });
      const GetUnitInfo = this.GetUnitInfo({
        ...this.requestData,
        catalog: this.activeCatalog.attributes.code,
        unitId: this.$route.params.unit
      });
      const GetListImageMapByUnit = this.GetListImageMapByUnit({
        ...this.requestData,
        catalog: this.activeCatalog.attributes.code,
        unitId: this.$route.params.unit
      });

      Promise.all([
        GetListDetailByUnit,
        GetUnitInfo,
        GetListImageMapByUnit
      ]).then((resp) => {
        this.details = resp[0].map(item => ({
          ...item.attributes,
          data: item.elements ? item.elements.map(i => i.attributes) : []
        }));
        this.info = resp[1][0];
        this.imageMap = resp[2].map(item => item.attributes);
      });
    },

    imgLoad() {
      this.naturalSize = { height: this.$refs.img.naturalHeight, width: this.$refs.img.naturalWidth };
      this.imageZoom.size = [this.$refs.imgWrap.getBoundingClientRect().width, this.$refs.imgWrap.getBoundingClientRect().height];
    },

    zoom(e) {
      if (this.imageZoom.zoomed) {
        this.imageZoom.zoomed = false;
        return;
      }
      if (!this.naturalSize.height || !this.naturalSize.width || this.windowSize.width <= 600) return;
      this.imageZoom.zoomed = true;
      this.imageZoom.pageCord = [this.$refs.imgWrap.getBoundingClientRect().x, this.$refs.imgWrap.getBoundingClientRect().y];
      this.imageZoom.size = [this.$refs.imgWrap.getBoundingClientRect().width, this.$refs.imgWrap.getBoundingClientRect().height];
      this.imageZoom.mousePosition = [e.pageX, e.pageY];
    },
    imgMouseMove(e) {
      if (this.windowSize.width <= 600) return;
      //if (this.naturalSize.height && this.naturalSize.width) this.imageZoom.zoomed = true;
      this.imageZoom.mousePosition = [e.pageX, e.pageY];
    },

    async searchDetail(article) {
      if (this.supplierProductsLoading) {
        return;
      }

      const row = this.details.find(detail => detail.oem === article);
      const rowIndex = this.details.indexOf(row);

      this.$set(this.details, rowIndex, { loading: true, ...row });

      await this.$store.dispatch('products/GetSupplierProducts', {
        article,
        brand: this.activeCatalog.attributes.brand,
        tableType: 'part',
      })
        .then(() => {
          this.$router.push(`/part/${this.activeCatalog.attributes.brand}/${article}`);
        })
        .catch(() => {
          this.$alert('Подходящих предложений не найдено', 'Подбор', {
            confirmButtonText: 'OK',
          });
        }).finally(() => {
          this.$set(this.details, rowIndex, { loading: false, ...row });
        });
    },

    checkMaxWidth(item, dataVar) {
      if (!item) return '';
      this[dataVar] = item.clientWidth > this[dataVar] ? item.clientWidth : this[dataVar];
      return '';
    },
  }
};
</script>

<style scoped lang="sass">
.oem
  overflow: hidden
  text-overflow: ellipsis
.laximo-unit
  position: relative
  display: grid
  grid-template-columns: auto 1fr
  align-items: flex-start
  gap: 10px
  @media (max-width: 600px)
    grid-template-columns: 1fr
  h2
    grid-column: 1 / -1
    margin-top: 0
    margin-bottom: 0
    font-size: 14px
  &__img
    height: 100%
    &__wrapper
      position: sticky
      top: 10px
      overflow: hidden
      border: 1px solid rgba(black, .1)
      cursor: zoom-in
      height: calc(100vh - 350px)
      min-height: 300px
      @media (max-width: 600px)
        overflow: auto
        position: relative
      &_zoom
        img, .laximo-unit__map
          width: 300%
          height: 300%
    &_error-img
      height: 100%
      background: rgba(black, .1)
  &__map
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    z-index: 101
    &__area
      position: absolute
      border: 2px solid gray
      z-index: -1
      cursor: pointer
      transition: .2s
      &_active
        border-color: red
      &:hover
        border-color: red
</style>
