const emptyText = {
  type: String,
  required: false,
  default: '',
  validator(val) {
    return typeof val === 'string';
  }
};

export default emptyText;
