/**
 * Генерирует объект с описаниями для продукта
 *
 * @param product {Object<product>}
 * @param row {Object<row>}
 * @return {{product, visible: boolean, link: string, price: string}}
 */
export default function genProductInfo(product, row) {
  const re = /(?:https?|ftp):\/\/\S+[^\s.,>)\];'"!?]/;
  return {
    ...row,
    ...product,
    visible: true,
    link: re.exec(product.description) || '',
    description: product.description.replace(re, '').replace('ССЫЛКА', '').replace('СЫЛКА', '').trim(),
  };
}
