const rowClass = {
  type: Function,
  required: false,
  default: () => ({}),
  validator(val) {
    return typeof val === 'function';
  }
};

export default rowClass;
