<template>
  <div
    v-if="analogsFor"
    class="analog-modal"
  >
    <div class="analog-modal__wrapper">
      <page-header>
        Аналоги
        <template #subtitle>
          Доступные аналоги товара «{{ productInfo.name }}»
        </template>
        <template #rightArea>
          <el-button
            class="close-button"
            icon="el-icon-close"
            size="mini"
            @click="SET_ANALOGS_FOR_ID(null)"
          />
        </template>
      </page-header>
      <n-table
        ref="nikolTable"
        class="analog-modal__table"
        :data="analogs"
        row-index-key="id"
        :columns="[
          {
            name: 'Артикул',
            prop: 'article',
            width: windowSize.width < 400 ? '100px' : '150px',
            sortable: true,
            show: showColumns.hide,
            translucent: tableVisual.focus
          },
          { name: 'Бренд', prop: 'brand', width: '130px', sortable: true, show: showColumns.hide2, translucent: tableVisual.focus },
          { name: 'Код 1С', prop: 'externalId', width: '90px', show: showColumns.hide3, translucent: tableVisual.focus },
          { name: 'Название', prop: 'name', width: '1fr', sortable: true },
          { name: 'К', prop: 'minShippingRate', width: '40px', align: 'center', show: showColumns.hide4, translucent: tableVisual.focus },
          { name: 'Цена', prop: 'price', width: '90px', align: 'right', sortable: true },
          {
            name: 'Остаток',
            prop: 'stockBalance',
            width: '86px',
            align: 'right',
            translucent: tableVisual.focus,
            show: _role !== 'guest'
          },
          { name: 'Купить', prop: 'bay', width: '122px', align: 'center' },
        ]"
        :main-columns="mainColumns"
        :empty-text="productInfo.name ? 'Аналоги не найдены' : 'Загрузка'"
        :row-class="rowClasses"
      >
        <template #minShippingRateHead>
          <el-tooltip
            v-once
            effect="dark"
            content="Минимальная кратность"
            placement="top"
          >
            <div>K.</div>
          </el-tooltip>
        </template>
        <template
          v-once
          #article="{ row }"
        >
          <el-tooltip
            v-if="row.article.length >= 14"
            effect="dark"
            :content="row.article"
            placement="top"
          >
            <div class="analog-modal__table__article">
              {{ row.article }}
            </div>
          </el-tooltip>
          <template v-else>
            {{ row.article }}
          </template>
        </template>
        <template
          v-once
          #brand="{ row }"
        >
          <el-tooltip
            v-if="row.brand.length >= 14"
            effect="dark"
            :content="row.brand"
            placement="top"
          >
            <div class="analog-modal__table__brand">
              {{ row.brand }}
            </div>
          </el-tooltip>
          <template v-else>
            {{ row.brand }}
          </template>
        </template>
        <template
          v-once
          #OEM="{ row }"
        >
          <el-tooltip
            v-if="row.OEM.length >= 14"
            effect="dark"
            :content="row.OEM"
            placement="top"
          >
            <div class="analog-modal__table__oem">
              {{ row.OEM }}
            </div>
          </el-tooltip>
          <template v-else>
            {{ row.OEM }}
          </template>
        </template>
        <template #name="{ row }">
          <div class="analog-modal__table__name">
            {{ row.name }}
            <basket-indicator
              v-if="row.baskets"
              :baskets="row.baskets"
              class="analog-modal__table__name__baskets"
            />
          </div>
        </template>
        <template #price="{ row }">
          <priceOutput :price="normalizePriceMarkup(getPrice(row))" />
        </template>
        <template #regionalStockBalance="{ row }">
          {{ row.regionalStockBalance || '0' }}
        </template>
        <template
          v-once
          #bay="{ row }"
        >
          <add-to-cart
            v-if="row.stockBalance + row.regionalStockBalance !== 0 || returnBasketId !== null"
            v-model="row.quantity"
            :loading="loadingIndex === row.id"
            :disabled="row.stockBalance + row.regionalStockBalance === 0 && returnBasketId === null"
            :min="+row.minShippingRate || 1"
            :max="row.stockBalance + row.regionalStockBalance - ((row.stockBalance + row.regionalStockBalance) % +row.minShippingRate)"
            :step="+row.minShippingRate"
            @addToCart="addProduct(row)"
          />
        </template>
      </n-table>
    </div>
    <div
      class="analog-modal__bg"
      @click="SET_ANALOGS_FOR_ID(null)"
    />
  </div>
</template>

<script>
/**
 * @typedef {Object} Analog
 * @property {string} article
 * @property {string} brand
 * @property {number} categoryId
 * @property {string} externalId
 * @property {number} id
 * @property {string} minShippingRate
 * @property {string} name
 * @property {string} price
 * @property {number} regionalStockBalance
 * @property {number} stockBalance
 * @property {string} tengePrice
 */

import {
  mapActions, mapGetters, mapMutations, mapState
} from 'vuex';

import AddToCart from '@/components/add-to-cart/addToCart';
import PageHeader from '@/components/page-header/page-header';
import basketIndicator from '@/components/product-table/layout/basketIndicator';
import hide from '@/components/tables/mixins/hide';
import { getPrice,normalizePriceMarkup } from '@/utils/normalizePrice';

export default {
  name: 'AnalogsModal',
  components: { PageHeader, AddToCart, basketIndicator },
  mixins: [hide],
  data: () => ({
    loadingIndex: null,
    /** @type Analog[] */
    analogs: [],
    productInfo: {},
  }),
  computed: {
    ...mapState('ui', ['tableVisual', 'userCurrency', 'windowSize']),
    ...mapState('products', ['analogsFor']),
    ...mapState('basket', ['basket', 'activeBasketId']),
    ...mapState('returns', {
      returnBasketId: state => state.basketId,
    }),
    ...mapGetters('auth', ['_role', '_warehouseId']),

    mainColumns() {
      if (this.windowSize.width > 1200) return [];
      if (this.windowSize.width > 1000) return ['article', 'brand', 'name', 'price', 'stockbalance', 'regionalstockbalance', 'bay'];
      if (this.windowSize.width > 800) return ['article', 'brand', 'name', 'price', 'bay'];
      if (this.windowSize.width > 620) return ['article', 'name', 'price', 'bay'];
      return ['article', 'name', 'price'];
    }
  },
  watch: {
    async analogsFor(val) {
      if (!val) {
        this.analogs = [];
        this.productInfo = {};
        return;
      }
      this.GetAnalogs(this.analogsFor)
        .then((/** @type Analog[] */ data) => {
          this.analogs = data.map(item => ({ ...item, quantity: +item.minShippingRate }));
        })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось получить аналоги'); });
      this.GetProductInfo(this.analogsFor)
        .then((data) => {
          this.productInfo = data;
        })
        .catch((err) => { this.$alert(err.message || err, 'Не удалось получить данные о товаре'); });
    }
  },
  methods: {
    ...mapActions('products', ['GetAnalogs', 'GetProductInfo']),
    ...mapActions('basket', ['AddProductInBasket']),
    ...mapMutations('products', ['SET_ANALOGS_FOR_ID', 'SET_PRODUCT_BASKET_QUANTITY']),

    handleAddProductErrors(errorMessage) {
      this.$alert(errorMessage, 'Добавление в корзину', {
        confirmButtonText: 'OK',
      });
    },
    async addProduct(product) {
      this.loadingIndex = product.id;
      await this.AddProductInBasket(product)
        .then(() => {
          const basketThisProduct = this.basket.find(prod => prod.productId === product.id);
          this.analogs.forEach((item) => {
            if (item.id !== product.id) return;
            this.$set(item, 'baskets', {
              [this.activeBasketId]: !basketThisProduct ? product.quantity : basketThisProduct.quantity + product.quantity
            });
          });
        })
        .catch(({ message }) => {
          this.handleAddProductErrors(message);
        });
      product.quantity = +product.minShippingRate;
      this.loadingIndex = null;
    },

    normalizePriceMarkup,
    getPrice,
    rowClasses(row) {
      return {
        'highlight-analog': row.priority !== null
      }
    }
  }
};
</script>

<style lang="sass">
.analog-modal__table .highlight-analog .n-table-row__col_brand
  background: rgba(255, 235, 59, 0.22)
</style>
<style scoped lang="sass">
.close-button
  padding: 5px !important
.analog-modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  align-items: center
  justify-content: center
  z-index: 1999
  &__wrapper
    position: relative
    display: flex
    flex-flow: column
    width: calc(100% - 30px)
    height: calc(100% - 30px)
    background: white
    padding: 30px
    z-index: 2
    @media (max-width: 600px)
      width: 100%
      height: 100%
      padding: 15px
  &__bg
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: rgba(black, .5)
    z-index: 1
  &__header
    display: flex
    align-items: center
    margin-bottom: 15px
    &__icon
      margin-right: 15px
      cursor: pointer
  &__title
    width: 100%
    text-align: left
    align-self: flex-start
    background-color: #fff
    color: #000
    margin-top: 0
    margin-bottom: 0
    font-size: 18px
  &__sub-title
    text-align: left
    align-self: flex-start
    background-color: #fff
    color: #909399
    margin: 0
    font-weight: normal
    font-size: 14px
  &__table
    flex: 1
    &__name
      display: flex
      flex-flow: nowrap
      align-items: center
      width: 100%
      &__baskets
        margin-left: auto
  &__footer
    margin-top: 10px
    display: flex
    flex-flow: nowrap
    align-items: center
    justify-content: flex-end
</style>
